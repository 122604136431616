import { Form } from '../../components/form';

export const Create = (props) => {
  return (
    <Form
      schema={props.schema}
      isAdd={props.isAdd}
      crud={props.crud}
      setIsAdd={props.setIsAdd}
      session={props.session}
    />
  );
};
