import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Elements from './elements';
import { ButtonIcon } from './buttons/ButtonIcon';
import * as Y from 'yup';
import { useCreateCall } from '../../lib/api/create';
import { useUpdateCall } from '../../lib/api/update';
import { useDeleteCall } from '../../lib/api/delete';
import httpParams from '../../lib/query/httpParams';
import { createYupSchema } from '../../lib/utils/createYupSchema';

export const RowForm = (props) => {
  const params = httpParams.adminOrgParams();
  const createCallMutation = useCreateCall();
  const updateCallMutation = useUpdateCall();
  const deleteCallMutation = useDeleteCall();

  const [deleteId, setDeleteId] = React.useState(null);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);

  const validation = Y.object().shape({
    ...props.schema.buildValidation.reduce(createYupSchema, {}),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: props.isAdd ? 'onSubmit' : 'onChange',
    resolver: yupResolver(validation),
  });

  let ElementName;

  const FormElements = {
    InputField: Elements.InputField,
    SelectBox: Elements.SelectBox,
    SwitchBox: Elements.SwitchBox,
    FileChooser: Elements.FileChooser,
  };

  const onSubmitForm = async (data) => {
    const payload = { ...data };
    if (props.schema.addParentId) {
      payload[props.schema.parentId] = props.parentId;
    }
    payload['appId'] = process.env.REACT_APP_APP_ID;
    payload['tenantId'] = process.env.REACT_APP_TENANT_ID;
    payload['orgId'] = process.env.REACT_APP_ORG_ID;

    await createCallMutation.mutateAsync({
      apiEndpoint: props.schema.formAPI,
      parentAPI: props.schema.parentAPI,
      data: payload, //data,
      messageTitle: props.schema.message.title,
      message: props.schema.message.message,
      params: params,
    });
  };
  const onSubmitUpdate = async (data) => {
    for (let prop in props.defaultData) {
      if (props.defaultData.hasOwnProperty(prop)) {
        if (!(prop in data)) {
          data[prop] = props.defaultData[prop];
        }
      }
    }

    const payload = { ...data };
    if (props.schema.makebool) {
      props.schema.makeboolKey.map((k) => (payload[k.k] = k.v));
    }
    if (props.parentDetails) {
      payload[props.parentDetails.parentIdName] = props.parentDetails.parentId;
    }
    payload['appId'] = process.env.REACT_APP_APP_ID;
    payload['tenantId'] = process.env.REACT_APP_TENANT_ID;
    payload['orgId'] = process.env.REACT_APP_ORG_ID;

    await updateCallMutation.mutateAsync({
      apiEndpoint: props.schema.formAPI,
      data: payload, // data,
      parentAPI: props.schema.parentAPI,
      id: props.schema.idObj
        ? payload[props.schema.id[0]][props.schema.id[1]]
        : payload[props.schema.id],
      messageTitle: props.schema.message.title,
      message: props.schema.message.message,
      params,
    });
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    setDeleteConfirm(true);
  };

  const onDeleteForm = async () => {
    await deleteCallMutation.mutateAsync({
      apiEndpoint: props.schema.formAPI,
      id: deleteId,
      parentAPI: props.schema.parentAPI,
      messageTitle: props.schema.deleteMessage.title,
      message: props.schema.deleteMessage.message,
      params,
    });
    setDeleteConfirm(false);
  };
  if (props.isAdd) {
    return (
      <>
        {createCallMutation.status === 'error' && (
          <div className="text-danger">Something went wrong, please try again!</div>
        )}
        {createCallMutation.status === 'success' && (
          <div className="text-success">{props.schema.formTitle} has been added!</div>
        )}
        <form
          onSubmit={handleSubmit(props.isAdd ? onSubmitForm : onSubmitUpdate)}
          data-testid="formAdd"
          className="row"
        >
          {props.schema.schema.map((field, fieldIndex) => (
            <div
              key={fieldIndex}
              className={`col-md-${field.size}`}
              style={{ display: field.hidden ? 'none' : 'block' }}
            >
              {(ElementName = FormElements[field.element])}
              <ElementName
                key={fieldIndex}
                index={fieldIndex}
                register={register}
                field={field}
                errors={errors}
                isAdd={props.isAdd}
              />
            </div>
          ))}
          <div className="col-2 d-flex">
            <label className="form-control-label mt-3"> </label>
            {props.crud > 3 && (
              <ButtonIcon
                type="submit"
                buttonStyle="success btn-icon btn-sm mt-2 mr-2"
                buttonIcon={props.schema.updateButtonIcon}
                isLoading={createCallMutation.isLoading}
              />
            )}

            <button
              data-testid="deleteChildForm"
              type="button"
              onClick={() => props.setIsAdd(false)}
              className="btn btn-secondary btn-icon btn-sm mt-2 mr-1"
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </form>
        <div
          id="mymodal6"
          className="modal fade custom-modal modal1 show"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{
            display: updateCallMutation.isLoading ? 'block' : 'none',
            backgroundColor: 'rgba(55,55,55,0.3)',
          }}
        >
          <div className="modal-dialog modal-sm" style={{ textAlign: 'center' }}>
            <div
              className="modal-content po-relative"
              style={{ display: 'inline-block', border: 'none' }}
            >
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col">
                    <div className="modal-bg">
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <form
          onSubmit={handleSubmit(props.isAdd ? onSubmitForm : onSubmitUpdate)}
          data-testid="formAdd"
          className="row"
        >
          {props.schema.schema.map((field, fieldIndex) => (
            <div
              key={fieldIndex}
              className={`col-md-${field.size}`}
              style={{ display: field.hidden ? 'none' : 'block' }}
            >
              {(ElementName = FormElements[field.element])}
              <ElementName
                key={fieldIndex}
                index={fieldIndex}
                register={register}
                field={field}
                errors={errors}
                defaultData={props.defaultData}
              />
            </div>
          ))}
          <div className="col-2 ">
            <label className="form-control-label mt-5"> </label>
            {props.crud > 1 && props.schema.noUpdate !== true && (
              <ButtonIcon
                type="submit"
                buttonStyle="success btn-icon btn-sm mt-2 mr-2"
                buttonIcon={props.schema.updateButtonIcon}
                isLoading={updateCallMutation.isLoading}
              />
            )}
            {props.crud > 3 && (
              <ButtonIcon
                type="button"
                buttonStyle="danger btn-icon btn-sm mt-2 ml-1"
                buttonIcon={props.schema.deleteButtonIcon}
                isLoading={deleteCallMutation.isLoading}
                function={confirmDelete}
                delId={
                  props.schema.idObj
                    ? props.defaultData[props.schema.id[0]][props.schema.id[1]]
                    : props.defaultData[props.schema.id]
                }
              />
            )}
          </div>
        </form>
        <div
          id="mymodal6"
          className="modal fade custom-modal modal1 show"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{
            display: updateCallMutation.isLoading ? 'block' : 'none',
            backgroundColor: 'rgba(55,55,55,0.3)',
          }}
        >
          <div className="modal-dialog modal-sm" style={{ textAlign: 'center' }}>
            <div
              className="modal-content po-relative"
              style={{ display: 'inline-block', border: 'none' }}
            >
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col">
                    <div className="modal-bg">
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="mymodal6"
          className="modal fade custom-modal modal1 show"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{
            display: deleteConfirm ? 'block' : 'none',
            backgroundColor: 'rgba(55,55,55,0.3)',
          }}
        >
          <div className="modal-dialog modal-md" style={{ textAlign: 'center' }}>
            <div
              className="modal-content po-relative"
              style={{ display: 'inline-block', border: 'none' }}
            >
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col">
                    <div className="modal-bg text-center ">
                      <i className="fa fa-times-circle-o fa-5x text-danger"></i>
                      <br />
                      Are you sure you want to delete this?
                      <button
                        type="button"
                        className="btn btn-success-gradiant text-white btn-md btn-arrow m-t-20"
                        onClick={() => setDeleteConfirm(false)}
                      >
                        <span>Cancel</span>
                      </button>{' '}
                      <button
                        type="button"
                        className="btn btn-danger-gradiant text-white btn-md btn-arrow m-t-20 ml-2"
                        onClick={() => onDeleteForm()}
                      >
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
