export const ButtonIcon = (props) => {
  return props.type === 'submit' ? (
    <button
      data-testid="deleteChildForm"
      type={props.type}
      className={`btn btn-${props.buttonStyle}`}
      disabled={props.isLoading && true}
    >
      {props.isLoading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      ) : (
        <i className={props.buttonIcon}></i>
      )}
    </button>
  ) : (
    <button
      data-testid="deleteChildForm"
      type={props.type}
      className={`btn btn-${props.buttonStyle}`}
      disabled={props.isLoading && true}
      onClick={() => props.function(props.delId)}
    >
      {props.isLoading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      ) : (
        <i className={props.buttonIcon}></i>
      )}
    </button>
  );
};
