import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Elements from './elements';
import { Button } from './buttons/Button';
import * as Y from 'yup';
import { useCreateCall } from '../../lib/api/create';
import { useUpdateCall } from '../../lib/api/update';
import { useDeleteCall } from '../../lib/api/delete';
import httpParams from '../../lib/query/httpParams';
import { createYupSchema } from '../../lib/utils/createYupSchema';

export const Form = (props) => {
  const params = httpParams.adminOrgParams();
  const createCallMutation = useCreateCall();
  const updateCallMutation = useUpdateCall();
  const deleteCallMutation = useDeleteCall();

  const [deleteId, setDeleteId] = React.useState(null);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);

  const validation = Y.object().shape({
    ...props.schema.buildValidation.reduce(createYupSchema, {}),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: props.isAdd ? 'onSubmit' : 'onChange',
    resolver: yupResolver(validation),
  });

  let ElementName;

  const FormElements = {
    InputField: Elements.InputField,
    SelectBox: Elements.SelectBox,
    SwitchBox: Elements.SwitchBox,
    FileChooser: Elements.FileChooser,
  };

  const onSubmitForm = async (data, e) => {
    if (props.schema.hasFile) {
      // const inputFile = document.getElementById("fileInput") as HTMLInputElement;
      // let formData = { ...data };
      // formData[props.schema.fileField] = imageFile;
      // const datas = Object.fromEntries(formData);
      // // datas['file'] = datas[props.schema.fileField];
      // //formData.append('file', e.target.fileName.value);
      // datas['file'] = e.target.fileName.value;
      // console.log(datas);
      //data['fileName'] = data[props.schema.fileField][0];
      //const formData = { ...data };
      //formData.append('fileName', data[props.schema.fileField][0]);
      //formData[props.schema.fileField] = imageFile;
      let formData = new FormData();
      formData.append('docType', data.docType);
      formData.append('file', imageFile);
      console.log(formData);
      await createCallMutation.mutateAsync({
        apiEndpoint: props.schema.formAPI,
        data: formData, //data,
        messageTitle: props.schema.message.title,
        message: props.schema.message.message,
        params: params,
        isMultipart: true,
      });
      //e.target.reset();
    } else {
      const payload = { ...data };
      if (props.schema.addParentId) {
        payload[props.schema.parentId] = props.parentId;
      }
      if (props.schema.addUserId) {
        payload[props.schema.userId] = props.session.user.userId;
      }
      payload['appId'] = process.env.REACT_APP_APP_ID;
      payload['tenantId'] = process.env.REACT_APP_TENANT_ID;
      payload['orgId'] = process.env.REACT_APP_ORG_ID;
      await createCallMutation.mutateAsync({
        apiEndpoint: props.schema.formAPI,
        data: payload, //data,
        messageTitle: props.schema.message.title,
        message: props.schema.message.message,
        params: params,
      });
      e.target.reset();
    }
  };
  const onSubmitUpdate = async (data) => {
    // const payload = { ...data };
    // if (props.schema.makebool) {
    //   props.schema.makeboolKey.map((k) => (payload[k.k] = k.v));
    // }

    // await updateCallMutation.mutateAsync({
    //   apiEndpoint: props.schema.formAPI,
    //   data: payload, // data,
    //   id: payload[props.schema.id],
    //   messageTitle: props.schema.message.title,
    //   message: props.schema.message.message,
    //   params,
    // });
    for (let prop in props.defaultData) {
      if (props.defaultData.hasOwnProperty(prop)) {
        if (!(prop in data)) {
          data[prop] = props.defaultData[prop];
        }
      }
    }

    const payload = { ...data };
    if (props.schema.makebool) {
      props.schema.makeboolKey.map((k) => (payload[k.k] = k.v));
    }
    payload['appId'] = process.env.REACT_APP_APP_ID;
    payload['tenantId'] = process.env.REACT_APP_TENANT_ID;
    payload['orgId'] = process.env.REACT_APP_ORG_ID;

    if (props.schema.handleDatefields) {
      props.schema.dateFields.map((k) => {
        if (payload[k] === 'Invalid Date') {
          payload[k] = null;
        }
      });
    }

    await updateCallMutation.mutateAsync({
      apiEndpoint: props.schema.formAPI,
      data: payload, // data,
      id: payload[props.schema.id],
      messageTitle: props.schema.message.title,
      message: props.schema.message.message,
      params,
    });
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    setDeleteConfirm(true);
  };

  const onDeleteForm = async () => {
    await deleteCallMutation.mutateAsync({
      apiEndpoint: props.schema.formAPI,
      id: deleteId,
      messageTitle: props.schema.deleteMessage.title,
      message: props.schema.deleteMessage.message,
      params,
    });
    setDeleteConfirm(false);
  };

  if (props.isAdd) {
    return (
      <>
        <h2 className="font-light pt-2">{props.schema.formTitle}</h2>
        {createCallMutation.status === 'error' && (
          <div className="text-danger">Something went wrong, please try again!</div>
        )}
        {createCallMutation.status === 'success' && (
          <div className="text-success">{props.schema.formTitle} has been added!</div>
        )}
        <hr />
        <form
          onSubmit={handleSubmit(props.isAdd ? onSubmitForm : onSubmitUpdate)}
          data-testid="formAdd"
          className="row"
        >
          {props.schema.schema.map((field, fieldIndex) => (
            <div
              key={fieldIndex}
              className={`col-md-${field.size}`}
              style={{ display: field.hidden ? 'none' : 'block' }}
            >
              {(ElementName = FormElements[field.element])}
              <ElementName
                key={fieldIndex}
                index={fieldIndex}
                register={register}
                field={field}
                errors={errors}
                isAdd={props.isAdd}
                setImageFile={setImageFile}
              />
            </div>
          ))}
          <div className="col-12 d-flex">
            {props.crud > 3 && (
              <Button
                type="submit"
                buttonStyle="success mt-2 mr-2"
                buttonName={props.schema.updateButtonName}
                isLoading={createCallMutation.isLoading}
                isLoadingText={props.schema.loadingButtonName}
              />
            )}

            {props.schema.disableClose !== true && (
              <button
                data-testid="deleteChildForm"
                type="button"
                onClick={() => props.setIsAdd(false)}
                className="btn btn-secondary mt-2 mr-2"
              >
                Close
              </button>
            )}
          </div>
        </form>
        <div
          id="mymodal6"
          className="modal fade custom-modal modal1 show"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{
            display: updateCallMutation.isLoading ? 'block' : 'none',
            backgroundColor: 'rgba(55,55,55,0.3)',
          }}
        >
          <div className="modal-dialog modal-sm" style={{ textAlign: 'center' }}>
            <div
              className="modal-content po-relative"
              style={{ display: 'inline-block', border: 'none' }}
            >
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col">
                    <div className="modal-bg">
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <h2 className="font-light pt-2">{props.schema.formTitle}</h2>
        <hr />
        <form
          onSubmit={handleSubmit(props.isAdd ? onSubmitForm : onSubmitUpdate)}
          data-testid="formAdd"
          className="row"
        >
          {props.schema.schema.map((field, fieldIndex) => (
            <div
              key={fieldIndex}
              className={`col-md-${field.size}`}
              style={{ display: field.hidden ? 'none' : 'block' }}
            >
              {(ElementName = FormElements[field.element])}
              <ElementName
                key={fieldIndex}
                index={fieldIndex}
                register={register}
                field={field}
                errors={errors}
                defaultData={props.defaultData}
                setImageFile={setImageFile}
              />
            </div>
          ))}
          <div className="col-12 d-flex">
            {props.crud > 1 && (
              <Button
                type="submit"
                buttonStyle="success mt-2 mr-2"
                buttonName={props.schema.updateButtonName}
                isLoading={updateCallMutation.isLoading}
                isLoadingText={props.schema.loadingButtonName}
              />
            )}
            {props.crud > 3 && (
              <Button
                type="button"
                buttonStyle="danger mt-2 ml-2"
                buttonName={props.schema.deleteButtonName}
                isLoading={deleteCallMutation.isLoading}
                isLoadingText={props.schema.loadingButtonName}
                function={confirmDelete}
                delId={props.defaultData[props.schema.id]}
              />
            )}
          </div>
        </form>
        <div
          id="mymodal6"
          className="modal fade custom-modal modal1 show"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{
            display: updateCallMutation.isLoading ? 'block' : 'none',
            backgroundColor: 'rgba(55,55,55,0.3)',
          }}
        >
          <div className="modal-dialog modal-sm" style={{ textAlign: 'center' }}>
            <div
              className="modal-content po-relative"
              style={{ display: 'inline-block', border: 'none' }}
            >
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col">
                    <div className="modal-bg">
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="mymodal6"
          className="modal fade custom-modal modal1 show"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{
            display: deleteConfirm ? 'block' : 'none',
            backgroundColor: 'rgba(55,55,55,0.3)',
          }}
        >
          <div className="modal-dialog modal-md" style={{ textAlign: 'center' }}>
            <div
              className="modal-content po-relative"
              style={{ display: 'inline-block', border: 'none' }}
            >
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col">
                    <div className="modal-bg text-center ">
                      <i className="fa fa-times-circle-o fa-5x text-danger"></i>
                      <br />
                      Are you sure you want to delete this?
                      <button
                        type="button"
                        className="btn btn-success-gradiant text-white btn-md btn-arrow m-t-20"
                        onClick={() => setDeleteConfirm(false)}
                      >
                        <span>Cancel</span>
                      </button>{' '}
                      <button
                        type="button"
                        className="btn btn-danger-gradiant text-white btn-md btn-arrow m-t-20 ml-2"
                        onClick={() => onDeleteForm()}
                      >
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
