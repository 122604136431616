import { default as dayjs } from 'dayjs';
export const InputField = (props) => {
  let defVal;
  if (props.isAdd !== true) {
    if (props.field.valFromObj) {
      defVal = props.defaultData[props.field.obj[0]][props.field.obj[1]];
    } else {
      defVal = props.defaultData[props.field.name];
    }
    if (props.field.type === 'date') {
      defVal = dayjs(defVal).format('MM-DD-YYYY');
    }
  }
  if (props.field.hasPrefix) {
    return props.isAdd ? (
      // <div className={`input-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
      //   <label className="form-control-label">{props.field.placeholder}</label>
      //   {props.field.hasPrefix && props.defaultData[props.field.prefixKey]}
      //   <input
      //       type="text"
      //       placeholder={props.field.placeholder}
      //       disabled={props.field.disabled}
      //       onFocus={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'date') : null)}
      //       onBlur={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'text') : null)}
      //       className={`form-control ${props.errors[props.field.name] && 'form-control-danger'}`}
      //       {...props.register(props.field.name)}
      //       style={{ height: ' !important' }}
      //     />
      //   {props.errors[props.field.name] && (
      //     <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
      //   )}
      // </div>
      <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
        <label className="control-label">{props.field.placeholder}</label>
        <div className="input-group">
          {props.field.hasPrefix && (
            <span className="input-group-text">{props.defaultData[props.field.prefixKey]}</span>
          )}
          <input
            type="text"
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            onFocus={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'date') : null)}
            onBlur={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'text') : null)}
            className={`form-control ${props.errors[props.field.name] && 'form-control-danger'}`}
            {...props.register(props.field.name)}
            style={{ height: ' !important' }}
          />
        </div>
        {props.errors[props.field.name] && (
          <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
        )}
      </div>
    ) : (
      // <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
      //   <label className="form-control-label">{props.field.placeholder}</label>
      //   {props.field.hasPrefix && props.defaultData[props.field.prefixKey]}
      //   <input
      //     type="text"
      //     defaultValue={defVal}
      //     placeholder={props.field.placeholder}
      //     disabled={props.field.disabled}
      //     onFocus={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'date') : null)}
      //     onBlur={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'text') : null)}
      //     className={`form-control ${props.errors[props.field.name] && 'form-control-danger'}`}
      //     {...props.register(props.field.name)}
      //     style={{ height: ' !important' }}
      //   />
      //   {props.errors[props.field.name] && (
      //     <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
      //   )}
      // </div>
      <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
        <label className="control-label">{props.field.placeholder}</label>
        <div className="input-group">
          {props.field.hasPrefix && (
            <span className="input-group-text">{props.defaultData[props.field.prefixKey]}</span>
          )}
          <input
            type="text"
            defaultValue={defVal}
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            onFocus={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'date') : null)}
            onBlur={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'text') : null)}
            className={`form-control ${props.errors[props.field.name] && 'form-control-danger'}`}
            {...props.register(props.field.name)}
            style={{ height: ' !important' }}
          />
        </div>
        {props.errors[props.field.name] && (
          <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
        )}
      </div>
    );
  } else {
    return props.isAdd ? (
      <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
        <label className="form-control-label">{props.field.placeholder}</label>
        {props.field.defaultValue ? (
          <input
            type="text"
            placeholder={props.field.placeholder}
            defaultValue={props.field.defaultValue}
            disabled={props.field.disabled}
            onFocus={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'date') : null)}
            onBlur={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'text') : null)}
            className={`form-control ${props.errors[props.field.name] && 'form-control-danger'}`}
            {...props.register(props.field.name)}
            style={{ height: ' !important' }}
          />
        ) : (
          <input
            type="text"
            placeholder={props.field.placeholder}
            disabled={props.field.disabled}
            onFocus={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'date') : null)}
            onBlur={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'text') : null)}
            className={`form-control ${props.errors[props.field.name] && 'form-control-danger'}`}
            {...props.register(props.field.name)}
            style={{ height: ' !important' }}
          />
        )}
        {props.errors[props.field.name] && (
          <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
        )}
      </div>
    ) : (
      <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
        <label className="form-control-label">{props.field.placeholder}</label>
        {props.field.hasPrefix && props.defaultData[props.field.prefixKey]}
        <input
          type="text"
          defaultValue={defVal}
          placeholder={props.field.placeholder}
          disabled={props.field.disabled}
          onFocus={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'date') : null)}
          onBlur={(e) => (props.field.type === 'date' ? (e.currentTarget.type = 'text') : null)}
          className={`form-control ${props.errors[props.field.name] && 'form-control-danger'}`}
          {...props.register(props.field.name)}
          style={{ height: ' !important' }}
        />
        {props.errors[props.field.name] && (
          <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
        )}
      </div>
    );
  }
};
