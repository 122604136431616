export const FileChooser = (props) => {
  return (
    <fieldset className="form-group mb-3">
      <label className="custom-file d-block">
        <input
          id="fileInput"
          onChange={(e) => props.setImageFile(e.target.files[0])}
          type="file"
          className="custom-file-input"
        />
        <span className="custom-file-control"></span>
      </label>
      {props.errors[props.field.name] && (
        <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
      )}
    </fieldset>
  );
};
