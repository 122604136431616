import React from 'react';
import ReactPlayer from 'react-player';

export const VideoImageGallery = ({ pageData }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalObj, setModalObj] = React.useState(null);
  const [type, setType] = React.useState(null);

  const videoModalFunc = (obj, type) => {
    setIsModalOpen(!isModalOpen);
    setModalObj(obj);
    setType(type);
  };

  const errState = { error: false };
  try {
    return (
      <>
        <div className="portfolio4 spacer up">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="title">"Header"</h2>
                <h6 className="subtitle">Text</h6>
              </div>
            </div>
            <div className="row portfolio-box4" style={{ position: 'relative' }}>
              {pageData.childItems &&
                pageData.childItems.length > 0 &&
                pageData.childItems.map((gal, galItm) => (
                  <div className={`col-${gal.size.size} filter abstract print`}>
                    <div className="overlay-box">
                      <img
                        src={
                          gal.type.type === 'image'
                            ? gal.url.url
                            : `https://img.youtube.com/vi/${gal.url.url}/hqdefault.jpg`
                        }
                        alt={`gal${galItm}`}
                        className="w-100 img-fluid"
                      />
                      <span
                        className="overlay cursor_Pointer"
                        data-testid="videoMadalFunction"
                        onClick={() => videoModalFunc(gal, gal.type.type)}
                      >
                        <div className="port-text">
                          <h5>
                            <i className="fa fa-search fa-3x"></i>
                          </h5>
                        </div>
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div
            id="mymodal5"
            className="modal fade custom-modal modal6 show"
            tabIndex={-1}
            role="dialog"
            aria-modal="true"
            style={{
              display: 'block',
              backgroundColor: 'rgba(55,55,55,0.3)',
            }}
          >
            <div className="modal-dialog modal-fullscreen-sm-down modal-lg">
              <div className="modal-content po-relative">
                <div className="modal-body p-0">
                  <div className="row">
                    <div className="col-12 modal-bg-img">
                      {type === 'video' && (
                        <ReactPlayer
                          config={{
                            youtube: {
                              playerVars: { loop: 1 },
                            },
                          }}
                          controls={true}
                          url={'https://www.youtube.com/watch?v=' + modalObj.url.url}
                          width="100%"
                          height="calc(100vh - 100px)"
                        />
                      )}
                      {type === 'image' && (
                        <img src={modalObj.url.url} alt="img" className="img-responsive" />
                      )}
                    </div>
                  </div>
                  <span
                    className="close-btn d-flex align-items-center justify-content-center cursor_Pointer"
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    data-testid="setIsModalOpen"
                    onClick={() => setIsModalOpen(false)}
                  >
                    ×
                  </span>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
        )}
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        {console.log(err)}
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>
          try again
        </a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
