import React, { useEffect } from 'react';

import './lang.css';

let langOptions;
const GoogleTranslate = (props) => {
  const [windowWidth, setWindowWidth] = React.useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };
  React.useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);
  langOptions =
    props.pageData.langOptions.langOptions && props.pageData.langOptions.langOptions.split(',');
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
        includedLanguages: langOptions.toString(),
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    let addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    const styles = `--primary:${process.env.REACT_APP_THEME_COLOR}; bottom: ${
      props.isLoggedIn && windowWidth < 991 ? '8% !important' : '2% !important'
    }`;
    document.getElementById('langC').setAttribute('style', styles);
  }, []);
  function handleLangDisplay() {
    document
      .getElementsByClassName('goog-te-gadget-simple')[0]
      .getElementsByTagName('span')[0]
      .getElementsByTagName('a')[0]
      .getElementsByTagName('span')[0].innerHTML =
      '<i class="fa fa-language fa-2x text-white"></i>';
    document
      .getElementsByClassName('goog-te-gadget-simple')[0]
      .getElementsByTagName('span')[0]
      .getElementsByTagName('a')[0]
      .getElementsByTagName('span')[1].style.display = 'none';
    document
      .getElementsByClassName('goog-te-gadget-simple')[0]
      .getElementsByTagName('span')[0]
      .getElementsByTagName('a')[0]
      .getElementsByTagName('img')[0].style.display = 'none';
    document
      .getElementsByClassName('goog-te-gadget-simple')[0]
      .getElementsByTagName('span')[0]
      .getElementsByTagName('a')[0]
      .getElementsByTagName('span')[2].style.display = 'none';
    document
      .getElementsByClassName('goog-te-gadget-simple')[0]
      .getElementsByTagName('span')[0]
      .getElementsByTagName('a')[0]
      .getElementsByTagName('img')[1].style.display = 'none';
  }
  setTimeout(() => handleLangDisplay(), 2000);
  // if (document.getElementsByClassName('goog-te-menu-value')) {

  // }
  // setTimeout(
  //   () =>
  //     (document.getElementsByClassName('goog-te-menu-value')[0].innerHTML =
  //       '<i class="fa fa-language fa-2x"></i>'),
  //   2000
  // );

  return (
    <div id="langC" className="lang">
      <div id="google_translate_element"></div>
    </div>
  );
};

export default GoogleTranslate;
