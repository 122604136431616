import { useNotificationStore } from '../../store/notifications';
import { Notification } from './Notification';
export const Notifications = (props) => {
  const { notifications, dismissNotification } = useNotificationStore();
  return (
    <div
      className="toast-container"
      style={{ position: 'fixed', right: '2px', bottom: '4rem', zIndex: 2999 }}
    >
      {/* Notifications stack */}
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          onDismiss={dismissNotification}
        />
      ))}
    </div>
  );
};
