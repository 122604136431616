import React from 'react';
import { useForm } from 'react-hook-form';
import * as Y from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateCall } from '../../lib/api/create';

export const ModalFileManager = (props) => {
  const createCallMutation = useCreateCall();
  const validation = Y.object().shape({
    document: Y.mixed().required('Choose a document to upload!'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validation),
  });

  const onFormSubmit = async (data, e) => {
    let formData = new FormData();
    formData.append('docType', props.documentType);
    formData.append('file', data.document[0]);
    //console.log(formData);
    await createCallMutation.mutateAsync({
      apiEndpoint: 'applDocument/' + props.defaultData.applicationId,
      parentAPI: 'appl',
      data: formData,
      messageTitle: 'Document',
      message: 'Document uploaded successfully!',
      params: props.params,
    });
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      aria-modal="true"
      role="dialog"
      style={{ visibility: 'visible' }}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
          {`Upload ${props.documentType}`}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => props.setFileManageModal(false)}
        />
      </div>
      <div className="offcanvas-body">
        <div>
          <div className="mt-2 mb-5">
            <form onSubmit={handleSubmit(onFormSubmit)} className="row">
              <fieldset className="form-group mb-3">
                <label className="custom-file d-block">
                  <input type="file" {...register('document')} className="custom-file-input" />
                  <span className="custom-file-control"></span>
                </label>
                {errors['document'] && (
                  <div className="form-control-feedback">{errors['document'].message}</div>
                )}
              </fieldset>

              <button type="submit" className="btn btn-primary">
                {createCallMutation.isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    Uploading...
                  </>
                ) : (
                  <>Upload</>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
