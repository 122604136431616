import { useMutation } from 'react-query';
import { axios } from '../../lib/query/axios';
import { queryClient } from '../../lib/query/react-query';
import { useNotificationStore } from '../../store/notifications';

let callData;
export const updateCall = (call) => {
  callData = call;
  return axios.put(`/${call.apiEndpoint}/${call.id}` + call.params, call.data);
};

export const useUpdateCall = (call, { config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation(async (call) => await updateCall(call), {
    onError: (_, __, context) => {
      if (context?.previousCall) {
        queryClient.setQueryData(call.apiEndpoint, context.previousCall);
      }
      addNotification({
        type: 'error',
        title: callData.messageTitle,
        message: 'Error! Something went wrong',
      });
    },
    onSuccess: (resp) => {
      queryClient.invalidateQueries(callData.parentAPI ? callData.parentAPI : callData.apiEndpoint);
      addNotification({
        type: 'success',
        title: callData.messageTitle,
        message: callData.message,
      });
    },
    ...config,
    mutationFn: () => updateCall,
  });
};
