import { Link } from 'react-router-dom';

export const CallToAction7 = (props) => {
  const errState = { error: false };
  try {
    return (
      <div className={`mini-spacer bg-${props.pageData.bgColor.bgColor} text-white c2a7`}>
        <div className="container">
          <div className="d-flex">
            <div className="display-7 align-self-center">{props.pageData.header.header}</div>
            <div className="ms-auto m-t-10 m-b-10">
              <Link
                to={props.pageData.buttonLink.buttonLink}
                className="btn btn-outline-light btn-md"
              >
                {props.pageData.buttonText.buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
