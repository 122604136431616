import { Link } from 'react-router-dom';

export const Banner10 = (props) => {
  return (
    <div id="static-slider10-html">
      <div
        className="static-slider10"
        style={{ backgroundImage: `url('${props.pageData.imageBG.imageBG}')` }}
      >
        <div className="container">
          <div className="row justify-content-center ">
            <div
              className="col-lg-6 col-md-8 align-self-center text-center aos-init"
              data-aos="fade-down"
              data-aos-duration={1200}
            >
              <span className="label label-rounded label-inverse">
                {props.pageData.badge.badge}
              </span>
              <h1 className="title">{props.pageData.header.header}</h1>
              <h6 className="subtitle op-8">{props.pageData.text.text}</h6>
              <Link
                className="btn btn-outline-light btn-rounded btn-md btn-arrow m-t-20"
                to={props.pageData.buttonLink.buttonLink}
              >
                <span>
                  {props.pageData.buttonText.buttonText} <i className="ti-arrow-right" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
