import React from 'react';
import landing from '../../assets/images/landing-block.jpg';
import { NavbarDefault } from '../../components/web';

export const Landing = (props) => {
  const [windowWidth, setWindowWidth] = React.useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  });

  return (
    <>
      <NavbarDefault
        authData={props.authData}
        auth={props.auth}
        setRoleAccess={props.setRoleAccess}
        setRoleModal={props.setRoleModal}
        setOpenMenu={props.setOpenMenu}
        openMenu={props.openMenu}
        isLoggedIn={props.isLoggedIn}
        isOnline={props.isOnline}
      />
      <div className="page-wrapper">
        {/* ============================================================== */}
        {/* Container fluid  */}
        {/* ============================================================== */}
        <div className="container-fluid">
          {/* ============================================================== */}
          {/* Static Slider 1  */}
          {/* ============================================================== */}
          <div
            className="static-slider9 po-relative"
            style={{
              marginTop: '4rem',
              marginBottom: props.isLoggedIn && windowWidth <= 991 ? '2rem' : '',
            }}
          >
            {/* Row  */}
            <div className="container">
              <div className="row">
                {/* Column */}
                <div className="col-md-6 info-detail align-self-center">
                  <h1 className="title mt-5 mt-lg-0 pt-4 pt-md-5 pt-lg-0">
                    {props.webSettings['webSettings-landingPageHeader']}
                  </h1>
                  <h6 className="subtitle">{props.webSettings['webSettings-landingPageText']}</h6>

                  {/* Column */}
                </div>
                <div
                  className="col-md-6 bg-img"
                  style={{ backgroundImage: `url(${landing})` }}
                ></div>
              </div>
            </div>
            {/* Column */}
            {/* Column */}
          </div>
          {/* ============================================================== */}
          {/* End Static Slider 1  */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Container fluid  */}
        {/* ============================================================== */}
      </div>
    </>
  );
};
