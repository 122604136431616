import ReactPlayer from 'react-player';
export const VideoModal = (props) => {
  const errState = { error: false };
  try {
    return (
      <div
        id="mymodal5"
        className="modal fade custom-modal modal6 show"
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        style={{
          display: 'block',
          backgroundColor: 'rgba(55,55,55,0.3)',
        }}
      >
        <div className="modal-dialog modal-fullscreen-sm-down modal-lg">
          <div className="modal-content po-relative">
            <div className="modal-body p-0">
              <div className="row">
                <div className="col-12 modal-bg-img">
                  <ReactPlayer
                    config={{
                      youtube: {
                        playerVars: { loop: 1 },
                      },
                    }}
                    controls={true}
                    url={'https://www.youtube.com/watch?v=' + props.url}
                    width="100%"
                    height="calc(100vh - 100px)"
                  />
                </div>
              </div>
              <span
                className="close-btn d-flex align-items-center justify-content-center cursor_Pointer"
                data-bs-dismiss="modal"
                aria-hidden="true"
                onClick={() => props.setIsModalOpen(false)}
              >
                ×
              </span>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
