export const PBForm = ({ comp, updatePropertyValue }) => {
  return (
    <div className="col-12">
      {Object.entries(comp).map(
        ([key, value]) =>
          key !== 'pageElementId' &&
          key !== 'id' &&
          key !== 'childItems' &&
          key !== 'className' &&
          key !== 'pageElementPropertyId' &&
          key !== 'elementTypePropertyId' && (
            <form onSubmit={(e) => updatePropertyValue(comp, e, key)} className="row mb-2">
              <div className="col-4">{key}</div>
              <div className="col-4">
                <input defaultValue={comp[key][key]} name={key} className="form-control" />
              </div>
              <div className="col-2">
                <button type="submit" className="btn btn-sm btn-icon btn-success">
                  <i className="fa fa-check"></i>
                </button>
              </div>
            </form>
          )
      )}
    </div>
  );
};
