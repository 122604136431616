import { Link } from 'react-router-dom';

export const Banner3 = (props) => {
  return (
    <div id="static-slider3-html">
      <div className="static-slider3">
        <div className="container">
          <div className="row justify-content-center ">
            <div
              className="col-md-8 align-self-center text-center aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration={1200}
            >
              <h1 className="title">
                {props.pageData.text.text}{' '}
                <b className="font-bold">{props.pageData.textBold.textBold}</b>{' '}
                <span className="text-success-gradiant font-bold typewrite" data-period={2000}>
                  <span className="wrap">{props.pageData.textColored.textColored}</span>
                </span>
              </h1>
              <Link
                className="btn btn-success-gradiant text-white btn-md btn-arrow m-t-20"
                to={props.pageData.buttonLink.buttonLink}
              >
                <span>
                  {props.pageData.buttonText.buttonText} <i className="ti-arrow-right" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
