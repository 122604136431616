export const Features5 = (props) => {
  try {
    return (
      <div className="bg-light spacer feature5">
        <div className="container">
          {/* Row  */}
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 text-center">
              <h2 className="title">{props.pageData.header.header}</h2>
              <h6 className="subtitle">{props.pageData.text.text}</h6>
            </div>
          </div>
          <div id="feature5-html">
            {/* Row  */}
            <div className="row m-t-40">
              {/* Column */}
              {props.pageData.childItems.length > 0 &&
                props.pageData.childItems.map((item, index) => (
                  <div key={index} className={`col-md-${item.sizemd.sizemd} wrap-feature5-box`}>
                    <div
                      className="card card-shadow aos-init"
                      data-aos="fade-right"
                      data-aos-duration={1200}
                    >
                      <div className="card-body d-flex">
                        <div className="icon-space">
                          <i className={`text-success-gradiant ${item.icon.icon}`} />
                        </div>
                        <div className>
                          <h6 className="font-medium">
                            <a href={item.link.link} className="linking">
                              {item.title.title}
                            </a>
                          </h6>
                          <p className="m-t-20">{item.text.text}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
