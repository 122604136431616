import React from 'react';
import { useDrag } from 'react-dnd';
import ReactTooltip from 'react-tooltip';

import comps from '../../assets/images/500.jpg';
import { useGetCall } from '../../lib/api/get';
import httpParams from '../../lib/query/httpParams';
import { schema as appSchema } from '../../schema';

export const LeftDrawer = (props) => {
  const params = httpParams.adminOrgParams();
  const elementData = useGetCall('ElementType', params);
  const [accordPull, setAccordPull] = React.useState(null);

  const Drags = ({ element }) => {
    const [{ isDragging }, drag] = useDrag({
      item: element,
      type: 'elementType',
      collect: (monitor) => ({
        opacity: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0.4 : 1;
    return (
      <div
        className="mb-2 cursor-move"
        data-tip
        data-for={element.elementTypeName}
        ref={drag}
        style={{ opacity }}
      >
        <span className="btn input-block-level btn-outline-primary">{element.elementTypeName}</span>
        <ReactTooltip id={element.elementTypeName} type="light" place="right" aria-haspopup="true">
          <img
            src={comps}
            alt={element.elementTypeName}
            className="img-responsive"
            style={{ width: '100px', height: '100px' }}
          />
        </ReactTooltip>
      </div>
    );
  };

  if (elementData.isLoading) {
    return <div>Loading...</div>;
  }

  const getChildCompChild = (parenInd) => {
    return elementData.data?.formData.filter((el) => {
      return appSchema.elementCategories[parenInd]?.components.some((f) => {
        return f === el.elementTypeName;
      });
    });
  };

  return (
    <div
      className="offcanvas offcanvas-start show"
      data-bs-scroll="true"
      tabIndex={-1}
      id="offcanvasWithBothOptions"
      aria-labelledby="offcanvasWithBothOptionsLabel"
      aria-modal="true"
      role="dialog"
      style={{ visibility: 'visible' }}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
          Elements
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => props.closeLeft()}
        />
      </div>
      <div className="offcanvas-body cScrollBar" style={{ maxHeight: '100%' }}>
        {appSchema.elementCategories
          .sort((a, b) => a.category.localeCompare(b.category))
          .map((cat, catIndex) => (
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className={`accordion-button ${
                      accordPull !== null && accordPull === catIndex ? '' : 'collapsed'
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                    onClick={() => setAccordPull(accordPull === catIndex ? null : catIndex)}
                  >
                    {cat.category}
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className={`accordion-collapse collapse ${
                    accordPull !== null && accordPull === catIndex ? 'show' : ''
                  }`}
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {getChildCompChild(catIndex).map((comp, elementIndex) => (
                      <Drags key={elementIndex} element={comp} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
