import React from 'react';
//import { useSearchParams } from 'react-router-dom';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Landing } from './Landing';
import httpParams from '../../lib/query/httpParams';
import { useGetPageCall, useGetSPAPageCall } from '../../lib/api/get';
import { useCreateCall } from '../../lib/api/create';
import { useDeleteCall } from '../../lib/api/delete';
import * as Web from '../../components';
import { LeftDrawer } from '../../components/Layout/LeftDrawer';
import { RightDrawer } from '../../components/Layout/RightDrawer';
import { Login } from './Login';
import { Seo } from '../../lib/seo';

export const Website = (props) => {
  //const errState = { error: false };
  const params = httpParams.uiParams();
  const queryParams = new URLSearchParams(window.location.search);
  const editMode = queryParams.get('edit');
  const createCallMutation = useCreateCall();
  const deleteCallMutation = useDeleteCall();

  const [deleteId, setDeleteId] = React.useState([]);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);

  const listQuery = useGetPageCall(
    'pageData',
    props.routeData.pageId,
    props.routeData.name,
    params,
    true
  );
  const listSPAQuery = useGetSPAPageCall(
    'pageData',
    props.routeData.name,
    params,
    props.webSettings['webSettings-defaultSPAPage']
  );

  const [windowWidth, setWindowWidth] = React.useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    setDeleteConfirm(true);
  };

  const onDeleteForm = async () => {
    await deleteCallMutation.mutateAsync({
      apiEndpoint: 'PageElement',
      id: deleteId[0],
      noConfirmation: true,
      messageTitle: 'Page Element',
      message: 'Page element deleted!',
      params,
    });
    await deleteCallMutation.mutateAsync({
      apiEndpoint: 'PageElement',
      id: deleteId[1],
      noConfirmation: true,
      messageTitle: 'Page Element',
      message: 'Page element deleted!',
      params,
    });
    await deleteCallMutation.mutateAsync({
      apiEndpoint: 'PageElement',
      id: deleteId[2],
      parentAPI: 'pageData',
      messageTitle: 'Page Element',
      message: 'Page element deleted!',
      params,
    });
    setDeleteConfirm(false);
  };

  const Drops = ({ elemIndex }) => {
    //const [{ isOver, canDrop }, drop] = useDrop({
    //const [{ canDrop, isOver }, drop] = useDrop({
    const [, drop] = useDrop({
      accept: 'elementType',
      drop: async (item, monitor) => {
        const rowFormObject = {};
        rowFormObject['parentPageElementId'] = null;
        rowFormObject['pageId'] = props.routeData.pageId;
        rowFormObject['pageElementName'] = 'Row';
        rowFormObject['appId'] = process.env.REACT_APP_APP_ID;
        rowFormObject['tenantId'] = process.env.REACT_APP_TENANT_ID;
        rowFormObject['elementTypeName'] = 'Row';
        rowFormObject['elementTypeId'] = '86ECAB37-5659-4B70-A71B-217D225FBCF7';
        rowFormObject['pageElementDisplayOrder'] = elemIndex;
        await createCallMutation
          .mutateAsync({
            apiEndpoint: 'PageElement',
            data: rowFormObject,
            noConfirmation: true,
            messageTitle: 'Page Element',
            message: 'Page element added',
            params: params,
          })
          .then(async (resp) => {
            const colFormObject = {};
            colFormObject['parentPageElementId'] = resp.formData.pageElementId;
            colFormObject['pageId'] = props.routeData.pageId;
            colFormObject['pageElementName'] = 'Col';
            colFormObject['appId'] = process.env.REACT_APP_APP_ID;
            colFormObject['tenantId'] = process.env.REACT_APP_TENANT_ID;
            colFormObject['elementTypeName'] = 'Col';
            colFormObject['elementTypeId'] = '86ECAB37-5659-4B70-A71B-217D225FBCF7';
            colFormObject['pageElementDisplayOrder'] = 0;
            await createCallMutation
              .mutateAsync({
                apiEndpoint: 'PageElement',
                data: colFormObject,
                noConfirmation: true,
                messageTitle: 'Page Element',
                message: 'Page element added',
                params: params,
              })
              .then(async (resp) => {
                const formObject = { ...item };
                formObject['parentPageElementId'] = resp.formData.pageElementId;
                formObject['pageId'] = props.routeData.pageId;
                formObject['pageElementName'] = formObject.elementTypeName;
                formObject['appId'] = process.env.REACT_APP_APP_ID;
                formObject['tenantId'] = process.env.REACT_APP_TENANT_ID;
                formObject['pageElementDisplayOrder'] = 0;
                await createCallMutation.mutateAsync({
                  apiEndpoint: 'PageElement',
                  data: formObject,
                  parentAPI: 'pageData',
                  messageTitle: 'Page Element',
                  message: 'Page element added',
                  params: params + 'pageName=' + props.routeData.name,
                  refetch: true,
                });
              });
          });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });
    return (
      <div className="builderbox" ref={drop}>
        <div className="builderhidden" style={{ textAlign: 'center' }}>
          (Drop Here......{props.indexing})
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = props.routeData.pageTitle;
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  });

  const [drawLeft, setDrawLeft] = React.useState(false);
  const [drawRight, setDrawRight] = React.useState(false);
  const [compData, setCompData] = React.useState(null);

  const openLeft = () => {
    setDrawLeft(true);
  };

  const closeLeft = () => {
    setDrawLeft(false);
  };

  const openRight = (comp) => {
    setDrawRight(true);
    setCompData(comp);
  };

  const closeRight = () => {
    setDrawRight(false);
    setCompData(null);
  };

  if (
    listQuery.isLoading ||
    listSPAQuery.isLoading ||
    (!editMode && (listQuery.isRefetching || listSPAQuery.isRefetching))
  ) {
    return (
      <>
        <div
          className="spinner-border"
          role="status"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            color: process.env.REACT_APP_THEME_COLOR,
          }}
        >
          <span className="sr-only">Loading...</span>
        </div>
        <span className="sr-only">Loading...</span>
      </>
    );
  }

  let TagName;

  const pData = JSON.parse(listQuery.data?.formData?.pageData);

  const pDataSPA = listSPAQuery.data?.formData && JSON.parse(listSPAQuery.data?.formData?.pageData);

  if (props.routeData.isAuthenticated) {
    if (!props.isLoggedIn) {
      return <Login auth={props.auth} />;
    }
  }

  if (!editMode && pData.length <= 0) {
    return (
      <Landing
        auth={props.auth}
        sessionIsActive={props.sessionIsActive}
        authData={props.authData}
        setRoleAccess={props.setRoleAccess}
        setRoleModal={props.setRoleModal}
        setOpenMenu={props.setOpenMenu}
        openMenu={props.openMenu}
        isLoggedIn={props.isLoggedIn}
        routeData={props.routeData}
        webSettings={props.webSettings}
      />
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Seo
        title={props.routeData.pageTitle}
        location={props.routeData.path}
        webSettings={props.webSettings}
        seo={props.seo}
      />
      <div
        style={{
          marginTop: '4rem',
          marginBottom: props.isLoggedIn && windowWidth <= 991 ? '2rem' : '',
        }}
      >
        <div className="container-fluid">
          {props.routeData.isSPA &&
            pDataSPA.map((row) =>
              row.childItems.map((col) =>
                col.childItems.map(
                  (comp, compIndex) =>
                    comp.className.className ===
                      props.webSettings['webSettings-navbarComponent'] && (
                      <React.Fragment key={compIndex}>
                        {(TagName = Web.Web[comp.className.className])}

                        <TagName
                          auth={props.auth}
                          sessionIsActive={props.sessionIsActive}
                          authData={props.authData}
                          setRoleAccess={props.setRoleAccess}
                          setRoleModal={props.setRoleModal}
                          setOpenMenu={props.setOpenMenu}
                          openMenu={props.openMenu}
                          isLoggedIn={props.isLoggedIn}
                          routeData={props.routeData}
                          pageData={comp}
                          windowWidth={props.windowWidth}
                          webSettings={props.webSettings}
                        />
                      </React.Fragment>
                    )
                )
              )
            )}

          {!props.isOnline && (
            <div className="col-md-12 b-all text-center bg-dark border-2 rounded border_Width-1 mt-4">
              <h4 className="title text-light">No Internet Connection!</h4>
            </div>
          )}
          {/* <Web.Web.Features21
            pageData={{
              header: { header: 'feature21header' },
              text: { text: 'feature21text' },
              button: { button: 'Button' },
            }}
          /> */}
          {pData.length > 0 &&
            pData.map((row, rIndex) =>
              row.childItems.map((col) =>
                col.childItems.map((comp, compIndex) => (
                  <div key={compIndex}>
                    {(TagName = Web.Web[comp.className.className])}
                    {TagName ? (
                      <>
                        {comp.className.className !==
                          props.webSettings['webSettings-navbarComponent'] &&
                          editMode &&
                          props.authData.role === 'AppAdmin' && (
                            <div className="row builderbox">
                              <div className="col-md-12 builderbottomRow">
                                <button
                                  className="btn btn-primary btn-sm"
                                  style={{
                                    marginLeft: '1rem',
                                    marginTop: rIndex === 0 ? '10px !important' : '0px',
                                  }}
                                  onClick={() => openLeft()}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                                <Drops elemIndex={rIndex} />
                              </div>
                            </div>
                          )}
                        <div
                          key={compIndex}
                          className={
                            editMode && props.authData.role === 'AppAdmin'
                              ? 'builderdndcard w-100'
                              : ''
                          }
                          style={{
                            minHeight:
                              editMode &&
                              props.authData.role === 'AppAdmin' &&
                              comp.className.className ===
                                props.webSettings['webSettings-navbarComponent']
                                ? '150px'
                                : 'auto',
                          }}
                        >
                          <div className="buildertopright">
                            <button
                              className="btn btn-danger btn-sm "
                              onClick={() =>
                                confirmDelete([
                                  row.pageElementId,
                                  col.pageElementId,
                                  comp.pageElementId,
                                ])
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                          <div className="buildertopleft">
                            <span className="btn btn-primary btn-sm ">
                              {comp.className.className}
                            </span>
                          </div>
                          <div className="builderbottomright">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => openRight(comp)}
                            >
                              <i className="fa fa-cog"></i>
                            </button>
                          </div>

                          <TagName
                            auth={props.auth}
                            sessionIsActive={props.sessionIsActive}
                            authData={props.authData}
                            setRoleAccess={props.setRoleAccess}
                            setRoleModal={props.setRoleModal}
                            setOpenMenu={props.setOpenMenu}
                            openMenu={props.openMenu}
                            isLoggedIn={props.isLoggedIn}
                            routeData={props.routeData}
                            pageData={comp}
                            windowWidth={props.windowWidth}
                            webSettings={props.webSettings}
                          />
                        </div>

                        {comp.className.className !==
                          props.webSettings['webSettings-footerComponent'] &&
                          rIndex === pData.length - 1 &&
                          editMode &&
                          props.authData.role === 'AppAdmin' && (
                            <div className="row builderbox">
                              <div className="col-md-12 builderbottomRow">
                                <button
                                  className="btn btn-primary btn-sm"
                                  style={{ marginLeft: '1rem' }}
                                  onClick={() => openLeft()}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                                <Drops elemIndex={rIndex} />
                              </div>
                            </div>
                          )}
                      </>
                    ) : (
                      <div>
                        <div
                          key={compIndex}
                          className={
                            editMode && props.authData.role === 'AppAdmin'
                              ? 'builderdndcard w-100'
                              : ''
                          }
                          style={{
                            minHeight:
                              editMode &&
                              props.authData.role === 'AppAdmin' &&
                              comp.className.className ===
                                props.webSettings['webSettings-navbarComponent']
                                ? '150px'
                                : 'auto',
                            paddingBottom: '4rem',
                          }}
                        >
                          <div className="buildertopright">
                            <button
                              className="btn btn-danger btn-sm "
                              onClick={() =>
                                confirmDelete([
                                  row.pageElementId,
                                  col.pageElementId,
                                  comp.pageElementId,
                                ])
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                          <div className="buildertopleft">
                            <span className="btn btn-primary btn-sm ">
                              {comp.className.className}
                            </span>
                          </div>
                          <div className="builderbottomright">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => openRight(comp)}
                            >
                              <i className="fa fa-cog"></i>
                            </button>
                          </div>
                          Something went wrong
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )
            )}

          {editMode && props.authData.role === 'AppAdmin' && pData.length <= 0 && (
            <>
              <div className="row builderbox">
                <div className="col-md-12 builderbottomRow">
                  <button
                    className="btn btn-primary btn-sm"
                    style={{
                      marginLeft: '1rem',
                      marginTop: '8rem !important',
                    }}
                    onClick={() => openLeft()}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                  <Drops elemIndex={0} />
                </div>
              </div>
            </>
          )}

          {props.routeData.isSPA &&
            pDataSPA.map((row) =>
              row.childItems.map((col) =>
                col.childItems.map(
                  (comp, compIndex) =>
                    comp.className.className ===
                      props.webSettings['webSettings-footerComponent'] && (
                      <React.Fragment key={compIndex}>
                        {(TagName = Web.Web[comp.className.className])}

                        <TagName
                          auth={props.auth}
                          sessionIsActive={props.sessionIsActive}
                          authData={props.authData}
                          setRoleAccess={props.setRoleAccess}
                          setRoleModal={props.setRoleModal}
                          setOpenMenu={props.setOpenMenu}
                          openMenu={props.openMenu}
                          isLoggedIn={props.isLoggedIn}
                          routeData={props.routeData}
                          pageData={comp}
                          windowWidth={props.windowWidth}
                          webSettings={props.webSettings}
                        />
                      </React.Fragment>
                    )
                )
              )
            )}
        </div>

        {drawLeft && <LeftDrawer compData={compData} closeLeft={closeLeft} />}
        {drawRight && (
          <RightDrawer
            key={compData.className.className}
            compData={compData}
            closeRight={closeRight}
            pageId={props.routeData.pageId}
            pageName={props.routeData.name.replace(' ', '')}
          />
        )}

        <div
          id="mymodal6"
          className="modal fade custom-modal modal1 show"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{
            display: deleteConfirm ? 'block' : 'none',
            backgroundColor: 'rgba(55,55,55,0.3)',
          }}
        >
          <div className="modal-dialog modal-md" style={{ textAlign: 'center' }}>
            <div
              className="modal-content po-relative"
              style={{ display: 'inline-block', border: 'none' }}
            >
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col">
                    <div className="modal-bg text-center ">
                      <i className="fa fa-times-circle-o fa-5x text-danger"></i>
                      <br />
                      Are you sure you want to delete this?
                      <button
                        type="button"
                        className="btn btn-success-gradiant text-white btn-md btn-arrow m-t-20"
                        onClick={() => setDeleteConfirm(false)}
                      >
                        <span>Cancel</span>
                      </button>{' '}
                      <button
                        type="button"
                        className="btn btn-danger-gradiant text-white btn-md btn-arrow m-t-20 ml-2"
                        onClick={() => onDeleteForm()}
                      >
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};
