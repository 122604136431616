import React from 'react';
import { Sidebar } from '../../components/Layout/Sidebar';
import { axios } from '../../lib/query/axios';
import httpParams from '../../lib/query/httpParams';
import { default as dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { useUpdateCall } from '../../lib/api/update';
import { IsLoading } from '../../components/Layout/IsLoading';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const StaffAttendance = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
}) => {
  const errState = { error: false };
  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;
  });
  const params = httpParams.adminOrgParams();
  const [autoRefresh, setAutoRefresh] = React.useState(false);

  const [dates, setDates] = React.useState(null);
  const [classes, setClasses] = React.useState(null);

  const [attenData, setAttenData] = React.useState({ data: { formData: [] } });

  const [selectedApplData, setSelectedApplData] = React.useState({ data: { formData: [] } });

  const getData = async () => {
    const attnData = await axios.get(
      schema.api +
        params +
        '&checkInDateTime=' +
        dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss') +
        '&timeStamp=' +
        dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss')
    );
    setSelectedApplData({ data: { ...attnData } });
  };
  React.useEffect(() => getData(), []);
  const updateCallMutation = useUpdateCall();

  const onSubmit = async (data, type) => {
    const payload = { ...data };
    payload['attendanceType'] = type;

    await updateCallMutation
      .mutateAsync({
        apiEndpoint: schema.api,
        data: payload, // data,
        id: payload.staffAttendanceId,
        messageTitle: 'Attendance',
        message: 'Attendance updated successfully!',
        params,
      })
      .then(async () => {
        const attnData = await axios.get(
          schema.api + params + '&checkInDateTime=' + dates !== null
            ? dates
            : dayjs(new Date()).format('YYYY-MM-DD')
        );
        setSelectedApplData({ data: { ...attnData } });
      });
    setAttenData();
  };

  const getOnchangeAppl = async (e, typ) => {
    if (typ === 'date') {
      setDates(e);
      const attnData = await axios.get(
        schema.api +
          params +
          '&checkInDateTime=' +
          dayjs.utc(dates).format('YYYY-MM-DD HH:mm:ss') +
          '&timdatesStamp=' +
          dayjs.utc(dates).format('YYYY-MM-DD HH:mm:ss') +
          '&className=' +
          classes
      );
      setSelectedApplData({ data: { ...attnData } });
    } else {
      setClasses(e);
      const attnData = await axios.get(
        schema.api +
          params +
          '&checkInDateTime=' +
          dayjs.utc(dates).format('YYYY-MM-DD HH:mm:ss') +
          '&timdatesStamp=' +
          dayjs.utc(dates).format('YYYY-MM-DD HH:mm:ss') +
          '&className=' +
          e
      );
      setSelectedApplData({ data: { ...attnData } });
    }
  };

  const calculateHours = (outT, inT) => {
    const outTime = dayjs(outT);
    const inTime = dayjs(inT);
    const diff = dayjs.duration(outTime.diff(inTime));
    const diffFormatted = diff.format('HH:mm');

    return diffFormatted;
  };

  if (getData.isLoading) {
    return <IsLoading />;
  }

  if (getData.data?.formData?.length <= 0 && getData.data.error) {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar
              navs={navs}
              attenData={attenData}
              routesData={routesData}
              authData={authData}
            />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">
                  Error - {getData.data.error.response.status}
                </h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
  try {
    return (
      <>
        <div key={getData.data?.formData} className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {/* {schema.title} */}
                <label className="custSwitch" style={{ float: 'right' }}>
                  <input
                    type="checkbox"
                    defaultChecked={autoRefresh}
                    className="colorinput-input"
                    onChange={() => setAutoRefresh(!autoRefresh)}
                  />
                  <span
                    className="custSlider round"
                    style={{
                      width: '100% !important',
                      height: '100% !important',
                      backgroundColor: autoRefresh !== true && '#CCC',
                    }}
                  ></span>
                </label>
                <small style={{ float: 'right', marginRight: '1rem' }}>Auto Refresh</small>
              </h3>
              <h3 className="title d-none d-lg-block">
                {schema.title}{' '}
                <label className="custSwitch" style={{ float: 'right' }}>
                  <input
                    type="checkbox"
                    defaultChecked={autoRefresh}
                    className="colorinput-input"
                    onChange={() => setAutoRefresh(!autoRefresh)}
                  />
                  <span
                    className="custSlider round"
                    style={{
                      width: '100% !important',
                      height: '100% !important',
                      backgroundColor: autoRefresh !== true && '#CCC',
                    }}
                  ></span>
                </label>
                <small style={{ float: 'right', marginRight: '1rem' }}>Auto Refresh</small>
              </h3>
            </div>
            <div key="date" className="col-12 mb-3">
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  getOnchangeAppl(e.target.value, 'date');
                }}
                defaultValue={dates}
              />
            </div>

            <Sidebar
              navs={navs}
              attenData={attenData}
              routesData={routesData}
              authData={authData}
            />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2">{schema.title}</h2>
                <hr />
                <div id="header-with-footer-html">
                  {selectedApplData.data?.formData.length > 0 ? (
                    selectedApplData.data?.formData
                      .sort((a, b) => new Date(b.checkInDateTime) - new Date(a.checkInDateTime))
                      .map((atten, attenInd) => (
                        <div key={attenInd} className="card b-all">
                          <div
                            className="card-header bg-info text-white"
                            style={{ display: 'flex' }}
                          >
                            <div style={{ width: '50%' }}>
                              {dayjs(atten.checkInDateTime)
                                .utcOffset(tzOffset, true)
                                .format('MM/DD/YYYY')}{' '}
                            </div>
                          </div>
                          <div className="card-body row">
                            <div className="col-md-4 col-sm-12">
                              <h4 className="card-title">{atten.AppUser?.userFullName}</h4>
                              <p>{calculateHours(atten.checkOutDateTime, atten.checkInDateTime)}</p>
                            </div>
                            <div className="col-md-8 col-sm-12">
                              <div className="row">
                                <div
                                  className="col-md-6 col-sm-12"
                                  title="Check-In Time - Check-In Confirmed Time"
                                >
                                  {dayjs(atten.checkInDateTime)
                                    .utcOffset(tzOffset, true)
                                    .format('hh:mm A')}{' '}
                                  -
                                  {atten.checkInConfirmedDateTime !== null
                                    ? dayjs(atten.checkInConfirmedDateTime)
                                        .utcOffset(tzOffset, true)
                                        .format('hh:mm A')
                                    : ' NA '}
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  {atten.checkOutDateTime !== null
                                    ? dayjs(atten.checkOutDateTime)
                                        .utcOffset(tzOffset, true)
                                        .format('hh:mm A')
                                    : ' NA '}{' '}
                                  -
                                  {atten.checkOutConfirmedDateTime !== null
                                    ? dayjs(atten.checkOutConfirmedDateTime)
                                        .utcOffset(tzOffset, true)
                                        .format('hh:mm A')
                                    : ' NA '}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-sm-12">
                                  {atten.checkInConfirmedDateTime === null && (
                                    <button
                                      type="button"
                                      onClick={() => onSubmit(atten, 1)}
                                      className="btn btn-sm btn-success"
                                    >
                                      Check-In Confirm
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  {atten.checkOutDateTime !== null &&
                                    atten.checkOutConfirmedDateTime === null && (
                                      <button
                                        type="button"
                                        onClick={() => onSubmit(atten, 3)}
                                        className="btn btn-sm btn-danger"
                                      >
                                        Check-out Confirm
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div>No records found!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
