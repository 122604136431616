import React from 'react';
import { default as dayjs } from 'dayjs';

import { useGetCall } from '../../lib/api/get';
import { useCreateImageCall } from '../../lib/api/create';
import httpParams from '../../lib/query/httpParams';
import { schema as appSchema } from '../../schema';

export const ImageManager = (props) => {
  const params = httpParams.adminOrgParams();
  const getQuery = useGetCall('image', params);
  const createCallMutation = useCreateImageCall();
  const [clipboardMessage, setSlipboardMessage] = React.useState(null);
  const [imageFile, setImageFile] = React.useState(null);

  const copyImageLink = (link) => {
    navigator.clipboard.writeText(link);
    setSlipboardMessage(link);
  };

  const uploadImage = async () => {
    let formData = new FormData();
    formData.append('file', imageFile);
    formData['type'] = 'Image';
    await createCallMutation.mutateAsync({
      apiEndpoint: 'image',
      data: formData,
      messageTitle: 'Images',
      message: 'Image Uploaded!',
      params: params,
    });
  };

  if (getQuery.isLoading) {
    return (
      <>
        <div
          className={`spinner-border text-${appSchema.appSettings?.loader}`}
          role="status"
          style={{ position: 'fixed', top: '50%', left: '50%' }}
        >
          <span className="sr-only">Loading...</span>
        </div>
        <span className="sr-only">Loading...</span>
      </>
    );
  }

  return (
    <div
      id="mymodal6"
      className="modal fade custom-modal modal2 show"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: 'block',
        backgroundColor: 'rgba(55,55,55,0.3)',
      }}
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg">
        <div className="modal-content po-relative">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="modal-bg">
                  <h2 className="font-light m-b-0 mb-2">Image Manager</h2>

                  <div className="row cScrollBar" style={{ maxHeight: '100%' }}>
                    <div className="col-12">
                      <div className="mx-auto">
                        <div className="input-group mb-3">
                          <input
                            type="file"
                            className="form-control custom-file-input"
                            onChange={(e) => setImageFile(e.target.files[0])}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-success text-white"
                              type="button"
                              onClick={() => uploadImage()}
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="row shop-listing cScrollBar" style={{ maxHeight: '70vh' }}>
                    {/* column  */}
                    {getQuery.data?.formData.map((image, imageIndex) => (
                      <div className="col-lg-3 col-md-6" key={imageIndex}>
                        <div className="card shop-hover">
                          <img src={image.fileUrl} alt="imageLibrary" className="img-fluid" />
                          <div className="card-img-overlay align-items-center">
                            <button
                              className="btn btn-sm btn-info-gradiant"
                              onClick={() => copyImageLink(image.fileUrl)}
                            >
                              Copy
                            </button>
                          </div>
                          {clipboardMessage !== null && clipboardMessage === image.fileUrl && (
                            <span className="label label-rounded label-success">Copied</span>
                          )}
                        </div>
                        <div className="card">
                          <h5>
                            {dayjs(image.creationTime).format(process.env.REACT_APP_DATE_FORMAT)}
                          </h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <a
              href="#!"
              className="close-btn d-flex align-items-center justify-content-center"
              data-bs-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.setImageManage(false)}
            >
              ×
            </a>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};
