import React from 'react';
import QRCode from 'react-qr-code';

import { Sidebar } from '../../components/Layout/Sidebar';
import { useGetCall } from '../../lib/api/get';
import httpParams from '../../lib/query/httpParams';
import { Pagination } from '../../components/Layout/Pagination';
import { getDependencyCall } from '../../lib/api/dependencies';
import { useDependencies } from '../../store/dependencies';
import { ModalForm } from '../../components/Layout/ModalForm';
import { IsLoading } from '../../components/Layout/IsLoading';

export const QRCodeForm = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
}) => {
  const errState = { error: false };
  const params = httpParams.adminOrgParams();
  const getData = useGetCall(
    schema.getById
      ? schema.api + '/' + JSON.parse(localStorage.getItem('authent')).state.session.user.userId
      : schema.api,
    params
  );
  const [activePage, setActivePage] = React.useState(1);
  const [passcode, setPasscode] = React.useState(false);

  const dependencys = useDependencies((state) => state);
  const { setDependency } = useDependencies();

  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;
    if (schema.hasDependencies) {
      const dependeciesFormAPIS = {};
      schema.dependeciesFormAPIS.map(async (api) => {
        if (!dependencys.dependeciesFormAPIS[api]) {
          dependeciesFormAPIS[api] = await getDependencyCall(api, params);
          setDependency(dependeciesFormAPIS, dependencys.role, dependencys.roleId);
        }
      });
    }
  }, []);

  const handlePage = (page) => {
    setActivePage(page);
  };

  if (getData.isLoading) {
    return <IsLoading />;
  }

  if (getData.data?.formData?.length <= 0 && getData.data.error) {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">
                  Error - {getData.data.error.response.status}
                </h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }

  if (getData.data?.formData?.length <= 0) {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">No recors found!</div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }

  try {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3 text-center mt-2">
                <>
                  {schema.noFullCode ? (
                    <>
                      <h3>{getData.data.formData[schema.displayName]}</h3>
                      <QRCode
                        value={`${getData.data.formData[schema.prefixKey]}${
                          getData.data.formData[schema.codeKey]
                        }`}
                      />
                    </>
                  ) : (
                    <>
                      <h3>{getData.data.formData[activePage - 1][schema.displayName]}</h3>
                      <QRCode
                        value={getData.data.formData[activePage - 1][schema.qrCodeStringKey]}
                      />
                    </>
                  )}
                  <div className="mt-3 mb-3">
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="button"
                      onClick={() => setPasscode(true)}
                    >
                      <span className="btn-label">
                        <i className="fa fa-qrcode"></i>{' '}
                      </span>
                      Passcode
                    </button>
                  </div>
                </>
              </div>
              {getData.data.formData.length > 1 && (
                <div className="float-right">
                  <Pagination
                    currentPage={activePage}
                    totalCount={getData.data.formData.length}
                    siblingCount={1}
                    pageSize={1}
                    onPageChange={handlePage}
                    align="justify-content-end"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {passcode && (
          <ModalForm
            key={`${activePage}${schema.moduleName}`}
            authData={authData}
            defaultData={
              schema.noFullCode ? getData.data.formData : getData.data.formData[activePage - 1]
            }
            session={session}
            schema={schema.modalForm}
            navs={navs}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            closeModal={setPasscode}
          />
        )}
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    console.log(errState);
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
