import Axios from 'axios';
import { API_URL } from '../../config';

function authRequestInterceptor(config) {
  const authent = localStorage.getItem('authent') && JSON.parse(localStorage.getItem('authent'));
  const localToken = localStorage.getItem('authent') && authent.state?.authentication; //decryptData(localStorage.getItem('authent') && authent.state?.authentication);
  const token = localToken?.tokenObject?.id_token;
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
    config.headers.tokenSource = localStorage.getItem('authent') && authent.state?.tokenSource;
  }
  config.headers.Accept = 'application/json';
  //config.headers['content-type'] = 'multipart/form-data';
  return config;
}
export const axios = Axios.create({
  baseURL: API_URL,
  timeout: 10000,
});
axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // const message = error.response?.data?.message || error.message;
    // useNotificationStore.getState().addNotification({
    //   type: 'error',
    //   title: 'Error',
    //   message,
    // });
    return Promise.reject(error);
  }
);
