import { useQuery, useMutation } from 'react-query';
import { axios } from '../query/axios_get';

export const getCall = (apiEndpoint, params) => {
  return axios.get('/' + apiEndpoint + params);
};

export const useGetCall = (apiEndpoint, params, doPolling, enabled = true, { config } = {}) => {
  return useQuery({
    ...config,
    refetchInterval: doPolling ? 30000 : false,
    queryKey: [apiEndpoint],
    queryFn: () => getCall(apiEndpoint, params),
    enabled: enabled,
  });
};

export const useGetDocsCall = (call, { config } = {}) => {
  return useMutation(async (call) => await getCall(call.apiEndpoint, call.params), {
    onError: (_, __, context) => {},
    onSuccess: () => {},
    ...config,
    mutationFn: () => getCall,
  });
};

export const useGetDocsCalls = (apiEndpoint, params, doPolling, { config } = {}) => {
  return useQuery({
    ...config,
    refetchInterval: doPolling ? 30000 : false,
    queryKey: [apiEndpoint],
    queryFn: () => getCall(apiEndpoint, params),
  });
};

export const useGetPageCall = (apiEndpoint, id, page, params, enabled, { config } = {}) => {
  let pName = page.split(' ').join('');
  return useQuery({
    ...config,
    queryKey: [apiEndpoint],
    enabled: enabled,
    queryFn: () => getCall(apiEndpoint + '/' + id, params + '&pageName=' + pName),
  });
};

export const useGetSPAPageCall = (apiEndpoint, page, params, homePage, { config } = {}) => {
  return useQuery({
    ...config,
    queryKey: 'pageSPA',
    queryFn: () => getCall(apiEndpoint, params + '&pageName=' + homePage),
  });
};
