import jsPDF from 'jspdf';
import parse from 'html-react-parser';
import { renderToString } from 'react-dom/server';
import { default as dayjs } from 'dayjs';
import _ from 'lodash';

import { useGetCall } from '../../lib/api/get';
import httpParams from '../../lib/query/httpParams';

export const ActivityModal = (props) => {
  const params = httpParams.adminOrgParams();

  const getData = useGetCall(
    props.schema.modalAPI,
    params +
      '&attendanceId=' +
      props.selectedApplData.data.formData[props.activityAttenInd].attendanceId
  );
  if (getData.isLoading) {
    return <div>Loading...</div>;
  }
  const pdfData = {
    name: props.selectedApplData.data.formData[props.activityAttenInd].PickupPermission,
    pdfHeader: props.selectedApplData.data.formData[props.activityAttenInd],
  };

  const PDFSchema = ({ width, visibility, id, padding, groups }) => {
    const result = getData.data?.formData[0].rowFormData.reduce(function (r, a) {
      r[a.activityDetailType] = r[a.activityDetailType] || [];
      r[a.activityDetailType].push(a);
      return r;
    }, Object.create(null));

    const obs = Object.keys(result).map((key) => ({ key, value: result[key] }));

    return (
      <div
        className=""
        style={{
          color: '#000000',
          width: width,
          fontSize: '10px',
          visibility: visibility,
          ...padding,
        }}
        id={id}
      >
        {obs.map((egroup, groupIndex) => {
          if (egroup.key !== 'image') {
            return (
              <div key={groupIndex} className="row">
                <div className="col-md-12 text-center mb-2">
                  <h5>{egroup.key}</h5>
                </div>
                <div
                  className="col-md-3"
                  style={{
                    borderRight: 'solid',
                    borderRightWidth: 'thin',
                    borderRightColor: '#000000 !important',
                    borderLeft: 'solid',
                    borderLeftWidth: 'thin',
                    borderLeftColor: '#000000 !important',
                    borderTop: 'solid',
                    borderTopWidth: 'thin',
                    borderTopColor: '#000000 !important',
                    borderBottom: 'solid',
                    borderBottomWidth: 'thin',
                    borderBottomColor: '#000000 !important',
                    fontFamily: 'Arial, Helvetica, sans-serif',
                    letterSpacing: '0.005em',
                    paddingBottom: '8px',
                    paddingTop: '8px',
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                  }}
                >
                  Label
                </div>
                <div
                  className="col-md-5"
                  style={{
                    borderRight: 'solid',
                    borderRightWidth: 'thin',
                    borderRightColor: '#000000 !important',
                    borderTop: 'solid',
                    borderTopWidth: 'thin',
                    borderTopColor: '#000000 !important',
                    borderBottom: 'solid',
                    borderBottomWidth: 'thin',
                    borderBottomColor: '#000000 !important',
                    fontFamily: 'Arial, Helvetica, sans-serif',
                    letterSpacing: '0.005em',
                    paddingBottom: '8px',
                    paddingTop: '8px',
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                  }}
                >
                  Description
                </div>
                <div
                  className="col-md-4"
                  style={{
                    borderRight: 'solid',
                    borderRightWidth: 'thin',
                    borderRightColor: '#000000 !important',
                    borderTop: 'solid',
                    borderTopWidth: 'thin',
                    borderTopColor: '#000000 !important',
                    borderBottom: 'solid',
                    borderBottomWidth: 'thin',
                    borderBottomColor: '#000000 !important',
                    fontFamily: 'Arial, Helvetica, sans-serif',
                    letterSpacing: '0.005em',
                    paddingBottom: '8px',
                    paddingTop: '8px',
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                  }}
                >
                  Notes
                </div>
                {egroup.value.map(
                  (act, actIndex) =>
                    act.activityDetailDescription !== null && (
                      <>
                        <div
                          key={actIndex}
                          className="col-md-3"
                          style={{
                            borderRight: 'solid',
                            borderRightWidth: 'thin',
                            borderRightColor: '#000000 !important',
                            borderLeft: 'solid',
                            borderLeftWidth: 'thin',
                            borderLeftColor: '#000000 !important',
                            borderTop: 'solid',
                            borderTopWidth: 'thin',
                            borderTopColor: '#000000 !important',
                            borderBottom: 'solid',
                            borderBottomWidth: 'thin',
                            borderBottomColor: '#000000 !important',
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            letterSpacing: '0.005em',
                            paddingBottom: '2px',
                            paddingTop: '2px',
                            fontSize: '0.75rem',
                          }}
                        >
                          {act.activityDetailLabel}
                        </div>
                        <div
                          key={actIndex}
                          className="col-md-5"
                          style={{
                            borderRight: 'solid',
                            borderRightWidth: 'thin',
                            borderRightColor: '#000000 !important',
                            borderTop: 'solid',
                            borderTopWidth: 'thin',
                            borderTopColor: '#000000 !important',
                            borderBottom: 'solid',
                            borderBottomWidth: 'thin',
                            borderBottomColor: '#000000 !important',
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            letterSpacing: '0.005em',
                            paddingBottom: '2px',
                            paddingTop: '2px',
                            fontSize: '0.75rem',
                          }}
                        >
                          {act.activityDetailDescription}
                        </div>
                        <div
                          key={actIndex}
                          className="col-md-4"
                          style={{
                            borderRight: 'solid',
                            borderRightWidth: 'thin',
                            borderRightColor: '#000000 !important',
                            borderTop: 'solid',
                            borderTopWidth: 'thin',
                            borderTopColor: '#000000 !important',
                            borderBottom: 'solid',
                            borderBottomWidth: 'thin',
                            borderBottomColor: '#000000 !important',
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            letterSpacing: '0.005em',
                            paddingBottom: '2px',
                            paddingTop: '2px',
                            fontSize: '0.75rem',
                          }}
                        >
                          {act.activityDetailNotes}
                        </div>
                      </>
                    )
                )}
              </div>
            );
          }
        })}
        {/* {groupped.map((group, groupIndex) => (
          <div key={groupIndex}>
            {group}
            {group.map((act, actIndex) => (
              <div key={actIndex}>{act.activityDetailLabel}</div>
            ))}
          </div>
        ))} */}
        {/* {props.pdfSchema.map((row, rowIndex) => (
          <div
            className="row"
            style={{
              borderTopColor: '#000000 !important',
              borderTop: 'solid',
              borderTopWidth: 'thin',
              borderBottom: rowIndex === props.pdfSchema.length - 1 ? 'solid' : 'none',
              borderBottomWidth: 'thin',
            }}
          >
            {row.isRowData
              ? getData.data &&
                getData.data.formData &&
                getData.data.formData[0][row.rowFormDataKey].map(
                  (dataRows, dataRowInd) =>
                    dataRows.activityDetailType !== 'image' &&
                    row.cols.map((col, colIndex) => (
                      <div
                        style={{
                          borderRight: 'solid',
                          borderRightWidth: 'thin',
                          borderRightColor: '#000000 !important',
                          borderTop:
                            dataRowInd > 0 &&
                            dataRowInd < getData.data.formData[0][row.rowFormDataKey].length - 1
                              ? 'solid'
                              : 'none',
                          borderTopWidth: 'thin',
                          borderTopColor: '#000000 !important',
                          borderLeft: colIndex === 0 ? 'solid' : 'none',
                          borderLeftWidth: 'thin',
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          fontSize: col.fontSize,
                          letterSpacing: '0.005em',
                          textAlign: col.textAlign,
                          fontWeight: col.fontWeight,
                          ...col.styles,
                        }}
                        className={col.colSize}
                      >
                        {dataRows[col.dataKey]}
                      </div>
                    ))
                  // console.log(dataRows)
                )
              : row.cols.map((col, colIndex) => (
                  //console.log(col)
                  <div
                    style={{
                      borderRight: 'solid',
                      borderRightWidth: 'thin',
                      borderRightColor: '#000000 !important',
                      borderLeft: colIndex === 0 ? 'solid' : 'none',
                      borderLeftWidth: 'thin',
                      fontFamily: 'Arial, Helvetica, sans-serif',
                      fontSize: col.fontSize,
                      letterSpacing: '0.005em',
                      textAlign: col.textAlign,
                      fontWeight: col.fontWeight,
                      ...col.styles,
                    }}
                    className={col.colSize}
                  >
                    {parse(col.text)}
                    {col.isDate
                      ? pdfData[col.dataConst][col.dataKey] === 'Invalid Date' ||
                        pdfData[col.dataConst][col.dataKey] === null
                        ? '-'
                        : dayjs(pdfData[col.dataConst][col.dataKey]).format(col.dateFormat)
                      : col.hasData && pdfData[col.dataConst][col.dataKey]}
                  </div>
                ))}
          </div>
        ))} */}
      </div>
    );
  };

  const generatePDF = () => {
    const pdf = new jsPDF('p', 'pt', 'a4', true);
    pdf.setFont('times');
    pdf.setFontSize(8);

    const string = <PDFSchema width="147mm" visibility="visible" id="toPrint" />;

    pdf.html(renderToString(string), {
      callback: function (doc) {
        doc.save(props.pdfSchemaSettings.pdfName);
      },
      x: 20,
      y: 20,
    });
  };

  return (
    <div
      id="mymodal6"
      className="modal fade custom-modal modal2 show"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: 'block',
        backgroundColor: 'rgba(55,55,55,0.3)',
      }}
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content po-relative">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="modal-bg">
                  <h2 className="font-light m-b-0 mb-2">
                    Activity{' '}
                    <button
                      onClick={() => generatePDF()}
                      className="btn btn-primary btn-sm float-right"
                    >
                      <i className="fa fa-file-pdf-o" /> Download PDF
                    </button>
                  </h2>{' '}
                  <hr />
                  <PDFSchema
                    width="100%"
                    groups={getData.data?.formData[0].rowFormData}
                    padding={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px' }}
                    visibility="visible"
                    id="toView"
                  />
                </div>
              </div>
            </div>
            <a
              href="#!"
              className="close-btn d-flex align-items-center justify-content-center mr-3"
              data-bs-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.setOpenActivity(false)}
              style={{ right: '30px', fontSize: '3rem' }}
            >
              ×
            </a>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};
