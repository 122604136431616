import React from 'react';
import { useForm } from 'react-hook-form';
import * as Y from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Sidebar } from '../../components/Layout/Sidebar';
import { useGetCall } from '../../lib/api/get';
import { axios } from '../../lib/query/axios';
import httpParams from '../../lib/query/httpParams';
import { default as dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ActivityModal } from './ActivityModal';
import { useCreateCall } from '../../lib/api/create';
import { useUpdateCall } from '../../lib/api/update';
import { IsLoading } from '../../components/Layout/IsLoading';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const ChildAttendance = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
}) => {
  const errState = { error: false };
  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;
  });
  const params = httpParams.adminOrgParams();
  const createCallMutation = useCreateCall();
  const [autoRefresh, setAutoRefresh] = React.useState(false);

  const [dates, setDates] = React.useState(null);
  const [classes, setClasses] = React.useState(null);

  const [attenData, setAttenData] = React.useState({ data: { formData: [] } });

  const [selectedApplData, setSelectedApplData] = React.useState({ data: { formData: [] } });

  const getData = async () => {
    const attnData = await axios.get(
      schema.api +
        params +
        '&checkInDateTime=' +
        dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss') +
        '&timeStamp=' +
        dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss')
    );
    setSelectedApplData({ data: { ...attnData } });
  };
  React.useEffect(() => getData(), []);
  const updateCallMutation = useUpdateCall();

  const onSubmit = async (data, type) => {
    const payload = { ...data };
    payload['attendanceType'] = type;
    await updateCallMutation.mutateAsync({
      apiEndpoint: 'attendance',
      parentAPI: 'attendance',
      data: payload, // data,
      id: payload.attendanceId,
      messageTitle: 'Attendance',
      message: 'Attendance updated successfully!',
      params,
      refetchParam: true,
    });
    setAttenData();
  };

  const validation = Y.object().shape({
    document: Y.mixed().required('Choose a document to upload!'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validation),
  });

  const [openActivity, setOpenActivity] = React.useState(false);
  const [openRowActivity, setOpenRowActivity] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const [rowData, setRowData] = React.useState(null);

  const onFormSubmit = async (data) => {
    let formData = new FormData();
    formData.append('file', data.document[0]);
    formData.append('activityId', rowData.rowFormData[0].activityId);
    formData.append('activityDetailLabel', null);
    formData.append('activityDetailNotes', null);
    await createCallMutation.mutateAsync({
      apiEndpoint: 'activityDetail/image',
      parentAPI: 'activity',
      data: formData,
      messageTitle: 'Files',
      message: 'File uploaded successfully!',
      params: params,
    });
  };

  const getOnchangeAppl = async (e, typ) => {
    if (typ === 'date') {
      setDates(e);
      const attnData = await axios.get(
        '/attendance' +
          params +
          '&checkInDateTime=' +
          dayjs.utc(e).format('YYYY-MM-DD HH:mm:ss') +
          '&timeStamp=' +
          dayjs.utc(e).format('YYYY-MM-DD HH:mm:ss') +
          '&className=' +
          classes
      );
      setSelectedApplData({ data: { ...attnData } });
    } else if (typ === 'range') {
      setDates(e);
      const attnData = await axios.get(
        '/attendance' +
          params +
          '&checkInDateTimeFrom=' +
          dayjs.utc(e.start).format('YYYY-MM-DD HH:mm:ss') +
          '&checkInDateTimeTo=' +
          dayjs.utc(e.end).format('YYYY-MM-DD HH:mm:ss') +
          '&timeStampFrom=' +
          dayjs.utc(e.start).format('YYYY-MM-DD HH:mm:ss') +
          '&timeStampTo=' +
          dayjs.utc(e.end).format('YYYY-MM-DD HH:mm:ss') +
          '&className=' +
          classes
      );
      setSelectedApplData({ data: { ...attnData } });
    } else {
      setClasses(e);
      const attnData = await axios.get(
        '/attendance' +
          params +
          (typeof dates === 'object'
            ? `&checkInDateTimeFrom=${dates.start}&checkInDateTimeTo=${
                dates.end
              }&timeStampFrom=${dayjs
                .utc(dates.start)
                .format('YYYY-MM-DD HH:mm:ss')}&timeStampTo=${dayjs
                .utc(dates.end)
                .format('YYYY-MM-DD HH:mm:ss')}`
            : `&checkInDateTime=${dayjs.utc(dates).format('YYYY-MM-DD HH:mm:ss')}&timeStamp=${dayjs
                .utc(dates)
                .format('YYYY-MM-DD HH:mm:ss')}`) +
          '&className=' +
          e
      );
      setSelectedApplData({ data: { ...attnData } });
    }
  };

  const openActivityModal = async (data) => {
    setModalData(data);
    setOpenActivity(!openActivity);
  };

  const openActivityRow = async (data) => {
    setRowData(data);
    setOpenRowActivity(!openRowActivity);
  };

  if (getData.isLoading) {
    return <IsLoading />;
  }

  if (getData.data?.formData?.length <= 0 && getData.data.error) {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">
                  Error - {getData.data.error.response.status}
                </h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
  try {
    return (
      <>
        <div key={getData.data?.formData} className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {/* {schema.title} */}
                <label className="custSwitch" style={{ float: 'right' }}>
                  <input
                    type="checkbox"
                    defaultChecked={autoRefresh}
                    className="colorinput-input"
                    onChange={() => setAutoRefresh(!autoRefresh)}
                  />
                  <span
                    className="custSlider round"
                    style={{
                      width: '100% !important',
                      height: '100% !important',
                      backgroundColor: autoRefresh !== true && '#CCC',
                    }}
                  ></span>
                </label>
                <small style={{ float: 'right', marginRight: '1rem' }}>Auto Refresh</small>
              </h3>
              <h3 className="title d-none d-lg-block">
                {schema.title}{' '}
                <label className="custSwitch" style={{ float: 'right' }}>
                  <input
                    type="checkbox"
                    defaultChecked={autoRefresh}
                    className="colorinput-input"
                    onChange={() => setAutoRefresh(!autoRefresh)}
                  />
                  <span
                    className="custSlider round"
                    style={{
                      width: '100% !important',
                      height: '100% !important',
                      backgroundColor: autoRefresh !== true && '#CCC',
                    }}
                  ></span>
                </label>
                <small style={{ float: 'right', marginRight: '1rem' }}>Auto Refresh</small>
              </h3>
            </div>
            <div key="date" className="col-4 mb-3">
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  getOnchangeAppl(e.target.value, 'date');
                }}
                defaultValue={dates}
              />
            </div>
            <div key="class" className="col-4 mb-3">
              <select
                className="form-select"
                onChange={(e) => {
                  getOnchangeAppl(e.target.value, 'class');
                }}
              >
                <option>Select Class</option>
                {schema.classesNames.map((ops, opsInd) => (
                  <option key={opsInd} value={ops}>
                    {ops}
                  </option>
                ))}
              </select>
            </div>
            <div key="date" id="" className="col-4">
              <DateRangePicker
                initialSettings={{ startDate: new Date(), endDate: new Date() }}
                onCallback={(start, end, label) =>
                  getOnchangeAppl({ start: start._d, end: end._d }, 'range')
                }
              >
                <button className="btn btn-primary btn-sm">
                  <i className="fa fa-calendar" /> Date Range
                </button>
              </DateRangePicker>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2">{schema.title}</h2>
                <hr />
                <div id="header-with-footer-html">
                  {selectedApplData.data?.formData.length > 0 ? (
                    selectedApplData.data?.formData
                      .sort((a, b) => new Date(b.checkInDateTime) - new Date(a.checkInDateTime))
                      .map((atten, attenInd) => (
                        <div key={attenInd} className="card b-b">
                          {/* <div
                            className="card-header bg-info text-white"
                            style={{ display: 'flex' }}
                          >
                            <div style={{ width: '50%' }}>
                              {dayjs(atten.checkInDateTime).format('MM/DD/YYYY')}{' '}
                            </div>
                            <div style={{ width: '50%', textAlign: 'end' }}>
                              <button
                                type="button"
                                onClick={() => openActivityModal(atten)}
                                className="btn btn-sm btn-secondary ms-auto"
                              >
                                Activity
                              </button>
                            </div>
                          </div> */}
                          <div className="card-body row" style={{ padding: '5px' }}>
                            <div className="col-md-4 col-sm-12">
                              <h4 className="card-title">
                                {atten.PickupPermission?.userChildName}
                                {/* <br />
                                <small>{dayjs(atten.checkInDateTime).format('MM/DD/YYYY')}</small> */}
                              </h4>
                              {/* <p className="card-text">
                                {atten.checkOutConfirmedDateTime !== null
                                  ? dayjs(atten.checkOutConfirmedDateTime).format('hh:mm A')
                                  : atten.checkOutDateTime !== null
                                  ? dayjs(atten.checkOutDateTime).format('hh:mm A')
                                  : atten.checkInConfirmedDateTime !== null
                                  ? atten.checkInConfirmedDateTime !== null
                                    ? dayjs(atten.checkInConfirmedDateTime).format('hh:mm A')
                                    : ' - '
                                  : dayjs(atten.checkInDateTime).format('hh:mm A')}
                              </p> */}
                            </div>
                            <div className="col-md-8 col-sm-12">
                              {dayjs(atten.checkInDateTime)
                                .utcOffset(tzOffset, true)
                                .format('hh:mm A')}{' '}
                              -
                              {atten.checkOutDateTime !== null
                                ? dayjs(atten.checkOutDateTime)
                                    .utcOffset(tzOffset, true)
                                    .format('hh:mm A')
                                : ' NA '}{' '}
                              <button
                                type="button"
                                onClick={() => openActivityRow(atten, attenInd)}
                                className="btn btn-sm btn-primary ms-auto float-end"
                              >
                                <i className="fa fa-upload"></i>
                              </button>
                              <button
                                type="button"
                                onClick={() => openActivityModal(atten)}
                                className="btn btn-sm btn-secondary ms-auto float-end mr-2"
                              >
                                Activity
                              </button>{' '}
                              {/* <div className="row">
                                <div
                                  className="col-md-6 col-sm-12"
                                  title="Check-In Time - Check-In Confirmed Time"
                                >
                                  {dayjs(atten.checkInDateTime).format('hh:mm A')} -
                                  {atten.checkOutDateTime !== null
                                    ? dayjs(atten.checkOutDateTime).format('hh:mm A')
                                    : ' NA '}{' '}
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  {/* -
                                  {atten.checkOutConfirmedDateTime !== null
                                    ? dayjs(atten.checkOutConfirmedDateTime).format('hh:mm A')
                                    : ' NA '}
                                  <button
                                    type="button"
                                    onClick={() => openActivityModal(atten)}
                                    className="btn btn-sm btn-secondary ms-auto float-right"
                                  >
                                    Activity
                                  </button>{' '}
                                  <button
                                    type="button"
                                    onClick={() => openActivityModal(atten)}
                                    className="btn btn-sm btn-secondary ms-auto float-right"
                                  >
                                    Upload
                                  </button>
                                </div>
                              </div> */}
                              <div className="row">
                                <div className="col-md-6 col-sm-12">
                                  {atten.checkInConfirmedDateTime === null && (
                                    <button
                                      type="button"
                                      onClick={() => onSubmit(atten, 1)}
                                      className="btn btn-sm btn-success"
                                    >
                                      Check-In
                                    </button>
                                  )}
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  {atten.checkOutDateTime !== null &&
                                    atten.checkOutConfirmedDateTime === null && (
                                      <button
                                        type="button"
                                        onClick={() => onSubmit(atten, 3)}
                                        className="btn btn-sm btn-danger"
                                      >
                                        Check-out Confirm
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {openRowActivity && rowData.attendanceId === atten.attendanceId && (
                            <div className="row">
                              <div className="col-md-12">
                                <form onSubmit={handleSubmit(onFormSubmit)} className="row">
                                  <div className="col-6">
                                    <input
                                      type="file"
                                      {...register('document')}
                                      className="custom-file-input"
                                    />
                                  </div>
                                  <div className="col-6">
                                    <button type="submit" className="btn btn-primary btn-sm">
                                      {createCallMutation.isLoading ? (
                                        <>
                                          <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>{' '}
                                          Uploading...
                                        </>
                                      ) : (
                                        <>Upload</>
                                      )}
                                    </button>{' '}
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                  ) : (
                    <div>No records found!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {openActivity && (
          <ActivityModal
            schema={schema}
            session={session}
            setOpenActivity={setOpenActivity}
            data={modalData}
          />
        )}
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
