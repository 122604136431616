import { Link } from 'react-router-dom';
export const Sidebar = (props) => {
  const rs = [];
  props.routesData.map((r) => {
    if (r.isPrivate && r.component !== '') {
      rs.push({ ...r });
    }
  });

  let menus = [];
  props.authData.session.pages.forEach((p) =>
    p.roles.filter((f) => {
      if (f.roleName === props.authData.role) {
        menus.push(p);
      }
    })
  );

  const ms = menus.map((m) => {
    const t = props.routesData.filter((r) => r.isPrivate && r.name === m.pageName);
    return t;
  });
  return (
    <div className="col-lg-3 b-all rounded d-none d-lg-block">
      <div className="m-b-40">
        <h5 className="title">Menu</h5>
        <hr />
        <ul className="list-icons">
          {ms.map((nav, navI) => (
            <li key={navI}>
              <Link className="fw-normal" to={`/console/${nav[0].path}`}>
                {nav[0].pageTitle}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
