import React from 'react';
import { useForm } from 'react-hook-form';
import * as Y from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import httpParams from '../../lib/query/httpParams';
import { useGetCall } from '../../lib/api/get';
import { useCreateCall } from '../../lib/api/create';
import { RowForm } from '../../components/form/rowForm';

export const ActivityModal = (props) => {
  const params = httpParams.adminOrgParams();
  const createCallMutation = useCreateCall();
  const [isChildAdd, setIsChildAdd] = React.useState(false);
  const [isFileAdd, setIsFileAdd] = React.useState(false);
  const [activeActivityId, setActiveActivityId] = React.useState(null);
  const getData = useGetCall(
    'activity',
    params + '&attendanceId=' + props.data.rowFormData[0].attendanceId
  );

  const validation = Y.object().shape({
    document: Y.mixed().required('Choose a document to upload!'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validation),
  });

  const onFormSubmit = async (data) => {
    let formData = new FormData();
    formData.append('file', data.document[0]);
    formData.append('activityId', activeActivityId);
    formData.append('activityDetailLabel', data.activityDetailLabel);
    formData.append('activityDetailNotes', data.activityDetailNotes);
    await createCallMutation.mutateAsync({
      apiEndpoint: 'activityDetail/image',
      parentAPI: 'activity',
      data: formData,
      messageTitle: 'Files',
      message: 'File uploaded successfully!',
      params: params,
    });
  };

  const startActivityFile = (row) => {
    setActiveActivityId(row.activityId);
    setIsFileAdd(true);
  };

  const closeActivityFile = () => {
    setActiveActivityId(null);
    setIsFileAdd(false);
  };

  if (getData.isLoading) {
    return (
      <div
        id="mymodal6"
        className="modal fade custom-modal modal2 show"
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        style={{
          display: 'block',
          backgroundColor: 'rgba(55,55,55,0.3)',
        }}
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content po-relative">
            <div className="modal-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="modal-bg">
                    <h2 className="font-light m-b-0 mb-2">Activity</h2> <hr />{' '}
                    <div>Loading.....</div>
                  </div>
                </div>
              </div>
              <a
                href="#!"
                className="close-btn d-flex align-items-center justify-content-center mr-3"
                data-bs-dismiss="modal"
                aria-hidden="true"
                onClick={() => props.setOpenActivity(false)}
              >
                ×
              </a>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  }

  return (
    <div
      id="mymodal6"
      className="modal fade custom-modal modal2 show"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: 'block',
        backgroundColor: 'rgba(55,55,55,0.3)',
      }}
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content po-relative">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="modal-bg">
                  <h2 className="font-light m-b-0 mb-2">Activity</h2>{' '}
                  <div className="row" style={{ paddingRight: '1rem' }}>
                    <div
                      className="col-12 cScrollBar"
                      style={{ maxHeight: '30rem', overflowY: 'initial' }}
                    >
                      {props.schema.isRowForm && (
                        <>
                          <hr />
                          <div className="container mb-5">
                            <h3 className="font-light pt-2">{props.schema.rowForm.formTitle}</h3>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => setIsChildAdd(true)}
                            >
                              <i className="fa fa-plus"></i>
                            </button>

                            <hr />
                            {isFileAdd && (
                              <form onSubmit={handleSubmit(onFormSubmit)} className="row">
                                <div className="col-4" style={{ visibility: 'hidden' }}>
                                  <input
                                    type="text"
                                    {...register('activityId')}
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-4">
                                  <input
                                    type="text"
                                    {...register('activityDetailDescription')}
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-4">
                                  <input
                                    type="text"
                                    {...register('activityDetailNotes')}
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-2">
                                  <input
                                    type="file"
                                    {...register('document')}
                                    className="custom-file-input"
                                  />
                                </div>
                                <div className="col-2">
                                  <button type="submit" className="btn btn-primary">
                                    {createCallMutation.isLoading ? (
                                      <>
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>{' '}
                                        Uploading...
                                      </>
                                    ) : (
                                      <>Upload</>
                                    )}
                                  </button>{' '}
                                  <button
                                    type="button"
                                    onClick={() => closeActivityFile()}
                                    className="btn btn-secondary"
                                  >
                                    Close
                                  </button>
                                </div>
                              </form>
                            )}
                            {isChildAdd && (
                              <RowForm
                                key="addChildRow"
                                schema={props.schema.rowForm.create}
                                isAdd={isChildAdd}
                                crud={props.schema.rowForm.crud}
                                setIsAdd={setIsChildAdd}
                                session={props.session}
                                parentId={
                                  getData.data.formData[0][props.schema.rowForm.create.parentId]
                                }
                              />
                            )}
                            {getData.data.formData[0].rowFormData.map((row, rowIndex) => (
                              <>
                                <RowForm
                                  key={`${rowIndex}`}
                                  schema={props.schema.rowForm.update}
                                  crud={props.schema.rowForm.crud}
                                  defaultData={row}
                                  session={props.session}
                                />
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => startActivityFile(row)}
                                >
                                  <i className="fa fa-plus"></i> Upload Media
                                </button>
                                <hr />
                              </>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="#!"
              className="close-btn d-flex align-items-center justify-content-center mr-3"
              data-bs-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.setOpenActivity(false)}
              style={{ right: '30px', fontSize: '3rem' }}
            >
              ×
            </a>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};
