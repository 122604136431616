import { Helmet } from 'react-helmet';

export const Seo = ({ title, location, seo }) => {
  const structuredDataOrganization = `{ 
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${seo['seo-legalName']}",
		"url": "${seo['seo-url']}",
		"logo": "${seo['seo-logo']}",
		"foundingDate": "${seo['seo-foundingDate']}",
		"founders": [{
			"@type": "Person",
			"name": "${seo['seo-legalName']}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${seo['seo-contact-email']}",
			"telephone": "${seo['seo-contact-phone']}",
			"contactType": "customer service"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${seo['seo-address-city']}",
			"addressRegion": "${seo['seo-address-region']}",
			"addressCountry": "${seo['seo-address-country']}",
			"postalCode": "${seo['seo-address-zipCode']}"
		},
		"sameAs": [
			"${seo['seo-socialLinks-twitter']}",
			"${seo['seo-socialLinks-google']}",
			"${seo['seo-socialLinks-youtube']}",
			"${seo['seo-socialLinks-linkedin']}",
			"${seo['seo-socialLinks-instagram']}",
			"${seo['seo-socialLinks-github']}"
		]
  	}`;

  return (
    <Helmet>
      <meta name="description" content={seo['seo-defaultDescription']} />
      <meta name="image" content={process.env.REACT_APP_THEME_FAVICON} />

      <meta property="og:url" content={`${seo['seo-url']}${location}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={seo['seo-defaultDescription']} />
      <meta property="og:image" content={process.env.REACT_APP_THEME_FAVICON} />
      <meta property="fb:app_id" content={seo['seo-social.facebook']} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={seo['seo-socialLinks-twitter']} />
      <meta name="twitter:site" content={seo['seo-social-twitter']} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={seo['seo-defaultDescription']} />
      <meta name="twitter:image:src" content={process.env.REACT_APP_THEME_FAVICON} />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <link rel="publisher" href={seo['seo-socialLinks-google']} />
      <html lang="en" dir="ltr" />
    </Helmet>
  );
};
