import React from 'react';
import { Link } from 'react-router-dom';

export const Banner1 = (props) => {
  const errState = { error: false };
  try {
    return (
      <div
        id="static-slider1-html"
        className={props.pageData.cssClass.cssClass}
        style={{
          backgroundColor:
            props.pageData.bgColor.bgColor !== 0 ? props.pageData.bgColor.bgColor : '',
        }}
      >
        <div className="static-slider1">
          <div className="container">
            {/* Row  */}
            <div className="row">
              {/* Column */}
              <div
                className="col-md-7 align-self-center aos-init aos-animate"
                data-aos="fade-right"
                data-aos-duration={2200}
                data-aos-once={false}
              >
                <h1 className="title">
                  {props.pageData.text.text}{' '}
                  <span
                    className="text-success-gradiant typewrite"
                    data-period={2000}
                    data-type='[ "Photoshop", "HTML", "CSS3", "Angular CLI" ]'
                  >
                    <span className="wrap">{props.pageData.textColored.textColored}</span>
                  </span>
                </h1>
                <Link
                  className="btn btn-success-gradiant text-white btn-md btn-arrow m-t-20"
                  to={props.pageData.buttonLink.buttonLink}
                >
                  <span>
                    {props.pageData.buttonText.buttonText} <i className="ti-arrow-right" />
                  </span>
                </Link>
              </div>
              {/* Column */}
              <div
                className="col-md-5 img-anim m-t-40 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={2200}
                data-aos-once={false}
              >
                <img src={props.pageData.image.image} alt="trt" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
