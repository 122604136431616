import * as React from 'react';
import { QrReader } from 'react-qr-reader';
import { useForm } from 'react-hook-form';
import * as Y from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Sidebar } from '../../components/Layout/Sidebar';
import { useCreateCall } from '../../lib/api/create';
import { useUpdateCall } from '../../lib/api/update';
import httpParams from '../../lib/query/httpParams';

import './style.css';

export const CheckInOut = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
}) => {
  const errState = { error: false };
  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;
  });
  const params = httpParams.adminOrgParams();
  const [changeCam, setChangeCam] = React.useState('environment');
  const [havePermission, setHavePermission] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [isError, setIsError] = React.useState(false);
  const [results, setResults] = React.useState(null);
  const [scanInits, setScanInits] = React.useState(false);
  const [sendSuccess, setSendSuccess] = React.useState(false);
  const [sendFailed, setSendFailed] = React.useState(false);
  const [moduleType, setModuleType] = React.useState(
    window.location.pathname === '/console/check-out' ? 'CheckOut' : 'CheckIn'
  );
  const createCallMutation = useCreateCall();
  const updateCallMutation = useUpdateCall();

  const validationSchema = Y.object()
    .shape({
      ...schema.validation,
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const scanInit = () => {
    if (scanInits !== true) {
      setResults(null);
    }
    setScanInits(!scanInits);
  };

  const changeCamFunc = (face) => {
    setChangeCam(face);
  };

  const handleScan = async (data) => {
    setType(null);
    if (data) {
      window.localStream.getVideoTracks()[0].stop();
      const callMutation = moduleType === 'CheckOut' ? updateCallMutation : createCallMutation;
      const formData = {};
      formData[schema.authCodeField] = data.text;
      if (schema.checkInOnly) {
        formData['attendanceType'] = 0;
      } else {
        formData['attendanceType'] = moduleType === 'CheckOut' ? 2 : 0;
      }
      await callMutation
        .mutateAsync({
          apiEndpoint: schema.formAPI,
          data: formData,
          id: '',
          messageTitle: 'Attendance',
          message: 'Activity added successfully!',
          params: params,
        })
        .then((res) => {
          setType(null);
          setSendSuccess(true);
          setTimeout(() => setSendSuccess(false), 10000);
        })
        .catch((err) => {
          setSendFailed(true);
          setTimeout(() => setSendFailed(false), 10000);
        });
    }
  };

  const onSubmitForm = async (data, e) => {
    const callMutation = moduleType === 'CheckOut' ? updateCallMutation : createCallMutation;
    const formData = {};
    formData[schema.authCodeField] = data[schema.authCodeField];
    if (schema.checkInOnly) {
      formData['attendanceType'] = 0;
    } else {
      formData['attendanceType'] = moduleType === 'CheckOut' ? 2 : 0;
    }

    await callMutation
      .mutateAsync({
        apiEndpoint: schema.formAPI,
        data: formData,
        id: '',
        messageTitle: 'Attendance',
        message: 'Activity added successfully!',
        params: params,
      })
      .then((res) => {
        setType(null);
        setSendSuccess(true);
        setTimeout(() => setSendSuccess(false), 10000);
        e.target.reset();
      })
      .catch((err) => {
        setSendFailed(true);
        setTimeout(() => setSendFailed(false), 10000);
      });
  };

  const checkPermissions = (type) => {
    setType(type);
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    permissions
      .then((stream) => {
        window.localStream = stream;
        scanInit();
        setHavePermission(!havePermission);
      })
      .catch((err) => {
        setHavePermission(false);
        handleError();
      });
  };

  const authCode = (type) => {
    //window.localStream.getVideoTracks()[0].stop();
    setType(type);
  };

  const handleError = (err) => {
    setIsError(!isError);
  };

  try {
    return (
      <div className="container" style={{ marginTop: '6rem' }}>
        <div className="row">
          <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
            <h3 className="title d-block d-lg-none">
              <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
              {schema.title}
            </h3>
            <h3 className="title d-none d-lg-block">{schema.title} </h3>
          </div>
          <div className="col-12">
            <div className="row mb-3">
              <div className="col-6 text-center">
                <button
                  className={`btn btn-${moduleType === 'CheckIn' ? 'outline-primary' : 'primary'}`}
                  onClick={() => setModuleType('CheckIn')}
                >
                  {moduleType === 'CheckIn' && <i className="fa fa-check"></i>} Check-In
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  className={`btn btn-${moduleType === 'CheckOut' ? 'outline-primary' : 'primary'}`}
                  onClick={() => setModuleType('CheckOut')}
                >
                  {moduleType === 'CheckOut' && <i className="fa fa-check"></i>} Check-Out
                </button>
              </div>
            </div>
          </div>
          <Sidebar authData={authData} routesData={routesData} />
          <div
            className="col"
            style={{
              width: '50% !important',
              maxWidth: '50% !important',
              paddingRight: '0px !important',
            }}
          ></div>
          <div className="col-lg-8 b-all border-2 rounded border_Width-1">
            <div className="container mb-3 text-center mt-2">
              <>
                <h3>{moduleType === 'CheckIn' ? 'Check-In' : 'Check-Out'}</h3>
                <button className="btn btn-primary" onClick={() => checkPermissions('qr')}>
                  QR Code
                </button>{' '}
                <button className="btn btn-primary" onClick={() => authCode('auth')}>
                  Authcode
                </button>
                {type === 'qr' && (
                  <select
                    className="form-select mt-2"
                    onChange={(e) => changeCamFunc(e.target.value)}
                  >
                    <option value="user">Front Cam</option>
                    <option value="environment">Back Cam</option>
                  </select>
                )}
                {type === 'qr' && (
                  <div id="content">
                    <div id="camcontainer">
                      <div
                        id="camnavi"
                        style={{
                          width: '90%',
                          height: '65%',
                          zIndex: 2,
                          marginTop: '18%',
                          marginBottom: '15%',
                          marginRight: '15%',
                          marginLeft: '5%',
                        }}
                      >
                        <div className="ocrloader">
                          <p>Scanning</p>
                          <em></em>
                          <span></span>
                        </div>
                      </div>
                      <div id="caminfoi" style={{ width: '100%', zIndex: 1 }}>
                        <QrReader
                          results={results}
                          delay={300}
                          constraints={{ facingMode: changeCam }}
                          onResult={(result, error) => {
                            if (!!result) {
                              handleScan(result);
                            } else if (!!error) {
                              console.info(error);
                            }
                          }}
                          legacyMode
                        />
                      </div>
                    </div>
                  </div>
                )}
                {type === 'auth' && (
                  <form onSubmit={handleSubmit(onSubmitForm)} data-testid="formAdd" className="row">
                    <div className="input-group mb-3 mt-5">
                      <input
                        type="password"
                        placeholder="Passcode"
                        autoComplete="off"
                        className={`form-control ${
                          errors[schema.authCodeField] && 'form-control-danger height-unset'
                        }`}
                        {...register(schema.authCodeField)}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-info text-white" type="submit">
                          Go!
                        </button>
                      </div>
                      {errors[schema.authCodeField] && (
                        <div className="form-control-feedback">
                          {errors[schema.authCodeField].message}
                        </div>
                      )}
                    </div>
                  </form>
                )}
                {sendSuccess && (
                  <div className="modal-body text-center p-4 pb-5">
                    <i className="icon icon-check fs-70 text-success lh-1 my-4 d-inline-block" />{' '}
                    <h4 className="text-success mb-4">{`Checked-${
                      moduleType === 'CheckOut' ? 'Out' : 'In'
                    }`}</h4>{' '}
                    <p className="mb-4 mx-4">{`Your child is checked-${
                      moduleType === 'CheckOut' ? 'out' : 'in'
                    }!`}</p>
                    <button
                      className="btn btn-success pd-x-25"
                      onClick={() => setSendSuccess(false)}
                    >
                      Done
                    </button>{' '}
                  </div>
                )}
                {sendFailed && (
                  <div className="modal-body text-center p-4 pb-5">
                    <i className="icon icon-close fs-70 text-danger lh-1 my-4 d-inline-block" />{' '}
                    <h4 className="text-danger mb-4">{`Check-${
                      moduleType === 'CheckOut' ? 'Out' : 'In'
                    }`}</h4>{' '}
                    <p className="mb-4 mx-4">{`Your child could not be checked-${
                      moduleType === 'CheckOut' ? 'out' : 'in'
                    }!`}</p>
                    <button className="btn btn-danger pd-x-25" onClick={() => setSendFailed(false)}>
                      Try Again
                    </button>{' '}
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
