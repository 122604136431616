import React from 'react';
import { saveAs } from 'file-saver';
import { RowForm } from './../../components/form/rowForm';
import { Form } from './../../components/form/index';

export const MultiForm = ({
  schema,
  multiFormIndex,
  setMultiFormIndex,
  generatePDF,
  getData,
  activePage,
  dynamicFormAdd,
  dynamicFormAddRowInd,
  closeAddDynamicForm,
  session,
  addDynamicForm,
  appState,
  setFileManageModal,
  setDocumentType,
}) => {
  const downloadDoc = async (document, name) => {
    saveAs(document, `${name}.pdf`);
  };
  let downloadsBtn;
  return (
    <>
      <hr />
      <h3 className="font-light pt-2 mb-2">{schema.multiForm.formTitle}</h3>
      {appState.dependeciesFormAPIS['ApplicationDocType']?.formData.map((form, formIndex) => (
        <div key={formIndex} className="pricing6">
          <div className="">
            <div className="row">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="card card-shadow w-100" style={{ marginBottom: '10px' }}>
                  <div className="card-body row" style={{ padding: '10px' }}>
                    <div className="col-md-3">
                      <h4 className="mr-2">{form.docType}</h4>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="badge badge-sm badge-success p-2"
                        onClick={() => downloadDoc(form.fileURL, form.originalFileName)}
                        style={{ border: 'none' }}
                      >
                        Download Template
                      </button>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="badge badge-sm badge-success p-2"
                        onClick={() => {
                          setFileManageModal(true);
                          setDocumentType(form.docType);
                        }}
                        style={{ border: 'none' }}
                      >
                        Upload Filled Form
                      </button>
                    </div>
                    <div className="col-md-3">
                      {getData.data?.formData[activePage - 1].appdocuments.length > 0 &&
                        getData.data?.formData[activePage - 1].appdocuments.map(
                          (doc, docIndex) =>
                            doc.docType === form.docType && (
                              <button
                                key={docIndex}
                                className="badge badge-sm badge-primary p-2"
                                title={
                                  getData.data?.formData[activePage - 1].appdocuments[0]
                                    .lastUpdatedDateTime
                                }
                                onClick={() => downloadDoc(doc.fileURL, doc.originalFileName)}
                                style={{ border: 'none' }}
                              >
                                View Filled Form
                              </button>
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/* column  */}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
