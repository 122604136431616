import React from 'react';
import jsPDF from 'jspdf';
import { renderToString } from 'react-dom/server';
import { saveAs } from 'file-saver';
import { pdf, Document, Page, StyleSheet } from '@react-pdf/renderer';
import { queryClient } from '../../lib/query/react-query';

import { PDFSchema } from './PDFSchema';
import { Form } from '../../components/form';
import { RowForm } from './../../components/form/rowForm';
import { Sidebar } from '../../components/Layout/Sidebar';
import { useGetCall } from '../../lib/api/get';
import httpParams from '../../lib/query/httpParams';
import { Create } from './Create';
import { Pagination } from '../../components/Layout/Pagination';
import { getDependencyCall } from '../../lib/api/dependencies';
import { useDependencies } from '../../store/dependencies';
import { useAuthStore } from '../../store/auth';
import { ModalFileManager } from '../../components/Layout/ModalFileMaganer';
import { MultiForm } from './MultiForm';
import { BasicRowForm } from './BasicRowForm';
import { ErrorNoRes } from '../../components/Layout/ErrorNoRes';
import { IsLoading } from '../../components/Layout/IsLoading';
import { ErrorStack } from '../../components/Layout/ErrorStack';

const styles = StyleSheet.create({
  page: { backgroundColor: 'tomato' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
});

export const BasicForm = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  isOnline,
  routesData,
  roleActive,
}) => {
  const errState = { error: false };
  const params = httpParams.adminOrgParams();
  const getData = useGetCall(
    schema.api,
    params + `${schema.moduleAddParam ? schema.moduleAddParamString : ''}`
  );
  const [activePage, setActivePage] = React.useState(
    localStorage.getItem(schema.moduleName + 'pageActive')
      ? parseInt(localStorage.getItem(schema.moduleName + 'pageActive'))
      : 1
  );

  const [isAdd, setIsAdd] = React.useState(false);
  const [isChildAdd, setIsChildAdd] = React.useState(false);
  const [multiFormIndex, setMultiFormIndex] = React.useState(null);
  const [dynamicFormAdd, setDynamicFormAdd] = React.useState(false);
  const [fileManageModal, setFileManageModal] = React.useState(false);
  const [dynamicFormAddRowInd, setDynamicFormAddRowInd] = React.useState(null);
  const [allData, setAllData] = React.useState(null);
  const [documentType, setDocumentType] = React.useState(null);

  const addDynamicForm = (rInd) => {
    setDynamicFormAdd(true);
    setDynamicFormAddRowInd(rInd);
  };

  const closeAddDynamicForm = () => {
    setDynamicFormAdd(false);
    setDynamicFormAddRowInd(null);
  };

  const appState = useAuthStore((state) => state);
  const { setDependency } = useDependencies();

  const getDeps = () => {
    const stateVar = { ...appState };
    if (schema.hasDependencies) {
      schema.dependeciesFormAPIS.map(async (api) => {
        stateVar.dependeciesFormAPIS[api] = await getDependencyCall(api, params);
        setDependency(stateVar, stateVar.role, stateVar.roleId);
      });
    }
  };

  const generatePDF = async (pdfName) => {
    const pdf = new jsPDF();
    pdf.save(pdfName);
  };

  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;
    getDeps();
  }, []);

  const handlePage = (page) => {
    localStorage.setItem(schema.moduleName + 'pageActive', page);
    setActivePage(page);
  };

  const handleSearch = (e) => {
    const initialData = queryClient.getQueryData(schema.api);
    let filtered;
    if (allData === null) {
      setAllData(initialData);
      filtered = initialData.formData.filter((f) =>
        f[schema.searchObject].toLowerCase().includes(e.target.value)
      );
    } else {
      filtered = allData.formData.filter((f) =>
        f[schema.searchObject].toLowerCase().includes(e.target.value)
      );
    }
    queryClient.setQueryData(schema.api, { formData: filtered });
  };

  if (getData.isLoading) {
    return <IsLoading />;
  }

  if (getData.data?.formData?.length <= 0 && getData.data.error) {
    return (
      <ErrorNoRes
        setOpenMenu={setOpenMenu}
        openMenu={openMenu}
        schema={schema}
        setIsAdd={setIsAdd}
        navs={navs}
        getData={getData}
      />
    );
  }

  if (getData.data?.formData?.length <= 0) {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
                {schema.form.crud > 2 && (
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ float: 'right' }}
                    onClick={() => setIsAdd(true)}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                )}
              </h3>
              <h3 className="title d-none d-lg-block">
                {schema.title}{' '}
                {schema.form.crud > 2 && (
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ float: 'right' }}
                    onClick={() => setIsAdd(true)}
                  >
                    <i className="fa fa-plus"></i> Add
                  </button>
                )}
              </h3>
            </div>
            <div key="search" className="col-12 mb-3">
              <input
                type="search"
                placeholder={schema.searchTitle}
                onChange={(e) => handleSearch(e)}
                className="form-control"
              />
            </div>
            <Sidebar authData={authData} routesData={routesData} roleActive={roleActive} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>

            {isAdd ? (
              <div className="col-lg-8 b-all border-2 rounded border_Width-1">
                <Create
                  setIsAdd={setIsAdd}
                  isAdd={isAdd}
                  schema={schema.form.create}
                  crud={schema.form.crud}
                  session={session}
                />
              </div>
            ) : (
              <div className="col-lg-8 b-all border-2 rounded border_Width-1">
                <div className="container mb-3">No recors found!</div>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
  let filteredData = [];
  if (schema.filterBy) {
    filteredData = getData.data?.formData.filter(
      (f) => f.elementCSSLibraryName === schema.filterByKey
    );
    if (activePage > filteredData.length) {
      handlePage(1);
    }
  }

  if (activePage > getData.data?.formData?.length) {
    handlePage(1);
  }

  try {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            {!isOnline && (
              <div className="col-md-12 b-all text-center bg-dark border-2 rounded border_Width-1 mb-4">
                <h4 className="title text-light">No Internet Connection!</h4>
              </div>
            )}
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
                {!isAdd && schema.form.crud > 2 && !schema.updateOnly && (
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ float: 'right' }}
                    onClick={() => setIsAdd(true)}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                )}
              </h3>
              <h3 className="title d-none d-lg-block">
                {schema.title}{' '}
                {!isAdd && schema.form.crud > 2 && !schema.updateOnly && (
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ float: 'right' }}
                    onClick={() => setIsAdd(true)}
                  >
                    <i className="fa fa-plus"></i> Add
                  </button>
                )}
              </h3>
            </div>

            {!isAdd && (
              <div key="search" className="col-12 mb-3">
                <input
                  type="search"
                  placeholder={schema.searchTitle}
                  onChange={(e) => handleSearch(e)}
                  className="form-control"
                />
              </div>
            )}
            <Sidebar authData={authData} routesData={routesData} roleActive={roleActive} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                {isAdd ? (
                  <Create
                    setIsAdd={setIsAdd}
                    isAdd={isAdd}
                    schema={schema.form.create}
                    crud={schema.form.crud}
                    session={session}
                  />
                ) : (
                  <Form
                    key={`${activePage}${schema.moduleName}${getData.data.formData}`}
                    schema={schema.form.update}
                    crud={schema.form.crud}
                    defaultData={
                      schema.filterBy
                        ? filteredData[activePage - 1]
                        : getData.data.formData[activePage - 1]
                    }
                    session={session}
                  />
                )}
              </div>
              {schema.isPageEditor && isAdd !== true && (
                <div style={{ textAlign: 'right' }}>
                  <a
                    href={getData.data.formData[activePage - 1].routePath + '?edit=true'}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-info btn-sm mb-3"
                  >
                    Edit Page
                  </a>
                </div>
              )}
              {schema.filterBy
                ? filteredData.length > 1 &&
                  isAdd !== true && (
                    <div className="float-right">
                      <Pagination
                        currentPage={activePage}
                        totalCount={
                          schema.filterBy ? filteredData.length : getData.data.formData.length
                        }
                        siblingCount={1}
                        pageSize={1}
                        onPageChange={handlePage}
                        align="justify-content-end"
                      />
                    </div>
                  )
                : getData.data.formData.length > 1 &&
                  isAdd !== true && (
                    <div className="float-right">
                      <Pagination
                        currentPage={activePage}
                        totalCount={
                          schema.filterBy ? filteredData.length : getData.data.formData.length
                        }
                        siblingCount={1}
                        pageSize={1}
                        onPageChange={handlePage}
                        align="justify-content-end"
                      />
                    </div>
                  )}

              {schema.isRowForm && isAdd !== true && (
                <BasicRowForm
                  key={activePage - 1}
                  schema={schema}
                  setIsChildAdd={setIsChildAdd}
                  isChildAdd={isChildAdd}
                  filteredData={filteredData}
                  activePage={activePage}
                  getData={getData}
                  session={session}
                />
              )}

              {schema.isMultiForm && !isAdd && (
                <MultiForm
                  key={activePage - 1}
                  schema={schema}
                  multiFormIndex={multiFormIndex}
                  setMultiFormIndex={setMultiFormIndex}
                  generatePDF={generatePDF}
                  getData={getData}
                  activePage={activePage}
                  dynamicFormAdd={dynamicFormAdd}
                  dynamicFormAddRowInd={dynamicFormAddRowInd}
                  closeAddDynamicForm={closeAddDynamicForm}
                  session={session}
                  addDynamicForm={addDynamicForm}
                  appState={appState}
                  setFileManageModal={setFileManageModal}
                  setDocumentType={setDocumentType}
                />
              )}
            </div>
          </div>
        </div>
        {fileManageModal && (
          <ModalFileManager
            schema={schema.fileManager}
            setFileManageModal={setFileManageModal}
            defaultData={getData.data.formData[activePage - 1]}
            params={params}
            documentType={documentType}
          />
        )}
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    console.log(errState);
    return <ErrorStack setOpenMenu={setOpenMenu} openMenu={openMenu} schema={schema} navs={navs} />;
  }
};
