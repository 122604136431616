import { Link } from 'react-router-dom';

import android from '../../../assets/images/android.png';
import apple from '../../../assets/images/apple.png';

export const Footer1 = (props) => {
  const errState = { error: false };
  try {
    return (
      <div id="footer1-html spacer">
        <div className="footer1 font-14">
          <div className="f1-topbar">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-12 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-duration={1200}
                ></div>
              </div>
            </div>
          </div>
          <div className="f1-middle">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <Link href="/">
                    <img src={props.pageData.footerLogo.footerLogo} alt="footer1logo" />
                  </Link>
                  <p className="m-t-20">{props.pageData.footerText.footerText}</p>
                </div>
                {/* Column */}
                {/* Column */}
                <div className="col-lg-4 col-md-6">
                  <div className="d-flex no-block m-b-10 m-t-20">
                    <div className="display-7 m-r-20 align-self-top">
                      <i className="icon-Location-2" />
                    </div>
                    <div className="info">
                      <span className="font-medium text-dark db m-t-5">
                        {props.pageData.addressHeader.addressHeader}
                      </span>
                      <br />
                      <p>{props.pageData.address.address}</p>
                    </div>
                  </div>
                  <div className="d-flex no-block m-b-10">
                    <div className="display-7 m-r-20 align-self-top">
                      <i className="icon-Phone-2" />
                    </div>
                    <div className="info">
                      <a
                        href={`tel:${props.pageData.phone.phone}`}
                        className="font-medium text-dark db  m-t-5"
                      >
                        {props.pageData.phone.phone}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex no-block m-b-30">
                    <div className="display-7 m-r-20 align-self-top">
                      <i className="icon-Mail" />
                    </div>
                    <div className="info">
                      <a
                        href={`mailto:${props.pageData.email.email}`}
                        className="font-medium text-dark db  m-t-5"
                      >
                        {props.pageData.email.email}
                      </a>
                    </div>
                  </div>
                </div>
                {/* Column */}
                {/* Column */}
                <div className="col-lg-4 col-md-12 m-t-5">
                  <h6 className="font-medium m-t-20">{props.pageData.linkHeader.linkHeader}</h6>
                  <ul className="general-listing with-arrow m-t-10">
                    {props.pageData.childItems.map(
                      (link) =>
                        link.className.className === 'Footer1Links' &&
                        link.childItems.map((lk, lkIndex) => (
                          <li key={lkIndex}>
                            <Link to={lk.link.link}>
                              <i className="fa fa-angle-right" /> {lk.linkName.linkName}
                            </Link>
                          </li>
                        ))
                    )}
                  </ul>
                </div>
                {/* <div className="col-lg-3 col-md-12 m-t-5">
                  <h6 className="font-medium m-t-20">Download Apps</h6>
                  <div className="display-4 text-white">
                    <a
                      href={props.pageData.androidLink?.androidLink || '#'}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img className="img-responsive" src={android} alt="download android app" />
                    </a>
                  </div>

                  <div className={`display-4 text-white mt-2`}>
                    <a
                      href={props.pageData.appleLink?.appleLink || '#'}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img className="img-responsive" src={apple} alt="download apple app" />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* Row  */}
          <div className="f1-bottom-bar">
            <div className="container">
              <div className="d-block d-md-flex no-block">
                <div className="m-t-10 m-b-10">{props.pageData.copyright.copyright}</div>
                <div className="links ms-auto m-t-10 m-b-10">
                  {props.pageData.childItems.map(
                    (link, linkIndex) =>
                      link.className.className === 'Footer1SocialLinks' && (
                        <a
                          key={linkIndex}
                          href={link.link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link p-10"
                        >
                          <i className={`fa fa-${link.icon.icon}`} />
                        </a>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
