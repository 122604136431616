import React from 'react';
import { Form } from '../../components/form';
import { Sidebar } from '../../components/Layout/Sidebar';

export const Profile = ({ authData, session, schema, navs, setOpenMenu, openMenu, routesData }) => {
  const errState = { error: false };
  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;
  });
  try {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <h2 className="font-light pt-2">My Profile</h2>
              <hr />
              <div className="container mt-5">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-12">
                    <div className="uProfile_card p-3 py-4">
                      <div className="text-center">
                        <img
                          src={authData.authentication.profileImg}
                          width={100}
                          className="rounded-circle"
                          alt="profileImage"
                        />
                      </div>
                      <div className="text-center mt-3">
                        <h5 className="mt-2 mb-0">{authData.authentication.name}</h5>
                        <span>{authData.authentication.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mb-3">
                <hr />
                <Form
                  schema={schema.form.update}
                  crud={2}
                  defaultData={session.user.UserFamily[0]}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
