import { RowForm } from '../../components/form/rowForm';

export const BasicRowForm = ({
  schema,
  setIsChildAdd,
  isChildAdd,
  filteredData,
  activePage,
  getData,
  session,
}) => {
  return (
    <>
      <hr />
      <div className="container mb-3">
        <h3 className="font-light pt-2">{schema.rowForm.formTitle}</h3>
        <button className="btn btn-primary btn-sm" onClick={() => setIsChildAdd(true)}>
          <i className="fa fa-plus"></i>
        </button>
        <hr />
        {isChildAdd && (
          <RowForm
            key="addChildRow"
            schema={schema.rowForm.create}
            isAdd={isChildAdd}
            crud={schema.rowForm.crud}
            setIsAdd={setIsChildAdd}
            parentId={
              schema.filterBy
                ? filteredData[activePage - 1][schema.rowForm.create.parentId]
                : getData.data.formData[activePage - 1][schema.rowForm.create.parentId]
            }
            session={session}
          />
        )}
        {schema.filterBy
          ? filteredData[activePage - 1][schema.rowForm.rowDataArray].map((row, rowIndex) => (
              <>
                <RowForm
                  key={`${activePage}${schema.moduleName}${rowIndex}`}
                  schema={schema.rowForm.update}
                  parentDetails={{
                    parentIdName: schema.rowForm.parentId,
                    parentId: schema.filterBy
                      ? filteredData[activePage - 1][schema.rowForm.parentId]
                      : getData.data.formData[activePage - 1][schema.rowForm.parentId],
                  }}
                  crud={schema.rowForm.crud}
                  defaultData={row}
                  session={session}
                />
                <hr />
              </>
            ))
          : getData.data.formData[activePage - 1][schema.rowForm.rowDataArray].map(
              (row, rowIndex) => (
                <>
                  <RowForm
                    key={`${activePage}${schema.moduleName}${rowIndex}`}
                    schema={schema.rowForm.update}
                    parentDetails={{
                      parentIdName: schema.rowForm.parentId,
                      parentId: getData.data.formData[activePage - 1][schema.rowForm.parentId],
                    }}
                    crud={schema.rowForm.crud}
                    defaultData={row}
                    session={session}
                  />
                  <hr />
                </>
              )
            )}
      </div>
    </>
  );
};
