import * as config from '../../config';
const authent = localStorage.getItem('authent') && JSON.parse(localStorage.getItem('authent'));
const tzOffset = new Date();
const httpParams = {
  uiParams: () => {
    return `?tenantId=${config.REACT_APP_TENANT_ID}&appId=${
      config.REACT_APP_APP_ID
    }&publishStatus=PREVIEW&timeZone=${tzOffset.getTimezoneOffset()}`;
  },
  adminParams: () => {
    return `?tenantId=${config.REACT_APP_TENANT_ID}&appId=${config.REACT_APP_APP_ID}&roleId=${
      authent?.state && authent.state?.roleId && authent.state?.roleId
    }&timeZone=${tzOffset.getTimezoneOffset()}`;
  },
  adminOrgParams: () => {
    return `?tenantId=${config.REACT_APP_TENANT_ID}&appId=${config.REACT_APP_APP_ID}&orgId=${
      config.REACT_APP_ORG_ID
    }&roleId=${
      authent?.state && authent.state?.roleId && authent.state?.roleId
    }&timeZone=${tzOffset.getTimezoneOffset()}`;
  },
};

export default httpParams;
