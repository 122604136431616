import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import { gapi, loadAuth2 } from 'gapi-script';
import { default as dayjs } from 'dayjs';

import './assets/css/theme.css';

import { Auth } from './components/auth';
import { Error404 } from './components/errors/error404';
import { Website } from './modules/website';
import { useAuthStore } from './store/auth';
//import { encryptData } from './lib/encrypt/dataEncrypt';
import { useGetCall } from './lib/api/get';
import httpParams from './lib/query/httpParams';
import { schema as appSchema } from './schema';
import * as Module from './modules';
import * as Web from './components';
import { PrivateRoute } from './routes/PrivateRoute';
import { UserRole } from './components/Layout/UserRole';
import { loginWithEmailAndPassword } from './lib/auth/authQuery';
import { Notifications } from './components/Notifications';
import { MenuDrawer } from './components/Layout/MenuDrawer';
import { ModuleError } from './components/Layout/ModuleError';
import GoogleTranslate from './lib/lang/langTranslate';
import CookieConsent from './components/Layout/CookieConsent';
import { Landing } from './modules/website/Landing';

function App() {
  //const modulesEnabled = JSON.parse(process.env.REACT_APP_MODULES_ENABLED);
  const params = httpParams.uiParams();
  const getAppData = useGetCall('appData', params);
  //const getAllPages = useGetCall('page', params + '&isPrivate=true');
  //const getAllRoles = useGetCall('userRole', params);
  const authDetails = useAuthStore((state) => state);
  const { setAuthentication, setSession, unsetAuthentication, setRole, setRoleAccess, unsetRole } =
    useAuthStore();
  const [isOnline, setIsOnline] = React.useState(true);
  const [schema, setSchema] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [roleModal, setRoleModal] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [authModal, setAuthModal] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    AOS.init();
    setSchema(appSchema);
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  React.useEffect(() => {
    window.addEventListener('online', () => setIsOnline(navigator.onLine));
    window.addEventListener('offline', () => setIsOnline(navigator.onLine));
    return () => {
      window.removeEventListener('online', () => setIsOnline(navigator.onLine));
      window.addEventListener('offline', () => setIsOnline(navigator.onLine));
    };
  });

  const updateUser = async (currentUser) => {
    const name = currentUser.getBasicProfile().getName();
    const email = currentUser.getBasicProfile().getEmail();
    const profile = currentUser.getBasicProfile();
    const profileImg = currentUser.getBasicProfile().getImageUrl();
    const token = currentUser.getAuthResponse();
    const resp = {
      name: name,
      email: email,
      profileImg: profileImg,
      tokenObject: token,
      profile: profile,
    };

    const tokenObj = {};
    tokenObj['token'] = token.id_token;
    tokenObj['tokenSource'] = 'google';
    tokenObj['expiry'] = token.expires_at;

    if (dayjs(token.expires_at) > dayjs(new Date())) {
      await loginWithEmailAndPassword(tokenObj)
        .then(async (resp) => {
          await setUser(resp);

          const defaultRole = resp.data.formData.user.userRoles.filter(
            (f) => f.roleName === 'AppUser'
          );
          await setAuthentication(
            {
              name: name,
              email: email,
              profileImg: profileImg,
              tokenObject: token,
              profile: profile,
            },
            'google',
            resp.data.formData,
            defaultRole[0].roleName,
            defaultRole[0].roleId
          );

          await setSession(resp.data.formData, defaultRole[0].roleName, defaultRole[0].roleId);
          // if (authDetails.authentication !== null && authDetails.roleId) {
          //   await setSession(resp.data.formData, authDetails.role, authDetails.roleId);
          // } else {
          // }
          setAuthModal(false);
        })
        .catch((errr) => {
          console.log(errr);
        });
    }
  };

  if (getAppData.isLoading) {
    return (
      <>
        <div
          className="spinner-border"
          role="status"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            color: process.env.REACT_APP_THEME_COLOR,
          }}
        >
          <span className="sr-only">Loading...</span>
        </div>
        <span className="sr-only">Loading...</span>
      </>
    );
  }

  const routesData = getAppData.data?.formData[0]
    ? JSON.parse(getAppData.data?.formData[0].routesData)
    : [];
  const applicationSchema = getAppData.data?.formData[0]
    ? JSON.parse(getAppData.data?.formData[0].appElementsData)
    : {};

  const appSettings = applicationSchema.filter((f) => 'appSettings' in f)[0].appSettings;
  const webSettings = applicationSchema.filter((f) => 'webSettings' in f)[0].webSettings;
  const seo = applicationSchema.filter((f) => 'seo' in f)[0].seo;

  let Navig = Web.Web.NavbarDefault;
  let ModuleName;
  const Modules = {
    Profile: Module.Profile,
    BasicForm: Module.BasicForm,
    Receipts: Module.Receipts,
    Messages: Module.Messages,
    QRCodeForm: Module.QRCodeForm,
    Attendance: Module.Attendance,
    AttendanceStaff: Module.AttendanceStaff,
    ChildAttendance: Module.ChildAttendance,
    StaffAttendance: Module.StaffAttendance,
    CheckInOut: Module.CheckInOut,
  };

  const userSignedIn = (googleUser) => {
    updateUser(googleUser);
  };

  const userSignedOut = async () => {
    let _auth2;
    //const auth2 = await loadAuth2(gapi, process.env.REACT_APP_GOOGLE_CLIENT_ID, '');
    // setTimeout(function () {
    //   _auth2.signOut().then(() => {
    //     setUser(null);
    //     unsetAuthentication();
    //   });
    // }, 1000);
    //const curUser = auth2.currentUser.get();
    setTimeout(async function () {
      await window.gapi.load('auth2', function () {
        _auth2 = window.gapi.auth2.init({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          scope: 'email profile',
        });
      });
      _auth2.signOut().then(() => {
        setUser(null);
        unsetAuthentication();
        window.location = '/';
      });
    }, 1000);
  };

  return (
    <div id="main-wrapper">
      <GoogleTranslate
        isLoggedIn={authDetails.isLoggedIn}
        pageData={{
          langOptions: {
            langOptions: appSettings['appSettings-translateLanguages'],
          },
        }}
      />
      {(authModal || authDetails.isLoggedIn) && (
        <Auth
          userSignedIn={userSignedIn}
          userSignedOut={userSignedOut}
          setAuthModal={setAuthModal}
          isLoggedIn={authDetails.isLoggedIn}
          appSettings={appSettings}
          webSettings={webSettings}
        />
      )}
      <Router>
        <Notifications />

        {authDetails.role && (
          <MenuDrawer
            //navs={rolesData[authDetails.role]}
            authDetails={authDetails}
            routesData={routesData}
            setOpenMenu={setOpenMenu}
            openMenu={openMenu}
            user={user}
            unsetRole={unsetRole}
            appSettings={appSettings}
            webSettings={webSettings}
          />
        )}
        <Routes>
          {routesData.length > 0 ? (
            routesData.map((route, routeIndex) => (
              <Route
                key={`${route.name}${routeIndex}`}
                path={route.path}
                exact
                element={
                  <Website
                    key={`${route.name}`}
                    auth={{ userSignedIn, userSignedOut, setAuthModal }}
                    sessionIsActive={true}
                    authData={authDetails}
                    setRoleAccess={setRoleAccess}
                    setRoleModal={setRoleModal}
                    setOpenMenu={setOpenMenu}
                    openMenu={openMenu}
                    routeData={route}
                    isLoggedIn={authDetails.isLoggedIn}
                    isOnline={isOnline}
                    windowWidth={windowWidth}
                    appSettings={appSettings}
                    webSettings={webSettings}
                    seo={seo}
                  />
                }
              />
            ))
          ) : (
            <Route
              path="/"
              exact
              element={
                <Landing
                  authData={authDetails}
                  auth={{ userSignedIn, userSignedOut, setAuthModal }}
                  setRoleAccess={setRoleAccess}
                  setRoleModal={setRoleModal}
                  setOpenMenu={setOpenMenu}
                  openMenu={openMenu}
                  isLoggedIn={authDetails.isLoggedIn}
                  isOnline={isOnline}
                  appSettings={appSettings}
                  webSettings={webSettings}
                />
              }
            />
          )}

          {authDetails.isLoggedIn &&
            routesData.length &&
            routesData.length > 0 &&
            routesData.map(
              (mod, modInd) =>
                mod.isPrivate &&
                JSON.parse(process.env.REACT_APP_MODULES_ENABLED)[mod.name] && (
                  <Route
                    key={`${modInd}`}
                    path={`console/${mod.path}`}
                    element={
                      <PrivateRoute
                        auth={{ userSignedIn, userSignedOut }}
                        sessionIsActive={true}
                        authData={authDetails}
                        setRoleAccess={setRoleAccess}
                        setRoleModal={setRoleModal}
                        setOpenMenu={setOpenMenu}
                        openMenu={openMenu}
                        navComp={Navig}
                        isAuthenticated={authDetails.isLoggedIn}
                        roleActive={authDetails.role}
                        module={mod.name}
                        navigateRoute="/console/my-profile"
                        appSettings={appSettings}
                        webSettings={webSettings}
                        modSchema={mod.apiEndPointSchema}
                        routesData={routesData}
                      >
                        {(ModuleName = Modules[mod.component])}
                        {ModuleName ? (
                          <ModuleName
                            moduleSchema={mod.apiEndPointSchema}
                            roleActive={authDetails.role}
                            module={mod.name}
                            schema={mod.apiEndPointSchema}
                            authData={authDetails}
                            session={authDetails.session}
                            setOpenMenu={setOpenMenu}
                            openMenu={openMenu}
                            isOnline={isOnline}
                            appSettings={appSettings}
                            webSettings={webSettings}
                            routesData={routesData}
                          />
                        ) : (
                          <ModuleError
                            moduleSchema={mod.apiEndPointSchema}
                            roleActive={authDetails.role}
                            module={mod.name}
                            schema={mod.apiEndPointSchema}
                            authData={authDetails}
                            session={authDetails.session}
                            setOpenMenu={setOpenMenu}
                            openMenu={openMenu}
                            isOnline={isOnline}
                            appSettings={appSettings}
                            webSettings={webSettings}
                          />
                        )}
                      </PrivateRoute>
                    }
                  />
                )
            )}
          <Route
            path="*"
            element={
              <Error404
                navComp={Navig}
                setOpenMenu={setOpenMenu}
                openMenu={openMenu}
                auth={{ userSignedIn, userSignedOut }}
                sessionIsActive={true}
                authData={authDetails}
                setRoleAccess={setRoleAccess}
                setRoleModal={setRoleModal}
                appSettings={appSettings}
                webSettings={webSettings}
              />
            }
          />
        </Routes>
      </Router>
      <div id="google_translate_element"></div>
      {roleModal && authDetails.session.user.userRoles.length > 1 && (
        <UserRole
          setRoleModal={setRoleModal}
          roles={authDetails.session.user.userRoles}
          setRole={setRole}
          deCryptRole={authDetails.role}
          deCryptRoleId={authDetails.roleId}
          appSettings={appSettings}
          webSettings={webSettings}
        />
      )}

      {appSettings['appSettings-cookieConsentRequired'] && (
        <div style={{ marginBottom: '2rem' }}>
          <CookieConsent schema={appSettings} windowWidth={windowWidth} />
        </div>
      )}
    </div>
  );
}

export default App;
