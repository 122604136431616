import React from 'react';

import { Form } from '../../components/form';
import { RowForm } from './../../components/form/rowForm';
import { Sidebar } from '../../components/Layout/Sidebar';
import { useGetCall } from '../../lib/api/get';
import httpParams from '../../lib/query/httpParams';
import { Pagination } from '../../components/Layout/Pagination';
import { getDependencyCall } from '../../lib/api/dependencies';
import { useDependencies } from '../../store/dependencies';
import { useAuthStore } from '../../store/auth';
import { axios } from '../../lib/query/axios';
import { default as dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ActivityModal } from './ActivityModal';
import { IsLoading } from '../../components/Layout/IsLoading';
import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';

dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const Attendance = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
}) => {
  const errState = { error: false };
  const params = httpParams.adminOrgParams();
  const getData = useGetCall(
    schema.api,
    params + '&checkInDateTime=' + dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss')
  );
  const [activePage, setActivePage] = React.useState(1);
  const [isAdd, setIsAdd] = React.useState(false);

  const [dates, setDates] = React.useState(null);
  const [classes, setClasses] = React.useState(null);
  const [selectedAppl, setSelectedAppl] = React.useState(null);

  const [attenData, setAttenData] = React.useState({ data: { formData: [] } });

  const [selectedApplData, setSelectedApplData] = React.useState({ data: { formData: [] } });
  const [openActivity, setOpenActivity] = React.useState(false);
  const [activityAttenInd, setActivityAttenInd] = React.useState(null);

  const appState = useAuthStore((state) => state);
  const { setDependency } = useDependencies();

  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;

    const stateVar = { ...appState };

    if (schema.hasDependencies) {
      schema.dependeciesFormAPIS.map(async (api) => {
        if (!stateVar.dependeciesFormAPIS[api]) {
          stateVar.dependeciesFormAPIS[api] = await getDependencyCall(api, params);
          setDependency(stateVar, stateVar.role, stateVar.roleId);
        }
      });
    }
  });

  const handlePage = (page) => {
    setActivePage(page);
  };

  <script src="https://cdn.jsdelivr.net/npm/litepicker/dist/litepicker.js"></script>;

  const clearFilter = async () => {
    setDates(null);
    setClasses(null);
    const attnData = await axios.get(
      '/attendance' +
        params +
        '&applicationId=' +
        selectedAppl +
        '&checkInDateTime=' +
        null +
        '&className=' +
        null
    );
    setSelectedApplData({ data: { ...attnData } });
  };
  const getOnchangeAppl = async (e, typ) => {
    if (typ === 'applicationId') {
      setSelectedAppl(e);
      const attnData = await axios.get(
        '/attendance' +
          params +
          '&applicationId=' +
          e +
          '&checkInDateTime=' +
          dayjs.utc(dates).format('YYYY-MM-DD HH:mm:ss') +
          '&className=' +
          classes
      );
      setSelectedApplData({ data: { ...attnData } });
    } else if (typ === 'date') {
      setDates(e);
      const attnData = await axios.get(
        '/attendance' +
          params +
          '&applicationId=' +
          selectedAppl +
          '&checkInDateTime=' +
          dayjs.utc(e).format('YYYY-MM-DD HH:mm:ss') +
          '&className=' +
          classes
      );
      setSelectedApplData({ data: { ...attnData } });
    } else if (typ === 'range') {
      setDates(e);
      const attnData = await axios.get(
        '/attendance' +
          params +
          '&applicationId=' +
          selectedAppl +
          '&checkInDateTimeFrom=' +
          dayjs.utc(e.start).format('YYYY-MM-DD HH:mm:ss') +
          '&checkInDateTimeTo=' +
          dayjs.utc(e.end).format('YYYY-MM-DD HH:mm:ss') +
          '&className=' +
          classes
      );
      setSelectedApplData({ data: { ...attnData } });
    } else {
      setClasses(e);
      const attnData = await axios.get(
        '/attendance' +
          params +
          (typeof dates === 'object'
            ? `&checkInDateTimeFrom=${dates.start}&checkInDateTimeTo=${
                dates.end
              }&timeStampFrom=${dayjs
                .utc(dates.start)
                .format('YYYY-MM-DD HH:mm:ss')}&timeStampTo=${dayjs(dates.end)}`
            : `&checkInDateTime=${dayjs.utc(dates).format('YYYY-MM-DD HH:mm:ss')}&timeStamp=${dayjs(
                dates
              )}`) +
          '&className=' +
          e
      );
      setSelectedApplData({ data: { ...attnData } });
    }
  };

  const openActivityModal = (data) => {
    setActivityAttenInd(data);
    setOpenActivity(true);
  };

  const closeActivityModal = () => {
    setActivityAttenInd(null);
    setOpenActivity(false);
  };

  if (getData.isLoading) {
    return <IsLoading />;
  }

  if (getData.data?.formData?.length <= 0 && getData.data.error) {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">
                  Error - {getData.data.error.response.status}
                </h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
            {isAdd && (
              // <Create
              //   setIsAdd={setIsAdd}
              //   isAdd={isAdd}
              //   schema={schema.form.create}
              //   crud={schema.form.crud}
              // />
              <div></div>
            )}
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }

  try {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
                {/* {schema.form.crud > 2 && (
                <button
                  className="btn btn-primary btn-sm"
                  style={{ float: 'right' }}
                  onClick={() => setIsAdd(true)}
                >
                  <i className="fa fa-plus"></i>
                </button>
              )} */}
              </h3>
              <h3 className="title d-none d-lg-block">
                {schema.title}{' '}
                {/* {schema.form.crud > 2 && (
                <button
                  className="btn btn-primary btn-sm"
                  style={{ float: 'right' }}
                  onClick={() => setIsAdd(true)}
                >
                  <i className="fa fa-plus"></i> Add
                </button>
              )} */}
              </h3>
            </div>
            <div key="applicationId" className="col-4 mb-3">
              <select
                className="form-select"
                onChange={(e) => {
                  getOnchangeAppl(e.target.value, 'applicationId');
                }}
              >
                <option>Select Application</option>
                {authData.dependeciesFormAPIS[schema.dependeciesFormAPIS]?.formData.length > 0 &&
                  authData.dependeciesFormAPIS[schema.dependeciesFormAPIS]?.formData.map(
                    (ops, opsInd) => (
                      <option key={opsInd} value={ops.applicationId}>
                        {ops.applicationName}
                      </option>
                    )
                  )}
              </select>
            </div>
            <div key="date" className="col-3 mb-3">
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  getOnchangeAppl(e.target.value, 'date');
                }}
                defaultValue={dates}
              />
            </div>

            <div key="date" id="" className="col-3 mb-3 d-block d-md-none">
              <DateRangePicker
                initialSettings={{ startDate: new Date(), endDate: new Date() }}
                onCallback={(start, end, label) =>
                  getOnchangeAppl({ start: start._d, end: end._d }, 'range')
                }
              >
                <button className="btn btn-primary btn-sm">
                  <i className="fa fa-calendar" />
                </button>
              </DateRangePicker>
            </div>
            <div key="date" id="" className="col-3 mb-3 d-none d-md-block">
              <DateRangePicker
                initialSettings={{ startDate: new Date(), endDate: new Date() }}
                onCallback={(start, end, label) =>
                  getOnchangeAppl({ start: start._d, end: end._d }, 'range')
                }
              >
                <button className="btn btn-primary btn-sm">
                  <i className="fa fa-calendar" /> Date Range
                </button>
              </DateRangePicker>
            </div>
            <div className="col-2 mb-3 d-block d-md-none">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => clearFilter()}
              >
                <i className="fa fa-refresh" />
              </button>
            </div>
            <div className="col-2 mb-3 d-none d-md-block text-right">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => clearFilter()}
              >
                Clear
              </button>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                {/* <div className="card">
                <div className="card-body">
                  <h3 className="card-title">This card has width of 75%</h3>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to additional content.
                  </p>
                  <a href="#" className="btn btn-danger">
                    Go somewhere
                  </a>
                </div>
              </div> */}
                <h2 className="font-light pt-2">{schema.title}</h2>
                <hr />
                <div id="header-with-footer-html">
                  {selectedApplData.data?.formData.length > 0 ? (
                    selectedApplData.data?.formData.map((atten, attenInd) => (
                      <div key={attenInd} className="card b-b">
                        {/* <div className="card-body">
                          <h4 className="card-title">{atten.PickupPermission.userChildName}</h4>
                          <p className="card-text">
                            {atten.checkOutConfirmedDateTime !== null
                              ? dayjs(atten.checkOutConfirmedDateTime).format('hh:mm A')
                              : atten.checkOutDateTime !== null
                              ? dayjs(atten.checkOutDateTime).format('hh:mm A')
                              : atten.checkInConfirmedDateTime !== null
                              ? dayjs(atten.checkInConfirmedDateTime).format('hh:mm A')
                              : dayjs(atten.checkInDateTime).format('hh:mm A')}
                          </p>
                          <button
                            type="button"
                            onClick={() => openActivityModal(attenInd)}
                            className="btn btn-primary"
                          >
                            Activity
                          </button>
                        </div> */}
                        <div className="card-body row" style={{ padding: '5px' }}>
                          <div className="col-md-4 col-sm-12">
                            <h4 className="card-title">
                              {atten.PickupPermission?.userChildName}
                              <br />
                              <small style={{ fontSize: '0.7rem' }}>
                                {dayjs(atten.checkInDateTime)
                                  .utcOffset(tzOffset, true)
                                  .format('MM/DD/YYYY')}
                              </small>
                              {/* <br />
                                <small>{dayjs(atten.checkInDateTime).format('MM/DD/YYYY')}</small> */}
                            </h4>
                            {/* <p className="card-text">
                                {atten.checkOutConfirmedDateTime !== null
                                  ? dayjs(atten.checkOutConfirmedDateTime).format('hh:mm A')
                                  : atten.checkOutDateTime !== null
                                  ? dayjs(atten.checkOutDateTime).format('hh:mm A')
                                  : atten.checkInConfirmedDateTime !== null
                                  ? atten.checkInConfirmedDateTime !== null
                                    ? dayjs(atten.checkInConfirmedDateTime).format('hh:mm A')
                                    : ' - '
                                  : dayjs(atten.checkInDateTime).format('hh:mm A')}
                              </p> */}
                          </div>
                          <div className="col-md-8 col-sm-12">
                            {dayjs(atten.checkInDateTime)
                              .utcOffset(tzOffset, true)
                              .format('hh:mm A')}{' '}
                            -
                            {atten.checkOutDateTime !== null
                              ? dayjs(atten.checkOutDateTime)
                                  .utcOffset(tzOffset, true)
                                  .format('hh:mm A')
                              : ' NA '}{' '}
                            <button
                              type="button"
                              onClick={() => openActivityModal(attenInd)}
                              className="btn btn-sm btn-secondary ms-auto float-end mr-2"
                            >
                              Activity
                            </button>{' '}
                            {/* <div className="row">
                                <div
                                  className="col-md-6 col-sm-12"
                                  title="Check-In Time - Check-In Confirmed Time"
                                >
                                  {dayjs(atten.checkInDateTime).format('hh:mm A')} -
                                  {atten.checkOutDateTime !== null
                                    ? dayjs(atten.checkOutDateTime).format('hh:mm A')
                                    : ' NA '}{' '}
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  {/* -
                                  {atten.checkOutConfirmedDateTime !== null
                                    ? dayjs(atten.checkOutConfirmedDateTime).format('hh:mm A')
                                    : ' NA '}
                                  <button
                                    type="button"
                                    onClick={() => openActivityModal(atten)}
                                    className="btn btn-sm btn-secondary ms-auto float-right"
                                  >
                                    Activity
                                  </button>{' '}
                                  <button
                                    type="button"
                                    onClick={() => openActivityModal(atten)}
                                    className="btn btn-sm btn-secondary ms-auto float-right"
                                  >
                                    Upload
                                  </button>
                                </div>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No records found!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {openActivity && (
          <ActivityModal
            setOpenActivity={closeActivityModal}
            activityAttenInd={activityAttenInd}
            pdfSchema={schema.pdfSchema}
            pdfSchemaSettings={schema.pdfSchemaSettings}
            schema={schema}
            selectedApplData={selectedApplData}
          />
        )}
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
