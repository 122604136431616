import React, { useState } from 'react';
import { gapi, loadAuth2 } from 'gapi-script';
import { default as dayjs } from 'dayjs';

import { loginWithEmailAndPassword } from '../../../lib/auth/authQuery';
import { useAuthStore } from '../../../store/auth';

export const GoogleLogin = (props) => {
  const [user, setUser] = useState(null);
  const { setAuthentication, setSession, unsetAuthentication } = useAuthStore();
  const authDetails = useAuthStore((state) => state);

  async function setAuth2() {
    let _auth2;
    if (authDetails?.authentication !== null && authDetails?.tokenSource === 'google') {
      if (dayjs(authDetails.authentication.tokenObject.expires_at) > dayjs(new Date())) {
        setUser(authDetails.authentication);
      } else {
        await window.gapi.load('auth2', function () {
          _auth2 = window.gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: 'email profile',
          });
        });
        setTimeout(function () {
          if (_auth2.isSignedIn.get()) {
            props.userSignedIn(_auth2.currentUser.get());
          } else {
            attachSignin(document.getElementById('gLoginBtn'), _auth2);
          }
        }, 1000);
      }
    } else {
      await window.gapi.load('auth2', function () {
        _auth2 = window.gapi.auth2.init({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          scope: 'email profile',
        });
      });

      setTimeout(function () {
        if (_auth2.isSignedIn.get()) {
          props.userSignedIn(_auth2.currentUser.get());
        } else {
          attachSignin(document.getElementById('gLoginBtn'), _auth2);
        }
      }, 1000);
    }
  }

  React.useEffect(() => {
    setAuth2();
  }, []);

  if (authDetails?.authentication !== null && authDetails?.tokenSource === 'google') {
    const diff = dayjs(authDetails?.authentication.tokenObject.expires_at) - dayjs(new Date());

    setTimeout(() => setAuth2(), diff);
  }

  const updateUser = async (currentUser) => {
    const name = currentUser.getBasicProfile().getName();
    const email = currentUser.getBasicProfile().getEmail();
    const profile = currentUser.getBasicProfile();
    const profileImg = currentUser.getBasicProfile().getImageUrl();
    const token = currentUser.getAuthResponse();
    const resp = {
      name: name,
      email: email,
      profileImg: profileImg,
      tokenObject: token,
      profile: profile,
    };

    const tokenObj = {};
    tokenObj['token'] = token.id_token;
    tokenObj['tokenSource'] = 'google';
    tokenObj['expiry'] = token.expires_at;
    if (
      (authDetails?.authentication?.tokenObject &&
        dayjs(authDetails.authentication.tokenObject.expires_at) > dayjs(new Date())) ||
      token.expires_at > dayjs(new Date())
    ) {
      await loginWithEmailAndPassword(tokenObj)
        .then(async (resp) => {
          const defaultRole = resp.data.formData.user.userRoles.filter(
            (f) => f.roleName === 'AppUser'
          );
          setUser({
            name: name,
            email: email,
            profileImg: profileImg,
            tokenObject: token,
            profile: profile,
          });
          await setAuthentication(
            {
              name: name,
              email: email,
              profileImg: profileImg,
              tokenObject: token,
              profile: profile,
            },
            'google',
            resp.data.formData,
            defaultRole[0].roleName,
            defaultRole[0].roleId
          );
          if (authDetails.authentication !== null && authDetails.roleId) {
            await setSession(resp.data.formData, authDetails.role, authDetails.roleId);
          } else {
            await setSession(resp.data.formData, defaultRole[0].roleName, defaultRole[0].roleId);
          }
        })
        .catch((errr) => {
          unsetAuthentication();
          console.log(errr);
        });
    }
  };

  const attachSignin = (element, auth2) => {
    auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        updateUser(googleUser);
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  };

  const signOut = async () => {
    let _auth2;
    await window.gapi.load('auth2', function () {
      _auth2 = window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'email profile',
      });
    });
    // const auth2 = await loadAuth2(gapi, process.env.REACT_APP_GOOGLE_CLIENT_ID, '');
    // const curUser = auth2.currentUser.get();
    setTimeout(function () {
      _auth2.signOut().then(() => {
        setUser(null);
        unsetAuthentication();
      });
    }, 1000);
  };

  if (user) {
    return (
      <div className="container">
        {/* <UserCard user={user} />
        <div id="" className="btn logout" onClick={() => signOut()}>
          Logout
        </div> */}
      </div>
    );
  }

  if (props.isLoggedIn) {
    return (
      <span style={{ color: '#FFFFFF', fontSize: '1px' }} id="gLoginBtn">
        Login With Google
      </span>
    );
  } else {
    return (
      <button className="btn btn-danger mt-2" id="gLoginBtn">
        Login With Google
      </button>
    );
  }
};
