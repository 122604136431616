import { useEffect } from 'react';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

export default function CookieConsent({ schema, windowWidth }) {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run({
        //config
        revision: 1,
        // ...,
        ...schema['appSettings-cookieConsent'],
        //config
      });
    }
  }, []);

  const styles = `--cc-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_bg};
  --cc-text: ${schema['appSettings-cookieConsentColorScheme'].cc_text};
  --cc-btn-primary-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_btn_primary_bg};
  --cc-btn-primary-hover-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_btn_primary_hover_bg};
  --cc-btn-secondary-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_btn_secondary_bg};
  --cc-btn-secondary-hover-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_btn_secondary_hover_bg};
  --cc-toggle-bg-off: ${schema['appSettings-cookieConsentColorScheme'].cc_toggle_bg_off};
  --cc-toggle-bg-readonly: ${schema['appSettings-cookieConsentColorScheme'].cc_toggle_bg_readonly};
  --cc-toggle-knob-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_toggle_knob_bg};
  --cc-toggle-knob-icon-color: ${schema['appSettings-cookieConsentColorScheme'].cc_toggle_knob_icon_color};
  --cc-cookie-category-block-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_cookie_category_block_bg};
  --cc-cookie-category-block-bg-hover: ${schema['appSettings-cookieConsentColorScheme'].cc_cookie_category_block_bg_hover};
  --cc-section-border: ${schema['appSettings-cookieConsentColorScheme'].cc_cookie_border};
  --cc-cookie-table-border: ${schema['appSettings-cookieConsentColorScheme'].cc_cookie_table_border};
  --cc-webkit-scrollbar-bg: ${schema['appSettings-cookieConsentColorScheme'].cc_webkit_scrollbar_bg};
  --cc-webkit-scrollbar-bg-hover: ${schema['appSettings-cookieConsentColorScheme'].cc_webkit_scrollbar_bg_hover};`;

  setTimeout(() => document.getElementById('cc--main').setAttribute('style', styles), 5000);
  setTimeout(() => (document.getElementById('cc--main').style.bottom = '12% !important'), 5000);

  return null;
}
