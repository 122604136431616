export const Login = (props) => {
  return (
    <div className="container-fluid">
      {/* ============================================================== */}
      {/* Static Slider 1  */}
      {/* ============================================================== */}
      <div className="static-slider9 po-relative">
        {/* Row  */}
        <div className="container">
          <div className="row justify-content-center">
            {/* Column */}
            <div className="col-12 info-detail align-self-center text-center">
              <h1 className="title mt-5 mt-lg-0 pt-4 pt-md-5 pt-lg-0">You Need To Login</h1>
              <h6 className="subtitle">You need to login/signup to access the resources.</h6>
              <span
                role="img"
                className="btn btn-primary"
                onClick={() => props.auth.setAuthModal(true)}
              >
                Login
              </span>
              {/* Column */}
            </div>
          </div>
        </div>
      </div>
      {/* ============================================================== */}
      {/* End Static Slider 1  */}
      {/* ============================================================== */}
    </div>
  );
};
