import React from 'react';
import { Sidebar } from '../../components/Layout/Sidebar';
import { useGetCall } from '../../lib/api/get';
import httpParams from '../../lib/query/httpParams';
import { getDependencyCall } from '../../lib/api/dependencies';
import { useDependencies } from '../../store/dependencies';
import { useAuthStore } from '../../store/auth';
import { axios } from '../../lib/query/axios';
import { default as dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IsLoading } from '../../components/Layout/IsLoading';
import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const AttendanceStaff = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
  moduleSchema,
}) => {
  const errState = { error: false };
  const params = httpParams.adminOrgParams();
  // const getData = useGetCall(
  //   moduleSchema.api,
  //   params + '&checkInDateTime=' + dayjs(new Date()).format('MM-DD-YYYY')
  // );

  const [activePage, setActivePage] = React.useState(1);
  const [isAdd, setIsAdd] = React.useState(false);

  const [dates, setDates] = React.useState(null);
  const [classes, setClasses] = React.useState(null);
  const [selectedAppl, setSelectedAppl] = React.useState(null);

  const [attenData, setAttenData] = React.useState({ data: { formData: [] } });

  const [selectedApplData, setSelectedApplData] = React.useState({ data: { formData: [] } });

  const appState = useAuthStore((state) => state);
  const { setDependency } = useDependencies();

  const getData = async () => {
    const attnData = await axios.get(
      moduleSchema.api +
        params +
        '&checkInDateTime=' +
        dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss') +
        '&timeStamp=' +
        dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss')
    );
    setSelectedApplData({ data: { ...attnData } });
  };
  React.useEffect(() => getData(), []);

  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = moduleSchema.pageTitle;
    const stateVar = { ...appState };

    if (moduleSchema.hasDependencies) {
      moduleSchema.dependeciesFormAPIS.map(async (api) => {
        if (!stateVar.dependeciesFormAPIS[api]) {
          stateVar.dependeciesFormAPIS[api] = await getDependencyCall(api, params);
          setDependency(stateVar, stateVar.role, stateVar.roleId);
        }
      });
    }
  });

  const handlePage = (page) => {
    setActivePage(page);
  };

  const clearFilter = async () => {
    setDates(null);
    setClasses(null);
    const attnData = await axios.get(
      '/attendance' +
        params +
        '&applicationId=' +
        selectedAppl +
        '&checkInDateTime=' +
        null +
        '&className=' +
        null
    );
    setSelectedApplData({ data: { ...attnData } });
  };
  const getOnchangeAppl = async (e, typ) => {
    if (typ === 'applicationId') {
      setSelectedAppl(e);
      const attnData = await axios.get(moduleSchema.api + params + '&checkInDateTime=' + dates);
      setSelectedApplData({ data: { ...attnData } });
    } else if (typ === 'date') {
      setDates(e);
      const attnData = await axios.get(moduleSchema.api + params + '&checkInDateTime=' + e);
      setSelectedApplData({ data: { ...attnData } });
    } else if (typ === 'range') {
      setDates(e);
      const attnData = await axios.get(
        moduleSchema.api +
          params +
          '&checkInDateTimeFrom=' +
          dayjs.utc(e.start).format('YYYY-MM-DD HH:mm:ss') +
          '&checkInDateTimeTo=' +
          dayjs.utc(e.end).format('YYYY-MM-DD HH:mm:ss') +
          '&timeStampFrom=' +
          dayjs.utc(e.start).format('YYYY-MM-DD HH:mm:ss') +
          '&timeStampTo=' +
          dayjs.utc(e.end).format('YYYY-MM-DD HH:mm:ss')
      );
      setSelectedApplData({ data: { ...attnData } });
    } else {
      setClasses(e);
      const attnData = await axios.get(
        moduleSchema.api +
          params +
          '&applicationId=' +
          selectedAppl +
          (typeof dates === 'object'
            ? `&checkInDateTimeFrom=${dates.start}&checkInDateTimeTo=${
                dates.end
              }&timeStampFrom=${dayjs
                .utc(dates.start)
                .format('YYYY-MM-DD HH:mm:ss')}&timeStampTo=${dayjs
                .utc(dates.end)
                .format('YYYY-MM-DD HH:mm:ss')}`
            : `&checkInDateTime=${dayjs(dates).format('YYYY-MM-DD HH:mm:ss')}&timeStamp=${dayjs
                .utc(dates)
                .format('YYYY-MM-DD HH:mm:ss')}`) +
          '&className=' +
          e
      );
      setSelectedApplData({ data: { ...attnData } });
    }
  };

  const calculateHours = (outT, inT) => {
    const outTime = dayjs(outT);
    const inTime = dayjs(inT);
    const diff = dayjs.duration(outTime.diff(inTime));
    const diffFormatted = diff.format('HH:mm');

    return diffFormatted;
  };

  if (getData.isLoading) {
    return <IsLoading />;
  }

  if (getData.data?.formData?.length <= 0 && getData.data.error) {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {moduleSchema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{moduleSchema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">
                  Error - {getData.data.error.response.status}
                </h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
            {isAdd && (
              // <Create
              //   setIsAdd={setIsAdd}
              //   isAdd={isAdd}
              //   schema={moduleSchema.form.create}
              //   crud={moduleSchema.form.crud}
              // />
              <div></div>
            )}
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
  try {
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {moduleSchema.title}
                {/* {moduleSchema.form.crud > 2 && (
                <button
                  className="btn btn-primary btn-sm"
                  style={{ float: 'right' }}
                  onClick={() => setIsAdd(true)}
                >
                  <i className="fa fa-plus"></i>
                </button>
              )} */}
              </h3>
              <h3 className="title d-none d-lg-block">
                {moduleSchema.title}{' '}
                {/* {moduleSchema.form.crud > 2 && (
                <button
                  className="btn btn-primary btn-sm"
                  style={{ float: 'right' }}
                  onClick={() => setIsAdd(true)}
                >
                  <i className="fa fa-plus"></i> Add
                </button>
              )} */}
              </h3>
            </div>
            <div key="date" className="col-6 mb-3">
              <input
                type="date"
                className="form-control"
                onChange={(e) => {
                  getOnchangeAppl(e.target.value, 'date');
                }}
                defaultValue={dates}
              />
            </div>
            <div key="date" id="" className="col-6">
              <DateRangePicker
                initialSettings={{ startDate: new Date(), endDate: new Date() }}
                onCallback={(start, end, label) =>
                  getOnchangeAppl({ start: start._d, end: end._d }, 'range')
                }
              >
                <button className="btn btn-primary btn-sm">
                  <i className="fa fa-calendar" /> Date Range
                </button>
              </DateRangePicker>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2">{moduleSchema.title}</h2>
                <hr />
                <div id="header-with-footer-html">
                  {selectedApplData.data?.formData.length > 0 ? (
                    selectedApplData.data?.formData.map((atten, attenInd) => (
                      <div key={attenInd} className="card b-all">
                        <div className="card-header bg-info text-white">
                          {dayjs(atten.checkInDateTime)
                            .utcOffset(tzOffset, true)
                            .format('MM/DD/YYYY')}
                        </div>
                        <div className="card-body row">
                          <div className="col-md-4 col-sm-12">
                            <h4 className="card-title">{atten.AppUser?.userFullName}</h4>
                            <p>{calculateHours(atten.checkOutDateTime, atten.checkInDateTime)}</p>
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <div className="row">
                              <div
                                className="col-md-6 col-sm-12"
                                title="Check-In Time - Check-In Confirmed Time"
                              >
                                {dayjs(atten.checkInDateTime)
                                  .utcOffset(tzOffset, true)
                                  .format('hh:mm A')}{' '}
                                -
                                {atten.checkInConfirmedDateTime !== null
                                  ? dayjs(atten.checkInConfirmedDateTime)
                                      .utcOffset(tzOffset, true)
                                      .format('hh:mm A')
                                  : ' NA '}
                              </div>
                              <div className="col-md-6 col-sm-12">
                                {atten.checkOutDateTime !== null
                                  ? dayjs(atten.checkOutDateTime)
                                      .utcOffset(tzOffset, true)
                                      .format('hh:mm A')
                                  : ' NA '}{' '}
                                -
                                {atten.checkOutConfirmedDateTime !== null
                                  ? dayjs(atten.checkOutConfirmedDateTime)
                                      .utcOffset(tzOffset, true)
                                      .format('hh:mm A')
                                  : ' NA '}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No records found!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {moduleSchema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{moduleSchema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
