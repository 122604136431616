import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Y from 'yup';

import { useGetCall } from '../../lib/api/get';
import { useUpdateCall } from '../../lib/api/update';
import { useCreateCall } from '../../lib/api/create';
import { useDeleteCall } from '../../lib/api/delete';
import httpParams from '../../lib/query/httpParams';
import { schema as appSchema } from '../../schema';
import { PBForm } from './PBForm';
import { ImageManager } from './ImageManager';

export const RightDrawer = (props) => {
  const params = httpParams.adminOrgParams();
  const updateCallMutation = useUpdateCall();
  const createCallMutation = useCreateCall();
  const deleteCallMutation = useDeleteCall();
  const [dataUpdate, setDataUpdate] = React.useState(false);
  const [accordPull, setAccordPull] = React.useState(null);
  const [updateValue, setUpdateValue] = React.useState(0);
  const [imageManage, setImageManage] = React.useState(false);

  const elementData = useGetCall('ElementType', params);

  const validation = Y.object().shape({
    elementTypeId: Y.string().required('Select element to add'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validation),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validation),
  });

  if (elementData.isLoading) {
    return <div>Loading...</div>;
  }

  const elementTypes = elementData.data?.formData && elementData.data?.formData,
    elementTypeSchema = appSchema.elementSchema.filter(
      (f) => f.elementName === props.compData.className.className
    );

  const childComp = elementTypes.filter((el) => {
    return elementTypeSchema[0]?.childItems.some((f) => {
      return f.elementName === el.elementTypeName && f.parent === null;
    });
  });

  const getChildComp = (paren) => {
    const elementSchema = appSchema.elementSchema.filter((f) => f.elementName === paren);
    return elementTypes.filter((el) => {
      return elementSchema[0].childItems.some((f) => {
        return (
          f.elementName === el.elementTypeName &&
          el.elementCSSLibraryName === 'UINavigation' &&
          f.parent === null
        );
      });
    });
  };

  const deleteComp = async (key) => {
    await deleteCallMutation.mutateAsync({
      apiEndpoint: 'PageElement',
      id: key.pageElementId,
      messageTitle: 'Page Element',
      parentAPI: 'pageData',
      message: 'Page element deleted successfully!',
      params,
    });
  };

  const getChildCompChild = (paren) => {
    return elementTypes.filter((el) => {
      return elementTypeSchema[0]?.childItems.some((f) => {
        return f.elementName === el.elementTypeName && f.parent === paren;
      });
    });
  };

  const formSubmit = async (data) => {
    const formObject = {};
    formObject['elementTypeId'] = JSON.parse(data.elementTypeId).elementTypeId;
    formObject['parentPageElementId'] = props.compData.pageElementId;
    formObject['pageId'] = props.pageId;
    formObject['pageElementName'] = JSON.parse(data.elementTypeId).elementTypeName;
    formObject['appId'] = process.env.REACT_APP_APP_ID;
    formObject['tenantId'] = process.env.REACT_APP_TENANT_ID;
    formObject['pageElementDisplayOrder'] = 0;

    await createCallMutation.mutateAsync({
      apiEndpoint: 'PageElement',
      data: formObject,
      parentAPI: 'pageData',
      messageTitle: 'Page Element',
      message: 'Page element added',
      params: params + 'pageName=' + props.pageName,
      refetch: true,
    });
  };

  const formSubmitChild = async (data) => {
    const d1 = data.elementTypeId.split('&&');

    const parentId = JSON.parse(d1[0]);
    const childId = JSON.parse(d1[1]);
    const formObject = {};
    formObject['elementTypeId'] = childId.elementTypeId;
    formObject['parentPageElementId'] = parentId.pageElementId;
    formObject['pageId'] = props.pageId;
    formObject['pageElementName'] = childId.elementTypeName;
    formObject['appId'] = process.env.REACT_APP_APP_ID;
    formObject['tenantId'] = process.env.REACT_APP_TENANT_ID;
    formObject['pageElementDisplayOrder'] = 0;

    await createCallMutation.mutateAsync({
      apiEndpoint: 'PageElement',
      data: formObject,
      parentAPI: 'pageData',
      messageTitle: 'Page Element',
      message: 'Page element added',
      params: params + 'pageName=' + props.pageName,
      refetch: true,
    });
  };

  const updatePropertyValue = async (comp, e, key) => {
    setDataUpdate(!dataUpdate);
    e.preventDefault();

    var datas = new FormData(e.target);
    let formsObject = Object.fromEntries(datas.entries());
    const formObject = {};
    formObject['appId'] = process.env.REACT_APP_APP_ID;
    formObject['tenantId'] = process.env.REACT_APP_TENANT_ID;
    formObject['pageId'] = props.pageId;
    formObject['elementTypePropertyId'] = comp[key].elementTypePropertyId;
    formObject['pageElementPropertyId'] = comp[key].pageElementPropertyId;
    formObject['pageElementId'] = comp.pageElementId;
    formObject['pageElementPropertyValue'] = formsObject[key];

    await updateCallMutation
      .mutateAsync({
        apiEndpoint: 'pageElementProperty',
        data: formObject, // data,
        id: comp[key].pageElementPropertyId,
        parentAPI: 'pageData',
        messageTitle: 'Page Data',
        message: 'Page data updated',
        refetch: true,
        params,
      })
      .then(() => setUpdateValue(updateValue + 1));
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      style={{ visibility: 'visible' }}
      aria-modal="true"
      role="dialog"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
          {props.compData.className.className}{' '}
          <button className="btn btn-primary btn-sm" onClick={() => setImageManage(true)}>
            Images
          </button>
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => props.closeRight(false)}
        />
      </div>
      <div className="offcanvas-body cScrollBar" style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12">
            <PBForm comp={props.compData} updatePropertyValue={updatePropertyValue} />
          </div>
          {childComp.length > 0 && (
            <form className="row" onSubmit={handleSubmit(formSubmit)}>
              <div className="col-9">
                <select
                  className={`form-select ${errors['elementTypeId'] && 'form-control-danger'}`}
                  {...register('elementTypeId')}
                >
                  <option>Select...</option>
                  {getChildComp(props.compData.className.className).map((child, childIndex) => (
                    <option key={childIndex} value={`${JSON.stringify(child)}`}>
                      {child.elementTypeName}
                    </option>
                  ))}
                </select>
                {errors['elementTypeId'] && (
                  <div className="form-control-feedback">{errors['elementTypeId'].message}</div>
                )}
              </div>
              <div className="col-3 mb-4">
                <button type="submit" className="btn btn-primary btn-sm mt-1">
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </form>
          )}
          {props.compData.childItems &&
            props.compData.childItems.length > 0 &&
            props.compData.childItems
              .sort((a, b) => a.className.className.localeCompare(b.className.className))
              .map((child, childIndex) => (
                <div key={childIndex} className="col-12">
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className={`accordion-button ${
                            accordPull !== null && accordPull === childIndex ? '' : 'collapsed'
                          }`}
                          type="button"
                          onClick={() =>
                            setAccordPull(accordPull === childIndex ? null : childIndex)
                          }
                        >
                          {child.className.className}
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className={`accordion-collapse collapse ${
                          accordPull !== null && accordPull === childIndex ? 'show' : ''
                        }`}
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <button
                            className="btn btn-danger btn-sm float-right"
                            onClick={() => deleteComp(child)}
                          >
                            Delete
                          </button>
                          <PBForm comp={child} updatePropertyValue={updatePropertyValue} />
                          {getChildCompChild(child.className.className).length > 0 && (
                            <>
                              <form className="row" onSubmit={handleSubmit2(formSubmitChild)}>
                                <div className="col-9">
                                  <select
                                    className={`form-select ${
                                      errors['elementTypeId'] && 'form-control-danger'
                                    }`}
                                    {...register2('elementTypeId')}
                                  >
                                    {getChildCompChild(child.className.className).map(
                                      (childs, childsIndex) => (
                                        <option
                                          key={childsIndex}
                                          value={`${JSON.stringify(child)}&&${JSON.stringify(
                                            childs
                                          )}`}
                                        >
                                          {childs.elementTypeName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {errors['elementTypeId'] && (
                                    <div className="form-control-feedback">
                                      {errors['elementTypeId'].message}
                                    </div>
                                  )}
                                </div>
                                <div className="col-3 mb-4">
                                  <button type="submit" className="btn btn-primary btn-sm mt-1">
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </div>
                              </form>
                            </>
                          )}

                          {child.childItems.length > 0 &&
                            child.childItems.map((cChild, cChildIndex) => (
                              <React.Fragment key={`${childIndex}${cChildIndex}`}>
                                <button
                                  className="btn btn-danger btn-sm float-right"
                                  onClick={() => deleteComp(cChild)}
                                >
                                  Delete
                                </button>
                                <PBForm comp={cChild} updatePropertyValue={updatePropertyValue} />
                                <hr />
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      {imageManage && <ImageManager setImageManage={setImageManage} />}
    </div>
  );
};
