import { Sidebar } from './Sidebar';

export const ModuleError = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
}) => {
  return (
    <>
      <div className="container" style={{ marginTop: '6rem' }}>
        <div className="row">
          <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
            <h3 className="title d-block d-lg-none">
              <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
              Module Error
            </h3>
            <h3 className="title d-none d-lg-block">Module Error </h3>
          </div>
          <Sidebar authData={authData} routesData={routesData} />
          <div
            className="col"
            style={{
              width: '50% !important',
              maxWidth: '50% !important',
              paddingRight: '0px !important',
            }}
          ></div>
          <div className="col-lg-8 b-all border-2 rounded border_Width-1">
            <div className="container mb-3">
              <h2 className="font-light pt-2">Something went wrong!</h2>
              <hr />
              <p>Please contact your system administrator</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '6rem' }}></div>
    </>
  );
};
