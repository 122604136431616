import roles from '../../assets/images/roles.png';
export const UserRole = (props) => {
  return (
    <div
      id="mymodal6"
      className="modal fade custom-modal modal1 show"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: 'block',
        backgroundColor: 'rgba(55,55,55,0.3)',
      }}
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-lg">
        <div className="modal-content po-relative">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <div className="modal-bg">
                  <h2 className="font-light m-b-0 mb-2">Select Role</h2>

                  {props.roles.length > 0 &&
                    props.roles.map((role, roleIndex) => (
                      <button
                        type="button"
                        key={roleIndex}
                        className={`btn ${
                          props.deCryptRole === role.roleName
                            ? 'btn-primary'
                            : 'btn-outline-secondary'
                        } mr-2 mb-2`}
                        onClick={() => {
                          props.setRole(role.roleName, role.roleId);
                          props.setRoleModal(false);
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Tooltip on right"
                        data-original-title="Tooltip on right"
                      >
                        {props.deCryptRole === role.roleName && <i className="fa fa-check"></i>}{' '}
                        {role.roleName}
                      </button>
                    ))}
                </div>
              </div>
              <div
                className="col-lg-6 col-md-4 modal-bg-img"
                style={{
                  backgroundImage: `url(${roles})`,
                }}
              ></div>
            </div>
            <a
              href="#!"
              className="close-btn d-flex align-items-center justify-content-center"
              data-bs-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.setRoleModal(false)}
            >
              ×
            </a>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};
