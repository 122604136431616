import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useAuthStore = create(
  persist(
    (set, get) => ({
      setAuthentication: (authDetails, tokenSource, session, role, roleId) => {
        set((state) => ({
          ...state,
          isLoggedIn: true,
          authentication: authDetails,
          tokenSource: tokenSource,
          dependeciesFormAPIS: {},
          session: session,
          role: role,
          roleId: roleId,
        }));
      },
      setSession: (session, role, roleId) => {
        set((state) => ({
          session: session,
          role: role,
          roleId: roleId,
        }));
      },
      unsetAuthentication: () => {
        set((state) => ({
          isLoggedIn: false,
          authentication: null,
          session: null,
          tokenSource: null,
          dependeciesFormAPIS: {},
          role: null,
          roleId: null,
        }));
      },
      setRole: (role, roleId) => {
        set((state) => ({
          ...state,
          role: role,
          roleId: roleId,
        }));
      },
      setRoleAccess: (session) => {
        set((state) => ({
          session: session,
        }));
      },
      unsetRole: () => {
        set((state) => ({
          role: null,
          roleId: null,
        }));
      },
    }),
    {
      name: 'authent',
      getStorage: () => localStorage,
    }
  )
);

export const useUnsetAuthStore = create(
  persist(
    (set, get) => ({
      unsetAuthentication: () => {
        set((state) => ({
          authentication: null,
          session: null,
        }));
      },
    }),
    {
      name: 'authent',
      getStorage: () => localStorage,
    }
  )
);
