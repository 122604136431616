import { Form } from '../../form';

export const Contact1 = (props) => {
  const errState = { error: false };
  try {
    return (
      <div className="contact1 spacer">
        <div className="col-md-12">
          <iframe
            title="contactMap"
            src={props.pageData.mapURL.mapURL}
            width="100%"
            height={450}
            frameBorder={0}
            style={{ border: '0' }}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="spacer">
                <div className="row m-0">
                  <div className="col-lg-7">
                    <div className="contact-box p-r-40">
                      {props.isLoggedIn !== true && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            zIndex: '1',
                            margin: '-25px 0 0 -25px',
                          }}
                        >
                          <span
                            role="img"
                            className="btn btn-primary"
                            onClick={() => props.auth.setAuthModal(true)}
                          >
                            Login
                          </span>
                        </div>
                      )}

                      <div
                        style={{
                          zIndex: '2',
                          filter: props.isLoggedIn ? '' : 'blur(2px)',
                          WebkitFilter: props.isLoggedIn === true ? '' : 'blur(2px)',
                          pointerEvents: props.isLoggedIn === true ? '' : 'none',
                        }}
                      >
                        <Form
                          schema={props.webSettings['webSettings-contactForm']}
                          isAdd={true}
                          crud={4}
                          setIsAdd={props.setIsAdd}
                          session={props.authData.session}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div
                      className="detail-box p-40 bg-info aos-init"
                      data-aos="fade-right"
                      data-aos-duration={1200}
                    >
                      <h2 className="text-white">{props.pageData.addressHeader.addressHeader}</h2>
                      <p className="text-white m-t-30 op-8">
                        {props.pageData.phone.phone}
                        <br /> {props.pageData.email.email}
                      </p>
                      <p className="text-white op-8">{props.pageData.address.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
