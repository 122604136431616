import { Link } from 'react-router-dom';

export const Error404 = (props) => {
  return (
    <>
      <props.navComp
        key="navbar"
        auth={props.auth}
        sessionIsActive={props.sessionIsActive}
        authData={props.authData}
        setRoleAccess={props.setRoleAccess}
        setRoleModal={props.setRoleModal}
        setOpenMenu={props.setOpenMenu}
        openMenu={props.openMenu}
      />
      <div className="container" style={{ marginTop: '6rem' }}>
        <div className="row">
          <div className="col-12 b-all border-2 rounded border_Width-1">
            <div className="container mb-3">
              <h2 className="font-light pt-2 text-danger">Error - 404</h2>
              <hr />
              <div className="text-danger">Page Not Found!</div>
              <Link className="btn btn-secondary mt-5" to="/">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '6rem' }}></div>
    </>
  );
};
