export const Features1 = (props) => {
  const errState = { error: false };
  try {
    return (
      <div className="bg-light spacer feature1">
        <div className="container">
          {/* Row  */}
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 text-center">
              <h2 className="title">{props.pageData.header.header}</h2>
              <h6 className="subtitle">{props.pageData.text.text}</h6>
            </div>
          </div>
          <div id="feature1-html">
            {/* Row  */}
            <div className="row m-t-40 justify-content-center">
              {/* Column */}
              {props.pageData.childItems.map((card, cardIndex) => (
                <div key={cardIndex} className="col-lg-4 col-md-6 wrap-feature1-box">
                  <div
                    className="card card-shadow aos-init"
                    data-aos={`fade-${
                      cardIndex === 0
                        ? 'right'
                        : cardIndex === props.pageData.childItems.length - 1
                        ? 'left'
                        : 'up'
                    }`}
                    data-aos-duration={1200}
                  >
                    <div className="card-body text-center">
                      <div className="icon-space">
                        <i className={`fa fa-${card.icon.icon} fa-3x`}></i>
                      </div>
                      <h5 className="font-medium">{card.header.header}</h5>
                      <p className="m-t-20">{card.text.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
