import React from 'react';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from '../../lib/utils/OnClickOutside';

export const MenuDrawer = (props) => {
  const rs = [];
  props.routesData.map((r) => {
    if (r.isPrivate && r.component !== '') {
      rs.push({ ...r });
    }
  });

  let menus = [];
  props.authDetails.session.pages.forEach((p) =>
    p.roles.filter((f) => {
      if (f.roleName === props.authDetails.role) {
        menus.push(p);
      }
    })
  );

  const ms = menus.map((m) => {
    const t = props.routesData.filter((r) => r.isPrivate && r.name === m.pageName);
    return t;
  });

  const openMenu = React.useRef();

  useOnClickOutside(openMenu, () => props.setOpenMenu(false));
  return (
    <div
      className={`offcanvas offcanvas-start ${props.openMenu === true ? 'show' : ''}`}
      id="example1"
      aria-labelledby="offcanvasExampleLabel"
      aria-modal="true"
      role="dialog"
      style={{ visibility: 'visible', marginTop: '4.8rem' }}
      ref={openMenu}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
          Menus
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => props.setOpenMenu(false)}
        />
      </div>
      <div className="offcanvas-body">
        <ul className="list-icons">
          {ms.map((nav, navI) => (
            <li key={navI}>
              <Link
                className="fw-normal"
                to={`console/${nav[0].path}`}
                onClick={() => props.setOpenMenu(false)}
              >
                {nav[0].pageTitle}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
