import * as React from 'react';
export const Notification = ({ notification: { id, type, title, message } }) => {
  const [openMessage, setOpenMessage] = React.useState({ 1: true });
  React.useEffect(() => {
    let timer1 = setTimeout(() => setOpenMessage(false), 5 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div
      className="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{
        display: openMessage ? 'block' : 'none',
        background: type === 'success' ? '#2cdd9b' : '#ff4d7e',
        color: '#FFF',
      }}
      key={id}
    >
      <div
        className="toast-header"
        style={{ background: type === 'success' ? '#2cdd9b' : '#ff4d7e', color: '#FFF' }}
      >
        <strong className="me-auto">{title}</strong>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          onClick={() => setOpenMessage(false)}
        />
      </div>
      <div className="toast-body">{message}</div>
    </div>
  );
};
