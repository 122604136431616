import React from 'react';

import { GoogleLogin } from './google/GoogleLogin';
import login from '../../assets/images/login.png';
import { Link } from 'react-router-dom';

export const Auth = (props) => {
  if (props.isLoggedIn) {
    return (
      <div className="row">
        {parseInt(process.env.REACT_APP_GOOGLE_LOGIN) === 1 && (
          <GoogleLogin
            isLoggedIn={props.isLoggedIn}
            userSignedIn={props.userSignedIn}
            userSignedOut={props.userSignedOut}
          />
        )}
        {parseInt(process.env.REACT_APP_FACEBOOK_LOGIN) === 1 && <div>Facebook</div>}
      </div>
    );
  } else {
    return (
      <div
        id="mymodal6"
        className="modal fade custom-modal modal1 show"
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        style={{
          display: 'block',
          backgroundColor: 'rgba(55,55,55,0.3)',
        }}
      >
        <div className="modal-dialog modal-fullscreen-sm-down modal-lg">
          <div className="modal-content po-relative">
            <div className="modal-body p-0">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <div className="modal-bg">
                    <h2 className="font-light m-b-0 mb-2">Login</h2>
                    {parseInt(process.env.REACT_APP_GOOGLE_LOGIN) === 1 && (
                      <GoogleLogin
                        userSignedIn={props.userSignedIn}
                        userSignedOut={props.userSignedOut}
                      />
                    )}
                    <div className="text-left m-t-40">
                      By logging in, you are agreeing to the{' '}
                      <a href="policies/terms-of-service" rel="noopener noreferrer">
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a href="policies/privacy-policy" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                      .
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-4 modal-bg-img"
                  style={{
                    backgroundImage: `url(${login})`,
                  }}
                ></div>
              </div>
              <a
                href="#!"
                className="close-btn d-flex align-items-center justify-content-center"
                data-bs-dismiss="modal"
                aria-hidden="true"
                onClick={() => props.setAuthModal(false)}
              >
                ×
              </a>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  }
};
