export const IsLoading = () => {
  return (
    <div
      id="mymodal6"
      className="modal fade custom-modal modal1 show"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: 'block',
        backgroundColor: 'rgba(55,55,55,0.3)',
      }}
    >
      <div
        className="modal-dialog modal-sm"
        style={{
          textAlign: 'center',
          width: 'auto !important',
        }}
      >
        <div
          className="modal-content po-relative"
          style={{ display: 'inline-block', border: 'none' }}
        >
          <div className="modal-body p-0">
            <div className="row">
              <div className="col">
                <div className="modal-bg">
                  <div
                    className="spinner-grow mr-2"
                    role="status"
                    style={{ color: process.env.REACT_APP_THEME_COLOR }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div
                    className="spinner-grow mr-2 ml-2"
                    role="status"
                    style={{ color: process.env.REACT_APP_THEME_COLOR }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div
                    className="spinner-grow"
                    role="status"
                    style={{ color: process.env.REACT_APP_THEME_COLOR }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
