import React from 'react';
export const SwitchBox = (props) => {
  const [switchButton, setSwitchButton] = React.useState(
    props.isAdd ? props.field.defaultValue : props.defaultData[props.field.name]
  );
  return props.isAdd ? (
    <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
      <label className="form-control-label">{props.field.placeholder}</label>
      <label className="custSwitch">
        <input
          type="checkbox"
          name={props.field.name}
          defaultChecked={switchButton}
          className="colorinput-input"
          {...props.register(props.field.name)}
          id={props.field.name}
          onChange={() => setSwitchButton(!switchButton)}
        />
        <span
          className="custSlider round"
          style={{
            width: '100% !important',
            height: '100% !important',
            backgroundColor: switchButton !== true && '#CCC',
          }}
        ></span>
      </label>
      {props.errors[props.field.name] && (
        <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
      )}
    </div>
  ) : (
    <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
      <label className="form-control-label">{props.field.placeholder}</label>
      <label className="custSwitch">
        <input
          type="checkbox"
          name={props.field.name}
          defaultChecked={switchButton}
          className="colorinput-input"
          {...props.register(props.field.name)}
          id={props.field.name}
          onChange={() => setSwitchButton(!switchButton)}
        />
        <span
          className="custSlider round"
          style={{
            width: '100% !important',
            height: '100% !important',
            backgroundColor: switchButton !== true && '#CCC',
          }}
        ></span>
      </label>
      {props.errors[props.field.name] && (
        <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
      )}
    </div>
  );
};
