import * as Y from 'yup';
export const schema = {
  elementSchema: [
    {
      elementName: 'TextComponent',
      childItems: [],
    },
    {
      elementName: 'Banner1',
      childItems: [],
    },
    {
      elementName: 'Team2',
      childItems: [
        { elementName: 'Team2Card', parent: null },
        { elementName: 'Team2SocialLinks', parent: 'Team2Card' },
      ],
    },
    {
      elementName: 'Contact1',
      childItems: [],
    },
    {
      elementName: 'Navbar1',
      childItems: [
        { elementName: 'NavbarLinks', parent: null },
        { elementName: 'NavbarLinksItem', parent: 'NavbarLinks' },
      ],
    },
    {
      elementName: 'Features1',
      childItems: [{ elementName: 'Features1Cards', parent: null }],
    },
    {
      elementName: 'Features22',
      childItems: [],
    },
    {
      elementName: 'Footer1',
      childItems: [
        { elementName: 'Footer1SocialLinks', parent: null },
        { elementName: 'Footer1Links', parent: null },
        { elementName: 'Footer1LinkItem', parent: 'Footer1Links' },
      ],
    },
    {
      elementName: 'FileDownloads',
      childItems: [{ elementName: 'FileDownloadFiles', parent: null }],
    },
    {
      elementName: 'VideoImageGallery',
      childItems: [{ elementName: 'VideoImageGalleryItem', parent: null }],
    },
  ],
  elementCategories: [
    {
      components: ['Navbar1'],
      category: 'Navbars',
    },
    {
      components: ['Footer1', 'Footer2', 'Footer3', 'Footer4', 'Footer5', 'Footer6'],
      category: 'Footers',
    },
    {
      components: ['Contact1', 'Contact2', 'Contact3', 'Contact4'],
      category: 'Contacts',
    },
    {
      components: [
        'Banner1',
        'Banner2',
        'Banner3',
        'Banner4',
        'Banner5',
        'Banner6',
        'Banner7',
        'Banner8',
        'Banner9',
        'Banner10',
      ],
      category: 'Banners',
    },
    {
      components: [
        'Features1',
        'Features2',
        'Features3',
        'Features4',
        'Features5',
        'Features6',
        'Features7',
        'Features8',
        'Features9',
        'Features10',
        'Features11',
        'Features12',
        'Features13',
        'Features14',
        'Features15',
        'Features16',
        'Features17',
        'Features18',
        'Features19',
        'Features20',
        'Features21',
        'Features22',
        'Features23',
        'Features24',
        'Features25',
        'Features26',
        'Features27',
        'Features28',
        'Features29',
        'Features30',
        'Features31',
        'Features32',
        'Features33',
        'Features34',
        'Features35',
        'Features36',
        'Features37',
        'Features38',
        'Features39',
        'Features40',
        'Features41',
        'Features42',
        'Features43',
        'Features44',
        'Features45',
        'Features46',
        'Features47',
        'Features48',
        'Features49',
      ],
      category: 'Features',
    },
    {
      components: [
        'CallToAction1',
        'CallToAction2',
        'CallToAction3',
        'CallToAction4',
        'CallToAction5',
        'CallToAction6',
        'CallToAction7',
        'CallToAction8',
        'CallToAction9',
        'CallToAction10',
      ],
      category: 'CallToAction',
    },
    {
      components: ['Counter1'],
      category: 'Counters',
    },
    {
      components: ['Team1', 'Team2', 'Team3', 'Team4'],
      category: 'Teams',
    },
    {
      components: ['FormBanner1', 'FormBanner2'],
      category: 'FormBanners',
    },
    {
      components: [
        'TextComponent',
        'VideoImageGallery',
        'FileDownloads',
        'Row',
        'Column',
        'Text',
        'Image',
        'PageProperties',
      ],
      category: 'Others',
    },
  ],
  moduleSchema: {
    // MyProfile: {
    //   title: 'My Profile',
    //   moduleName: 'Profile',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: My Profile',
    //   form: {
    //     update: {
    //       formTitle: 'Edit Profile',
    //       formAPI: 'userFamily',
    //       id: 'userFamilyId',
    //       message: { title: 'Profile', message: 'Profile updated!' },
    //       buildValidation: [
    //         {
    //           name: 'userFamilyMemberName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Full name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       makebool: true,
    //       makeboolKey: [{ k: 'userFamilyMemberInviteStatus', v: false }],
    //       schema: [
    //         {
    //           name: 'userFamilyId',
    //           element: 'InputField',
    //           placeholder: 'Id',
    //           hidden: true,
    //           size: 4,
    //         },
    //         {
    //           name: 'userFamilyMemberName',
    //           element: 'InputField',
    //           type: 'text',
    //           placeholder: 'Full Name',
    //           hidden: false,
    //           size: 4,
    //         },
    //         {
    //           name: 'userFamilyMemberRelationship',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'MemberRelationship',
    //         },
    //         {
    //           name: 'userFamilyMemberEmail',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Member Email',
    //         },
    //         {
    //           name: 'userFamilyMemberInviteStatus',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Invite Status',
    //         },
    //         {
    //           name: 'userFamilyMemberCellphone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Cell Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberHomephone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Home Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberAltPhone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Alt Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberIsFamily',
    //           element: 'InputField',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Is Family',
    //         },
    //         {
    //           name: 'userFamilyMemberIsEmContact',
    //           element: 'InputField',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Emergency Contact',
    //         },
    //         {
    //           name: 'userFamilyMemberPickupPermissions',
    //           element: 'InputField',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Pickup Permissions',
    //         },
    //         {
    //           name: 'userFamilyMemberStreetAddress',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 8,
    //           placeholder: 'Address',
    //         },
    //         {
    //           name: 'userFamilyMemberCity',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'City',
    //         },
    //         {
    //           name: 'userFamilyMemberState',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'State',
    //         },
    //         {
    //           name: 'userFamilyMemberZipCode',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Zipcode',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       loadingButtonName: 'Updating...',
    //     },
    //   },
    // },
    // Apps: {
    //   title: 'Apps',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Apps',
    //   api: 'app',
    //   moduleName: 'Apps',
    //   form: {
    //     crud: 1,
    //     update: {
    //       formTitle: 'App',
    //       formAPI: 'app',
    //       id: 'appId',
    //       message: { title: 'Apps', message: 'App updated!' },
    //       buildValidation: [
    //         {
    //           name: 'appName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Full name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'tenantName',
    //           element: 'InputField',
    //           valFromObj: true,
    //           obj: ['Tenant', 'tenantName'],
    //           placeholder: 'Tenant Name',
    //           hidden: false,
    //           disabled: true,
    //           size: 6,
    //         },
    //         {
    //           name: 'appId',
    //           element: 'InputField',
    //           placeholder: 'App Id',
    //           hidden: true,
    //           size: 4,
    //         },
    //         {
    //           name: 'tenantId',
    //           element: 'InputField',
    //           placeholder: 'Tenant Id',
    //           hidden: true,
    //           size: 4,
    //         },
    //         {
    //           name: 'appName',
    //           element: 'InputField',
    //           type: 'text',
    //           placeholder: 'App Name',
    //           hidden: false,
    //           size: 6,
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       loadingButtonName: 'Updating...',
    //     },
    //   },
    // },
    // AppProperties: {
    //   title: 'App Properties',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: App Properties',
    //   searchTitle: 'Search App Properties',
    //   searchObject: 'appElementName',
    //   api: 'appElement',
    //   moduleName: 'AppProperties',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'App Properties',
    //       formAPI: 'appElement',
    //       id: 'appElementId',
    //       message: { title: 'App Properties', message: 'App Property updated!' },
    //       deleteMessage: { title: 'App Properties', message: 'App Property deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'appElementName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Element name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       validation: {
    //         elementTypeName: Y.string().required('Element name is required!'),
    //         elementCSSLibraryName: Y.string().required('Library name is required!'),
    //         defaultParentLevel: Y.string().required('Parent level is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'appElementName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 8,
    //           placeholder: 'Element Type Name',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Properties',
    //       formAPI: 'appElement',
    //       message: { title: 'Add Properties', message: 'Add Property created!' },
    //       buildValidation: [
    //         {
    //           name: 'appElementName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Element name is required!'],
    //             },
    //           ],
    //         },
    //       ],

    //       schema: [
    //         {
    //           name: 'appElementName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'APP Element Name',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    //   isRowForm: true,
    //   rowForm: {
    //     crud: 4,
    //     formTitle: 'App Properties',
    //     rowDataArray: 'rowFormData',
    //     parentId: 'elementTypeId',
    //     update: {
    //       formTitle: 'App Properties',
    //       formAPI: 'appElementProperty',
    //       parentAPI: 'appElement',
    //       id: 'appElementPropertyId',
    //       message: { title: 'App Properties', message: 'App Property updated!' },
    //       deleteMessage: { title: 'App Properties', message: 'App Property deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'appElementPropertyValue',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['App property name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'appElementPropertyId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Role Name',
    //         },
    //         {
    //           name: 'appElementPropertyValue',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 8,
    //           placeholder: 'Property Value',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-check',
    //       deleteButtonIcon: 'fa fa-trash',
    //       loadingButtonIcon: '...',
    //     },
    //     create: {
    //       formTitle: 'Add Element Property',
    //       formAPI: 'ElementTypeProperty',
    //       parentAPI: 'ElementType',
    //       message: { title: 'Element Properties', message: 'Propert added!' },
    //       buildValidation: [
    //         {
    //           name: 'appElementPropertyValue',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['App property name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       addParentId: true,
    //       parentId: 'elementTypeId',
    //       schema: [
    //         {
    //           name: 'elementTypePropertyName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Name',
    //         },
    //         {
    //           name: 'elementTypePropertyDataType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Data Type',
    //         },
    //         {
    //           name: 'elementTypePropertyRequired',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Data Type',
    //         },
    //         {
    //           name: 'isAdvanced',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: false,
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Advanced',
    //         },
    //         {
    //           name: 'elementTypePropertyDefaultValue',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Default Value',
    //         },
    //         {
    //           name: 'elementTypePropertyOrder',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Order',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-plus',
    //       loadingButtonName: '...',
    //     },
    //   },
    // },
    // AppDocumentTypes: {
    //   title: 'App Doc Types',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: App Doc Types',
    //   api: 'ApplicationDocType',
    //   moduleName: 'AppDocumentTypes',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'Document Types',
    //       formAPI: 'ApplicationDocType',
    //       id: 'applicationDocTypeId',
    //       message: { title: 'Document Types', message: 'Document Type updated!' },
    //       deleteMessage: { title: 'Document Types', message: 'Document Type deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'docType',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Document Type Name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'fileName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Choose a file to upload!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'docType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Document Type Name',
    //         },
    //         {
    //           name: 'fileName',
    //           element: 'FileChooser',
    //           type: 'file',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Choose File',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Document Type',
    //       formAPI: 'ApplicationDocType',
    //       hasFile: true,
    //       fileField: 'fileName',
    //       message: { title: 'Document Types', message: 'Document Type created!' },
    //       buildValidation: [
    //         {
    //           name: 'docType',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Document Type Name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'docType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Document Type Name',
    //         },
    //         {
    //           name: 'file',
    //           element: 'FileChooser',
    //           type: 'file',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Choose File',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    // },
    // Pages: {
    //   title: 'Pages',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Pages',
    //   api: 'page',
    //   moduleName: 'Pages',
    //   isPageEditor: true,
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'Pages',
    //       formAPI: 'page',
    //       id: 'pageId',
    //       message: { title: 'Pages', message: 'Page updated!' },
    //       deleteMessage: { title: 'Pages', message: 'Page deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'pageName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'pageTitle',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page Title is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'routePath',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Route path is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'pageId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //         {
    //           name: 'displayOrder',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Display Order',
    //         },
    //         {
    //           name: 'pageTitle',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Title',
    //         },
    //         {
    //           name: 'routePath',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'component',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Component',
    //         },
    //         {
    //           name: 'isVisible',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Visible',
    //         },
    //         {
    //           name: 'isSPA',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'SPA',
    //         },
    //         {
    //           name: 'isPrivate',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Private',
    //         },
    //         {
    //           name: 'isModal',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Modal',
    //         },
    //         {
    //           name: 'exact',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Exact',
    //         },
    //         {
    //           name: 'isDefault',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Default',
    //         },
    //         {
    //           name: 'isAuthenticated',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Authenticated',
    //         },
    //         {
    //           name: 'console',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiURL',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointAddData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointUpdateData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointDeleteData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointSchema',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Page',
    //       formAPI: 'page',
    //       message: { title: 'Pages', message: 'Page created!' },
    //       buildValidation: [
    //         {
    //           name: 'pageName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'pageTitle',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page Title is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'routePath',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Route path is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //         {
    //           name: 'displayOrder',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Display Order',
    //         },
    //         {
    //           name: 'pageTitle',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Title',
    //         },
    //         {
    //           name: 'routePath',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'component',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Component',
    //         },
    //         {
    //           name: 'isVisible',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Visible',
    //         },
    //         {
    //           name: 'isSPA',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'SPA',
    //         },
    //         {
    //           name: 'isPrivate',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Private',
    //         },
    //         {
    //           name: 'isModal',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Modal',
    //         },
    //         {
    //           name: 'exact',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Exact',
    //         },
    //         {
    //           name: 'isDefault',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Default',
    //         },
    //         {
    //           name: 'isAuthenticated',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Authenticated',
    //         },
    //         {
    //           name: 'console',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiURL',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointAddData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointUpdateData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointDeleteData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointSchema',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    // },
    // PrivatePages: {
    //   title: 'Modules',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Modules',
    //   api: 'page',
    //   moduleName: 'PrivatePages',
    //   moduleAddParam: true,
    //   moduleAddParamString: '&isPrivate=true',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'Modules',
    //       formAPI: 'page',
    //       id: 'pageId',
    //       message: { title: 'Modules', message: 'Module updated!' },
    //       deleteMessage: { title: 'Modules', message: 'Module deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'pageName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'pageTitle',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page Title is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'routePath',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Route path is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'component',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Component is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'pageId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Module Name',
    //         },
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Module Name',
    //         },
    //         {
    //           name: 'displayOrder',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Display Order',
    //         },
    //         {
    //           name: 'pageTitle',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Module Title',
    //         },
    //         {
    //           name: 'component',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Component',
    //         },
    //         {
    //           name: 'routePath',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'isVisible',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Visible',
    //         },
    //         {
    //           name: 'isSPA',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'SPA',
    //         },
    //         {
    //           name: 'isPrivate',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Private',
    //         },
    //         {
    //           name: 'isModal',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Modal',
    //         },
    //         {
    //           name: 'exact',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Exact',
    //         },
    //         {
    //           name: 'isDefault',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Default',
    //         },
    //         {
    //           name: 'isAuthenticated',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Authenticated',
    //         },
    //         {
    //           name: 'console',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiURL',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointAddData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointUpdateData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointDeleteData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointSchema',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 12,
    //           placeholder: 'Schema',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Module',
    //       formAPI: 'page',
    //       message: { title: 'Modules', message: 'Module created!' },
    //       buildValidation: [
    //         {
    //           name: 'pageName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'pageTitle',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Page Title is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'routePath',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Route path is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'component',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Component is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Module Name',
    //         },
    //         {
    //           name: 'displayOrder',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: '0',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Display Order',
    //         },
    //         {
    //           name: 'pageTitle',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Module Title',
    //         },
    //         {
    //           name: 'routePath',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'component',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Component',
    //         },
    //         {
    //           name: 'isVisible',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Visible',
    //         },
    //         {
    //           name: 'isSPA',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: false,
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'SPA',
    //         },
    //         {
    //           name: 'isPrivate',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Private',
    //         },
    //         {
    //           name: 'isModal',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Modal',
    //         },
    //         {
    //           name: 'exact',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Exact',
    //         },
    //         {
    //           name: 'isDefault',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: false,
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Default',
    //         },
    //         {
    //           name: 'isAuthenticated',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: true,
    //           size: 2,
    //           placeholder: 'Authenticated',
    //         },
    //         {
    //           name: 'console',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiURL',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointAddData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointUpdateData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointDeleteData',
    //           element: 'InputField',
    //           type: 'text',
    //           defaultValue: 'data',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Route Path',
    //         },
    //         {
    //           name: 'apiEndPointSchema',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 12,
    //           placeholder: 'Schema',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    //   hasDependencies: true,
    //   dependeciesFormAPIS: ['role'],
    //   isRowForm: true,
    //   rowForm: {
    //     crud: 4,
    //     formTitle: 'Page Roles',
    //     rowDataArray: 'roles',
    //     parentId: 'pageId',
    //     update: {
    //       formTitle: 'Page Roles',
    //       formAPI: 'PageRole',
    //       parentAPI: 'page',
    //       id: 'pageRoleId',
    //       message: { title: 'Page Roles', message: 'Role updated!' },
    //       deleteMessage: { title: 'Page Roles', message: 'Role deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'roleId',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Role name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'accessLevel',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Role name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'pageRoleId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Role Id',
    //         },
    //         {
    //           name: 'roleId',
    //           element: 'SelectBox',
    //           type: 'text',
    //           optionAPIDependency: true,
    //           compOptionAPIDependency: 'role',
    //           compId: 'roleId',
    //           compName: 'roleName',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Role name',
    //         },
    //         {
    //           name: 'accessLevel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Access Level',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-check',
    //       deleteButtonIcon: 'fa fa-trash',
    //       loadingButtonIcon: '...',
    //     },
    //     create: {
    //       formTitle: 'Add Page Role',
    //       formAPI: 'PageRole',
    //       parentAPI: 'page',
    //       message: { title: 'Page Roles', message: 'Role added!' },
    //       buildValidation: [
    //         {
    //           name: 'roleId',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Role name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'accessLevel',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Role name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       addParentId: true,
    //       parentId: 'pageId',
    //       schema: [
    //         {
    //           name: 'roleId',
    //           element: 'SelectBox',
    //           type: 'text',
    //           optionAPIDependency: true,
    //           compOptionAPIDependency: 'role',
    //           compId: 'roleId',
    //           compName: 'roleName',
    //           hidden: false,
    //           size: 8,
    //           placeholder: 'User Role',
    //         },
    //         {
    //           name: 'accessLevel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Access Level',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-plus',
    //       loadingButtonName: '...',
    //     },
    //   },
    // },
    // Users: {
    //   title: 'Users',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Users',
    //   api: 'appuser',
    //   moduleName: 'Users',
    //   form: {
    //     crud: 1,
    //     update: {
    //       formTitle: 'Users',
    //       formAPI: 'page',
    //       id: 'userId',
    //       message: { title: 'Users', message: 'Page updated!' },
    //       deleteMessage: { title: 'Users', message: 'Page deleted!' },
    //       buildValidation: [],
    //       schema: [
    //         {
    //           name: 'userName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'User Name',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Page',
    //       formAPI: 'page',
    //       message: { title: 'Pages', message: 'Page created!' },
    //       buildValidation: [],
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    //   hasDependencies: true,
    //   dependeciesFormAPIS: ['role'],
    //   isRowForm: true,
    //   rowForm: {
    //     crud: 4,
    //     formTitle: 'User Roles',
    //     rowDataArray: 'userRoles',
    //     parentId: 'userId',
    //     update: {
    //       formTitle: 'User Roles',
    //       formAPI: 'userrole',
    //       parentAPI: 'appUser',
    //       id: 'roleId',
    //       noUpdate: true,
    //       message: { title: 'Users', message: 'Role updated!' },
    //       deleteMessage: { title: 'Users', message: 'Role deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'roleName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Role name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'roleId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Role Name',
    //         },
    //         {
    //           name: 'roleName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Role Name',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-check',
    //       deleteButtonIcon: 'fa fa-trash',
    //       loadingButtonIcon: '...',
    //     },
    //     create: {
    //       formTitle: 'Add Page',
    //       formAPI: 'userrole',
    //       parentAPI: 'appUser',
    //       message: { title: 'User Roles', message: 'Role added!' },
    //       buildValidation: [
    //         {
    //           name: 'roleName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Role name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       addParentId: true,
    //       parentId: 'userId',
    //       schema: [
    //         {
    //           name: 'roleId',
    //           element: 'SelectBox',
    //           type: 'text',
    //           optionAPIDependency: true,
    //           compOptionAPIDependency: 'role',
    //           compId: 'roleId',
    //           compName: 'roleName',
    //           hidden: false,
    //           size: 8,
    //           placeholder: 'User Role',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-plus',
    //       loadingButtonName: '...',
    //     },
    //   },
    // },
    // Elements: {
    //   title: 'Elements',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Elements',
    //   searchTitle: 'Search Elements',
    //   searchObject: 'elementTypeName',
    //   api: 'ElementType',
    //   moduleName: 'Elements',
    //   filterBy: true,
    //   filterByKey: 'UINavigation',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'Elements',
    //       formAPI: 'ElementType',
    //       id: 'elementTypeId',
    //       message: { title: 'Elements', message: 'Element updated!' },
    //       deleteMessage: { title: 'Elements', message: 'Element deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'elementTypeName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Element name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'elementCSSLibraryName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Library name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'defaultParentLevel',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Parent level is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'elementTypeName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Element Type Name',
    //         },
    //         {
    //           name: 'elementCSSLibraryName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Library Name',
    //         },
    //         {
    //           name: 'defaultParentLevel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Parent Level',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Element',
    //       formAPI: 'ElementType',
    //       message: { title: 'Elements', message: 'Element created!' },
    //       buildValidation: [
    //         {
    //           name: 'elementTypeName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Element name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'elementCSSLibraryName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Library name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'defaultParentLevel',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Parent level is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'elementTypeName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Element Type Name',
    //         },
    //         {
    //           name: 'elementCSSLibraryName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Library Name',
    //         },
    //         {
    //           name: 'defaultParentLevel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 4,
    //           placeholder: 'Parent Level',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    //   isRowForm: true,
    //   rowForm: {
    //     crud: 4,
    //     formTitle: 'Element Properties',
    //     rowDataArray: 'rowFormData',
    //     parentId: 'elementTypeId',
    //     update: {
    //       formTitle: 'Element Properties',
    //       formAPI: 'ElementTypeProperty',
    //       parentAPI: 'ElementType',
    //       id: 'elementTypePropertyId',
    //       message: { title: 'Element Properties', message: 'Property updated!' },
    //       deleteMessage: { title: 'Users', message: 'Property deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'elementTypePropertyName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Property name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'elementTypePropertyId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Role Name',
    //         },
    //         {
    //           name: 'elementTypePropertyName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Name',
    //         },
    //         {
    //           name: 'elementTypePropertyDataType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Name',
    //         },
    //         {
    //           name: 'elementTypePropertyRequired',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Data Type',
    //         },
    //         {
    //           name: 'isAdvanced',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Advanced',
    //         },
    //         {
    //           name: 'elementTypePropertyDefaultValue',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Default Value',
    //         },
    //         {
    //           name: 'elementTypePropertyOrder',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Order',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-check',
    //       deleteButtonIcon: 'fa fa-trash',
    //       loadingButtonIcon: '...',
    //     },
    //     create: {
    //       formTitle: 'Add Element Property',
    //       formAPI: 'ElementTypeProperty',
    //       parentAPI: 'ElementType',
    //       message: { title: 'Element Properties', message: 'Propert added!' },
    //       buildValidation: [
    //         {
    //           name: 'elementTypePropertyName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Property name is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       addParentId: true,
    //       parentId: 'elementTypeId',
    //       schema: [
    //         {
    //           name: 'elementTypePropertyName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Name',
    //         },
    //         {
    //           name: 'elementTypePropertyDataType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Data Type',
    //         },
    //         {
    //           name: 'elementTypePropertyRequired',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Data Type',
    //         },
    //         {
    //           name: 'isAdvanced',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: false,
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Advanced',
    //         },
    //         {
    //           name: 'elementTypePropertyDefaultValue',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Default Value',
    //         },
    //         {
    //           name: 'elementTypePropertyOrder',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 2,
    //           placeholder: 'Order',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-plus',
    //       loadingButtonName: '...',
    //     },
    //   },
    // },
    // MyFamily: {
    //   title: 'My Family',
    //   searchTitle: 'Search Family Member',
    //   searchObject: 'userFamilyMemberName',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: My Family',
    //   api: 'userFamily',
    //   moduleName: 'MyFamily',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'My Family',
    //       formAPI: 'userFamily',
    //       id: 'userFamilyId',
    //       message: { title: 'Family', message: 'Family member updated!' },
    //       deleteMessage: { title: 'Family', message: 'Family member deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'userFamilyMemberName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userFamilyMemberRelationship',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Relationship is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userFamilyMemberEmail',
    //           validationType: 'email',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Email is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'userFamilyId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //         {
    //           name: 'userFamilyMemberName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Family Member Name',
    //         },
    //         {
    //           name: 'userFamilyMemberRelationship',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Relationship',
    //         },
    //         {
    //           name: 'userFamilyMemberEmail',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Email',
    //         },
    //         {
    //           name: 'userFamilyMemberCellphone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Cell Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberHomephone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Home Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberAltPhone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Alt Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberInviteStatus',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Invite Status',
    //         },
    //         {
    //           name: 'userFamilyMemberIsFamily',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Is Family',
    //         },
    //         {
    //           name: 'userFamilyMemberIsEmContact',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Emergency Contact',
    //         },
    //         {
    //           name: 'userFamilyMemberPickupPermissions',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Pickup Permissions',
    //         },
    //         {
    //           name: 'userFamilyMemberStreetAddress',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Address',
    //         },
    //         {
    //           name: 'userFamilyMemberCity',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'City',
    //         },
    //         {
    //           name: 'userFamilyMemberState',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'State',
    //         },
    //         {
    //           name: 'userFamilyMemberZipCode',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'ZipCode',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Family Member',
    //       formAPI: 'userFamily',
    //       addUserId: true,
    //       userId: 'userId',
    //       message: { title: 'Family', message: 'Family created!' },
    //       buildValidation: [
    //         {
    //           name: 'userFamilyMemberName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userFamilyMemberRelationship',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Relationship is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userFamilyMemberEmail',
    //           validationType: 'email',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Email is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'userFamilyMemberName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Family Member Name',
    //         },
    //         {
    //           name: 'userFamilyMemberRelationship',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Relationship',
    //         },
    //         {
    //           name: 'userFamilyMemberEmail',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Email',
    //         },
    //         {
    //           name: 'userFamilyMemberCellphone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Cell Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberHomephone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Home Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberAltPhone',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Alt Phone',
    //         },
    //         {
    //           name: 'userFamilyMemberInviteStatus',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Invite Status',
    //         },
    //         {
    //           name: 'userFamilyMemberIsFamily',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: true,
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Is Family',
    //         },
    //         {
    //           name: 'userFamilyMemberIsEmContact',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: false,
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Emergency Contact',
    //         },
    //         {
    //           name: 'userFamilyMemberPickupPermissions',
    //           element: 'SwitchBox',
    //           type: 'checkbox',
    //           defaultValue: false,
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Pickup Permissions',
    //         },
    //         {
    //           name: 'userFamilyMemberStreetAddress',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Address',
    //         },
    //         {
    //           name: 'userFamilyMemberCity',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'City',
    //         },
    //         {
    //           name: 'userFamilyMemberState',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'State',
    //         },
    //         {
    //           name: 'userFamilyMemberZipCode',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'ZipCode',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    // },
    // MyKids: {
    //   title: 'My Kids',
    //   searchTitle: 'Search Child',
    //   searchObject: 'userChildName',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: My Kids',
    //   api: 'userChildren',
    //   moduleName: 'MyKids',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'My Kids',
    //       formAPI: 'userChildren',
    //       id: 'userChildrenId',
    //       message: { title: 'Children', message: 'Children updated!' },
    //       deleteMessage: { title: 'Children', message: 'Children deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'userChildName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Child name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userChildGender',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Child gender is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userChildAgeMonths',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Child age is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'userChildrenId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //         {
    //           name: 'userChildName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Child Name',
    //         },
    //         {
    //           name: 'userChildGender',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Child Gender',
    //         },
    //         {
    //           name: 'userChildAgeMonths',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Age',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Child',
    //       formAPI: 'userChildren',
    //       addUserId: true,
    //       userId: 'userId',
    //       message: { title: 'Children', message: 'Child created!' },
    //       buildValidation: [
    //         {
    //           name: 'userChildName',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Child name is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userChildGender',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Child gender is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'userChildAgeMonths',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Child age is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'userChildName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Child Name',
    //         },
    //         {
    //           name: 'userChildGender',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Child Gender',
    //         },
    //         {
    //           name: 'userChildAgeMonths',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Age',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    // },
    ChildRegistrations: {
      title: 'Child Registrations',
      searchTitle: 'Search Registration',
      pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Child Registrations',
      api: 'appl',
      updateOnly: true,
      moduleName: 'ChildRegistrations',
      hasDependencies: true,
      hasDocuments: true,
      dependeciesFormAPIS: ['userChildren', 'userFamily'],
      isMultiForm: true,
      parentId: 'applicationId',
      multiForm: {
        formTitle: 'Application Forms',
        multiSchema: [
          {
            title: 'Child Registration',
            pdfSchemaSettings: { pdfName: 'ChildCare-ChildRegistration.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Child Care Registration Form</strong></span><br /><span style="font-size: 14pt;"><strong>(for family home or center program</strong></span></p>',
                    colSize: 'col-6',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Entry Date</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childEntryDate',
                    colSize: 'col-3',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Exit Date</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childExitDate',
                    colSize: 'col-3',
                  },
                ],
              },
              {
                cols: [
                  {
                    textAlign: 'left',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: left;">Child’s name (Last, First, Middle)</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childEntryDate',
                    colSize: 'col-5',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Name used (Nickname)</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childExitDate',
                    colSize: 'col-4',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Birthdate</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childExitDate',
                    colSize: 'col-3',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    formTitle: 'Child Details',
                    formAPI: 'appl',
                    id: 'applicationId',
                    handleDatefields: true,
                    dateFields: ['childEntryDate', 'childExitDate'],
                    message: { title: 'Child Application', message: 'Child details updated!' },
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                  {
                    formTitle: 'Parents/Guardians',
                    formAPI: 'applParent',
                    idObj: true,
                    id: ['ApplicationParent', 'applicationParentsId'],
                    message: { title: 'Child Application', message: 'Parents/Guardians updated!' },
                    isDynamic: true,
                    dynamicDataKey: 'parents',
                    dynamicParentId: 'applicationId',
                    schema: [
                      {
                        name: 'userFamilyId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'userFamilyMemberName',
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Member Name',
                      },
                    ],
                    validation: {
                      userFamilyId: Y.string().required('Select a member!'),
                    },
                    create: {
                      formAPI: 'applParent',
                      parentAPI: 'appl',
                      addParentId: true,
                      parentId: 'applicationId',
                      message: { title: 'Child Application', message: 'Parents/Guardians added!' },
                      schema: [
                        {
                          name: 'userFamilyId',
                          element: 'SelectBox',
                          type: 'text',
                          optionAPIDependency: true,
                          compOptionAPIDependency: 'userFamily',
                          compId: 'userFamilyId',
                          compName: 'userFamilyMemberName',
                          hidden: false,
                          size: 8,
                          placeholder: 'User Role',
                        },
                      ],
                      updateButtonIcon: 'fa fa-plus',
                      deleteButtonIcon: 'fa fa-times',
                      loadingButtonIcon: '...',
                    },
                    updateButtonIcon: 'fa fa-check',
                    deleteButtonIcon: 'fa fa-trash',
                    loadingButtonIcon: '...',
                  },
                  {
                    formTitle: 'Emergency Contacts',
                    formAPI: 'applEmergencyContact',
                    idObj: true,
                    id: ['ApplicationEmergencyContact', 'applicationEmergencyContactsId'],
                    message: { title: 'Child Application', message: 'Emergency contacts updated!' },
                    isDynamic: true,
                    dynamicDataKey: 'emergencyContacts',
                    dynamicParentId: 'applicationId',
                    schema: [
                      {
                        name: 'userFamilyId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'userFamilyMemberName',
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Member Name',
                      },
                    ],
                    validation: {
                      userFamilyId: Y.string().required('Select a member!'),
                    },
                    create: {
                      formAPI: 'applEmergencyContact',
                      parentAPI: 'appl',
                      addParentId: true,
                      parentId: 'applicationId',
                      message: { title: 'Child Application', message: 'Emergency contacts added!' },
                      schema: [
                        {
                          name: 'userFamilyId',
                          element: 'SelectBox',
                          type: 'text',
                          optionAPIDependency: true,
                          compOptionAPIDependency: 'userFamily',
                          compId: 'userFamilyId',
                          compName: 'userFamilyMemberName',
                          hidden: false,
                          size: 8,
                          placeholder: 'User Role',
                        },
                      ],
                      updateButtonIcon: 'fa fa-plus',
                      deleteButtonIcon: 'fa fa-times',
                      loadingButtonIcon: '...',
                    },
                    updateButtonIcon: 'fa fa-check',
                    deleteButtonIcon: 'fa fa-trash',
                    loadingButtonIcon: '...',
                  },
                  {
                    formTitle: 'Pickup Permissions',
                    formAPI: 'applPickupPermission',
                    idObj: true,
                    id: ['ApplicationPickupPermission', 'applicationPickupPermissionsId'],
                    message: { title: 'Child Application', message: 'Pickup permissions updated!' },
                    isDynamic: true,
                    dynamicDataKey: 'pickupPermissions',
                    dynamicParentId: 'applicationId',
                    schema: [
                      {
                        name: 'userFamilyId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'userFamilyMemberName',
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Member Name',
                      },
                    ],
                    validation: {
                      userFamilyId: Y.string().required('Select a member!'),
                    },
                    create: {
                      formAPI: 'applPickupPermission',
                      parentAPI: 'appl',
                      addParentId: true,
                      parentId: 'applicationId',
                      message: { title: 'Child Application', message: 'Pickup permissions added!' },
                      schema: [
                        {
                          name: 'userFamilyId',
                          element: 'SelectBox',
                          type: 'text',
                          optionAPIDependency: true,
                          compOptionAPIDependency: 'userFamily',
                          compId: 'userFamilyId',
                          compName: 'userFamilyMemberName',
                          hidden: false,
                          size: 8,
                          placeholder: 'User Role',
                        },
                      ],
                      updateButtonIcon: 'fa fa-plus',
                      deleteButtonIcon: 'fa fa-times',
                      loadingButtonIcon: '...',
                    },
                    updateButtonIcon: 'fa fa-check',
                    deleteButtonIcon: 'fa fa-trash',
                    loadingButtonIcon: '...',
                  },
                ],
              },
            },
          },
          {
            title: 'Agreement',
            pdfSchemaSettings: { pdfName: 'ChildCare-Agreement.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Bal Bhavan Family Home Childcare</strong></span></p>',
                    colSize: 'col-12',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    name: 'Registration',
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                ],
              },
            },
          },
          {
            title: 'Diaper',
            pdfSchemaSettings: { pdfName: 'ChildCare-Diaper.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Diaper Cream/Ointment Authorization Form</strong></span></p>',
                    colSize: 'col-6',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    name: 'Registration',
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                ],
              },
            },
          },
          {
            title: 'Sunscreen',
            pdfSchemaSettings: { pdfName: 'ChildCare-Sunscreen.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Sunscreen Authorization Form</strong></span><br /><span style="font-size: 14pt;">(Sunscreen Brought from Home)</span></p>',
                    colSize: 'col-6',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Entry Date</p>',
                    colSize: 'col-3',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Exit Date</p>',
                    colSize: 'col-3',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    name: 'Registration',
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                ],
              },
            },
          },
        ],
      },
      form: {
        crud: 3,
        update: {
          formTitle: 'Applications',
          formAPI: 'appl',
          id: 'applicationId',
          message: { title: 'My Applications', message: 'Application updated!' },
          deleteMessage: { title: 'My Applications', message: 'Application deleted!' },
          validation: {
            applicationName: Y.string().required('Application name is required!'),
            applicationStatus: Y.string().required(),
          },
          schema: [
            {
              name: 'applicationId',
              element: 'InputField',
              type: 'text',
              hidden: true,
              size: 6,
              placeholder: 'Application Name',
            },
            {
              name: 'userChildName',
              element: 'InputField',
              valFromObj: true,
              obj: ['UserChild', 'userChildName'],
              placeholder: 'Child name',
              hidden: false,
              disabled: true,
              size: 6,
            },
            {
              name: 'userChildrenId',
              element: 'InputField',
              valFromObj: true,
              obj: ['UserChild', 'userChildrenId'],
              placeholder: 'Child name',
              hidden: true,
              disabled: true,
              size: 6,
            },
            {
              name: 'applicationName',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Name',
            },
            {
              name: 'applicationStatus',
              element: 'SelectBox',
              type: 'text',
              optionArrayDependency: true,
              compOptionArrayDependency: [
                { id: 'DRAFT', name: 'Draft' },
                { id: 'SUBMITTED', name: 'Submitted' },
                { id: 'APPROVED', name: 'Approved' },
              ],
              compId: 'userChildrenId',
              compName: 'userChildName',
              hidden: false,
              size: 6,
              placeholder: 'Application Status',
            },
            {
              name: 'applicationNotes',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Notes',
            },
          ],
          updateButtonName: 'Update',
          deleteButtonName: 'Delete',
          loadingButtonName: 'Updating...',
        },
        create: {
          formTitle: 'Add Application',
          formAPI: 'appl',
          addUserId: true,
          userId: 'userId',
          message: { title: 'Applications', message: 'Application created!' },
          validation: {
            userChildrenId: Y.string().required('Child name is required!'),
            applicationName: Y.string().required('Application name is required!'),
            applicationStatus: Y.string()
              .matches(/^(?!approved\b)/i, 'You cannot approve applications!')
              .required(),
          },
          schema: [
            {
              name: 'userChildrenId',
              element: 'SelectBox',
              type: 'text',
              optionAPIDependency: true,
              compOptionAPIDependency: 'userChildren',
              compId: 'userChildrenId',
              compName: 'userChildName',
              hidden: false,
              size: 6,
              placeholder: 'Child name',
            },
            {
              name: 'applicationName',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Name',
            },
            {
              name: 'applicationStatus',
              element: 'SelectBox',
              type: 'text',
              optionArrayDependency: true,
              compOptionArrayDependency: [
                { id: 'DRAFT', name: 'Draft' },
                { id: 'SUBMITTED', name: 'Submitted' },
                { id: 'APPROVED', name: 'Approved' },
              ],
              compId: 'userChildrenId',
              compName: 'userChildName',
              hidden: false,
              size: 6,
              placeholder: 'Application Status',
            },
            {
              name: 'applicationNotes',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Notes',
            },
          ],
          updateButtonName: 'Add',
          loadingButtonName: 'Adding...',
        },
      },
      fileManager: {
        title: 'Application Documents',
      },
    },
    MyApplications: {
      title: 'My Applications',
      searchTitle: 'Search Registration',
      pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: My Applications',
      api: 'appl',
      moduleName: 'MyApplications',
      hasDependencies: true,
      hasDocuments: true,
      dependeciesFormAPIS: ['userChildren', 'userFamily', 'ApplicationDocType'],
      isMultiForm: true,
      parentId: 'applicationId',
      multiForm: {
        formTitle: 'Application Forms',
        multiFormAPI: 'ApplicationDocType',
        multiFormAPIID: 'applicationId',
        multiSchema: [
          {
            title: 'Child Registration',
            pdfSchemaSettings: { pdfName: 'ChildCare-ChildRegistration.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Child Care Registration Form</strong></span><br /><span style="font-size: 14pt;"><strong>(for family home or center program</strong></span></p>',
                    colSize: 'col-6',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Entry Date</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childEntryDate',
                    colSize: 'col-3',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Exit Date</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childExitDate',
                    colSize: 'col-3',
                  },
                ],
              },
              {
                cols: [
                  {
                    textAlign: 'left',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: left;">Child’s name (Last, First, Middle)</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childEntryDate',
                    colSize: 'col-5',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Name used (Nickname)</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childExitDate',
                    colSize: 'col-4',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Birthdate</p>',
                    hasData: true,
                    isDate: true,
                    dateFormat: 'MM-DD-YYYY',
                    dataKey: 'childExitDate',
                    colSize: 'col-3',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    formTitle: 'Child Details',
                    formAPI: 'appl',
                    id: 'applicationId',
                    handleDatefields: true,
                    dateFields: ['childEntryDate', 'childExitDate'],
                    message: { title: 'Child Application', message: 'Child details updated!' },
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                  {
                    formTitle: 'Parents/Guardians',
                    formAPI: 'applParent',
                    idObj: true,
                    id: ['ApplicationParent', 'applicationParentsId'],
                    message: { title: 'Child Application', message: 'Parents/Guardians updated!' },
                    deleteMessage: {
                      title: 'Child Application',
                      message: 'Parent/Guardian deleted!',
                    },
                    isDynamic: true,
                    dynamicDataKey: 'parents',
                    dynamicParentId: 'applicationId',
                    schema: [
                      {
                        name: 'userFamilyId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'userFamilyMemberName',
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Member Name',
                      },
                    ],
                    validation: {
                      userFamilyId: Y.string().required('Select a member!'),
                    },
                    create: {
                      formAPI: 'applParent',
                      parentAPI: 'appl',
                      addParentId: true,
                      parentId: 'applicationId',
                      message: { title: 'Child Application', message: 'Parents/Guardians added!' },
                      schema: [
                        {
                          name: 'userFamilyId',
                          element: 'SelectBox',
                          type: 'text',
                          optionAPIDependency: true,
                          compOptionAPIDependency: 'userFamily',
                          compId: 'userFamilyId',
                          compName: 'userFamilyMemberName',
                          hidden: false,
                          size: 8,
                          placeholder: 'User Role',
                        },
                      ],
                      updateButtonIcon: 'fa fa-plus',
                      deleteButtonIcon: 'fa fa-times',
                      loadingButtonIcon: '...',
                    },
                    updateButtonIcon: 'fa fa-check',
                    deleteButtonIcon: 'fa fa-trash',
                    loadingButtonIcon: '...',
                  },
                  {
                    formTitle: 'Emergency Contacts',
                    formAPI: 'applEmergencyContact',
                    idObj: true,
                    id: ['ApplicationEmergencyContact', 'applicationEmergencyContactsId'],
                    message: { title: 'Child Application', message: 'Emergency contacts updated!' },
                    deleteMessage: {
                      title: 'Child Application',
                      message: 'Emergency contact deleted!',
                    },
                    isDynamic: true,
                    dynamicDataKey: 'emergencyContacts',
                    dynamicParentId: 'applicationId',
                    schema: [
                      {
                        name: 'userFamilyId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'userFamilyMemberName',
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Member Name',
                      },
                    ],
                    validation: {
                      userFamilyId: Y.string().required('Select a member!'),
                    },
                    create: {
                      formAPI: 'applEmergencyContact',
                      parentAPI: 'appl',
                      addParentId: true,
                      parentId: 'applicationId',
                      message: { title: 'Child Application', message: 'Emergency contacts added!' },
                      schema: [
                        {
                          name: 'userFamilyId',
                          element: 'SelectBox',
                          type: 'text',
                          optionAPIDependency: true,
                          compOptionAPIDependency: 'userFamily',
                          compId: 'userFamilyId',
                          compName: 'userFamilyMemberName',
                          hidden: false,
                          size: 8,
                          placeholder: 'User Role',
                        },
                      ],
                      updateButtonIcon: 'fa fa-plus',
                      deleteButtonIcon: 'fa fa-times',
                      loadingButtonIcon: '...',
                    },
                    updateButtonIcon: 'fa fa-check',
                    deleteButtonIcon: 'fa fa-trash',
                    loadingButtonIcon: '...',
                  },
                  {
                    formTitle: 'Pickup Permissions',
                    formAPI: 'applPickupPermission',
                    idObj: true,
                    id: ['ApplicationPickupPermission', 'applicationPickupPermissionsId'],
                    message: { title: 'Child Application', message: 'Pickup permissions updated!' },
                    deleteMessage: {
                      title: 'Child Application',
                      message: 'Pickup permission deleted!',
                    },
                    isDynamic: true,
                    dynamicDataKey: 'pickupPermissions',
                    dynamicParentId: 'applicationId',
                    schema: [
                      {
                        name: 'userFamilyId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'userFamilyMemberName',
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Member Name',
                      },
                    ],
                    validation: {
                      userFamilyId: Y.string().required('Select a member!'),
                    },
                    create: {
                      formAPI: 'applPickupPermission',
                      parentAPI: 'appl',
                      addParentId: true,
                      parentId: 'applicationId',
                      message: { title: 'Child Application', message: 'Pickup permissions added!' },
                      schema: [
                        {
                          name: 'userFamilyId',
                          element: 'SelectBox',
                          type: 'text',
                          optionAPIDependency: true,
                          compOptionAPIDependency: 'userFamily',
                          compId: 'userFamilyId',
                          compName: 'userFamilyMemberName',
                          hidden: false,
                          size: 8,
                          placeholder: 'User Role',
                        },
                      ],
                      updateButtonIcon: 'fa fa-plus',
                      deleteButtonIcon: 'fa fa-times',
                      loadingButtonIcon: '...',
                    },
                    updateButtonIcon: 'fa fa-check',
                    deleteButtonIcon: 'fa fa-trash',
                    loadingButtonIcon: '...',
                  },
                ],
              },
            },
          },
          {
            title: 'Agreement',
            pdfSchemaSettings: { pdfName: 'ChildCare-Agreement.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Bal Bhavan Family Home Childcare</strong></span></p>',
                    colSize: 'col-12',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    name: 'Registration',
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                ],
              },
            },
          },
          {
            title: 'Diaper',
            pdfSchemaSettings: { pdfName: 'ChildCare-Diaper.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Diaper Cream/Ointment Authorization Form</strong></span></p>',
                    colSize: 'col-6',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    name: 'Registration',
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                ],
              },
            },
          },
          {
            title: 'Sunscreen',
            pdfSchemaSettings: { pdfName: 'ChildCare-Sunscreen.pdf' },
            pdfSchema: [
              {
                cols: [
                  {
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    text: '<p style="text-align: center;"><span><strong>Sunscreen Authorization Form</strong></span><br /><span style="font-size: 14pt;">(Sunscreen Brought from Home)</span></p>',
                    colSize: 'col-6',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Entry Date</p>',
                    colSize: 'col-3',
                  },
                  {
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    text: '<p style="text-align: center;">Exit Date</p>',
                    colSize: 'col-3',
                  },
                ],
              },
            ],
            form: {
              update: {
                formTitle: '',
                formAPI: 'page',
                id: 'userId',
                message: { title: 'Users', message: 'Page updated!' },
                deleteMessage: { title: 'Users', message: 'Page deleted!' },
                validation: {
                  childName: Y.string().required('Page name is required!'),
                },
                steps: [
                  {
                    name: 'Registration',
                    schema: [
                      {
                        name: 'userChildrenId',
                        element: 'InputField',
                        type: 'text',
                        hidden: true,
                        size: 6,
                        placeholder: 'Role Name',
                      },
                      {
                        name: 'childName',
                        valFromObj: true,
                        obj: ['UserChild', 'userChildName'],
                        element: 'InputField',
                        type: 'text',
                        hidden: false,
                        size: 4,
                        placeholder: 'Child Name',
                      },
                      {
                        name: 'childEntryDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Entry Date',
                      },
                      {
                        name: 'childExitDate',
                        element: 'InputField',
                        type: 'date',
                        hidden: false,
                        size: 4,
                        placeholder: 'Exit Date',
                      },
                    ],
                    updateButtonName: 'Update',
                    deleteButtonName: 'Delete',
                    loadingButtonName: 'Updating...',
                  },
                ],
              },
            },
          },
        ],
      },
      form: {
        crud: 4,
        update: {
          formTitle: 'Applications',
          formAPI: 'appl',
          id: 'applicationId',
          message: { title: 'My Applications', message: 'Application updated!' },
          deleteMessage: { title: 'My Applications', message: 'Application deleted!' },
          buildValidation: [
            {
              name: 'userChildrenId',
              validationType: 'string',
              validations: [
                {
                  type: 'required',
                  params: ['Child name is required!'],
                },
              ],
            },
            {
              name: 'applicationName',
              validationType: 'string',
              validations: [
                {
                  type: 'required',
                  params: ['Application name is required!'],
                },
              ],
            },
            {
              name: 'applicationStatus',
              validationType: 'string',
              validations: [
                {
                  type: 'matches',
                  params: [/^(?!approved\b)/i, 'You cannot approve applications!'],
                },
                {
                  type: 'required',
                  params: ['Child age is required!'],
                },
              ],
            },
          ],
          schema: [
            {
              name: 'applicationId',
              element: 'InputField',
              type: 'text',
              hidden: true,
              size: 6,
              placeholder: 'Application Name',
            },
            {
              name: 'userChildrenId',
              element: 'SelectBox',
              type: 'text',
              obj: ['UserChild', 'userChildrenId'],
              hidden: true,
              size: 6,
              placeholder: 'Child name',
            },
            {
              name: 'userChildName',
              element: 'InputField',
              type: 'text',
              valFromObj: true,
              obj: ['UserChild', 'userChildName'],
              hidden: false,
              disabled: true,
              size: 6,
              placeholder: 'Child name',
            },
            {
              name: 'applicationName',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Name',
            },
            {
              name: 'applicationStatus',
              element: 'SelectBox',
              type: 'text',
              optionArrayDependency: true,
              compOptionArrayDependency: [
                { id: 'DRAFT', name: 'Draft' },
                { id: 'SUBMITTED', name: 'Submitted' },
                { id: 'APPROVED', name: 'Approved' },
              ],
              compId: 'userChildrenId',
              compName: 'userChildName',
              hidden: false,
              size: 6,
              placeholder: 'Application Status',
            },
            {
              name: 'applicationNotes',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Notes',
            },
          ],
          updateButtonName: 'Update',
          deleteButtonName: 'Delete',
          loadingButtonName: 'Updating...',
        },
        create: {
          formTitle: 'Add Application',
          formAPI: 'appl',
          addUserId: true,
          userId: 'userId',
          message: { title: 'Applications', message: 'Application created!' },
          buildValidation: [
            {
              name: 'userChildrenId',
              validationType: 'string',
              validations: [
                {
                  type: 'required',
                  params: ['Child name is required!'],
                },
              ],
            },
            {
              name: 'applicationName',
              validationType: 'string',
              validations: [
                {
                  type: 'required',
                  params: ['Application name is required!'],
                },
              ],
            },
            {
              name: 'applicationStatus',
              validationType: 'string',
              validations: [
                {
                  type: 'matches',
                  params: [/^(?!approved\b)/i, 'You cannot approve applications!'],
                },
                {
                  type: 'required',
                  params: ['Child age is required!'],
                },
              ],
            },
          ],
          schema: [
            {
              name: 'userChildrenId',
              element: 'SelectBox',
              type: 'text',
              optionAPIDependency: true,
              compOptionAPIDependency: 'userChildren',
              compId: 'userChildrenId',
              compName: 'userChildName',
              hidden: false,
              size: 6,
              placeholder: 'Child name',
            },
            {
              name: 'applicationName',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Name',
            },
            {
              name: 'applicationStatus',
              element: 'SelectBox',
              type: 'text',
              optionArrayDependency: true,
              compOptionArrayDependency: [
                { id: 'DRAFT', name: 'Draft' },
                { id: 'SUBMITTED', name: 'Submitted' },
                { id: 'APPROVED', name: 'Approved' },
              ],
              compId: 'userChildrenId',
              compName: 'userChildName',
              hidden: false,
              size: 6,
              placeholder: 'Application Status',
            },
            {
              name: 'applicationNotes',
              element: 'InputField',
              type: 'text',
              hidden: false,
              size: 6,
              placeholder: 'Application Notes',
            },
          ],
          updateButtonName: 'Add',
          loadingButtonName: 'Adding...',
        },
      },
      fileManager: {
        title: 'Application Documents',
      },
    },
    // PickupDrop: {
    //   title: 'Pickup/Drop',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Pickup/Drop',
    //   api: 'pickupPermission',
    //   moduleName: 'QRCodeForm',
    //   qrCodeStringKey: 'pickupAuthCodeFull',
    //   displayName: 'userChildName',
    //   modalForm: {
    //     crud: 1,
    //     formTitle: 'Pickup/Drop Passcode',
    //     formAPI: 'pickupPermission',
    //     id: 'applicationPickupPermissionsId',
    //     message: { title: 'Pickup/Drop', message: 'Passcode updated!' },
    //     deleteMessage: { title: 'Pickup/Drop', message: 'Passcode deleted!' },
    //     validation: {
    //       pickupAuthCode: Y.string().required('Passcode is required!'),
    //     },
    //     schema: [
    //       {
    //         name: 'pickupAuthCode',
    //         hasPrefix: true,
    //         prefixKey: 'pickupAuthCodePrefix',
    //         element: 'InputField',
    //         type: 'text',
    //         hidden: false,
    //         size: 6,
    //         placeholder: 'Passcode',
    //       },
    //     ],
    //     updateButtonName: 'Update',
    //     deleteButtonName: 'Delete',
    //     loadingButtonName: 'Updating...',
    //   },
    // },
    // StaffPassCode: {
    //   title: 'Staff Passcode',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Staff Passcode',
    //   api: 'appuser',
    //   moduleName: 'QRCodeForm',
    //   noFullCode: true,
    //   codeKey: 'authCode',
    //   getById: true,
    //   prefixKey: 'authCodePrefix',
    //   displayName: 'userFullName',
    //   modalForm: {
    //     crud: 1,
    //     formTitle: 'Staff Check-In Passcode',
    //     formAPI: 'appuser',
    //     id: 'userId',
    //     message: { title: 'Staff Passcode', message: 'Passcode updated!' },
    //     deleteMessage: { title: 'Staff Passcode', message: 'Passcode deleted!' },
    //     validation: {
    //       authCode: Y.string().required('Passcode is required!'),
    //     },
    //     schema: [
    //       {
    //         name: 'authCode',
    //         hasPrefix: true,
    //         prefixKey: 'authCodePrefix',
    //         element: 'InputField',
    //         type: 'text',
    //         hidden: false,
    //         size: 6,
    //         placeholder: 'Passcode',
    //       },
    //     ],
    //     updateButtonName: 'Update',
    //     deleteButtonName: 'Delete',
    //     loadingButtonName: 'Updating...',
    //   },
    // },
    // ChildAttendance: {
    //   title: 'Attendance',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Child Attendance',
    //   api: 'attendance',
    //   moduleName: 'ChildAttendance',
    //   classesNames: ['Lion'],
    //   isRowForm: true,
    //   rowForm: {
    //     crud: 4,
    //     formTitle: 'Activity Details',
    //     rowDataArray: 'userRoles',
    //     parentId: 'userId',
    //     update: {
    //       formTitle: 'Activity Details',
    //       formAPI: 'activityDetail',
    //       id: 'activityDetailId',
    //       message: { title: 'Activities', message: 'Activity updated!' },
    //       deleteMessage: { title: 'Activities', message: 'Activity deleted!' },
    //       buildValidation: [
    //         {
    //           name: 'activityDetailType',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Type is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'activityDetailLabel',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Label is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'activityDetailId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Type',
    //         },
    //         {
    //           name: 'activityDetailType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Type',
    //         },
    //         {
    //           name: 'activityDetailLabel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Label',
    //         },
    //         {
    //           name: 'activityDetailDescription',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Description',
    //         },
    //         {
    //           name: 'activityDetailNotes',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Notes',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-check',
    //       deleteButtonIcon: 'fa fa-trash',
    //       loadingButtonIcon: '...',
    //     },
    //     create: {
    //       formTitle: 'Add Activity',
    //       formAPI: 'activityDetail',
    //       message: { title: 'Activities', message: 'Activity added!' },
    //       addParentId: true,
    //       parentId: 'activityId',
    //       buildValidation: [
    //         {
    //           name: 'activityDetailType',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Type is required!'],
    //             },
    //           ],
    //         },
    //         {
    //           name: 'activityDetailLabel',
    //           validationType: 'string',
    //           validations: [
    //             {
    //               type: 'required',
    //               params: ['Label is required!'],
    //             },
    //           ],
    //         },
    //       ],
    //       schema: [
    //         {
    //           name: 'activityDetailType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Type',
    //         },
    //         {
    //           name: 'activityDetailLabel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Label',
    //         },
    //         {
    //           name: 'activityDetailDescription',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Description',
    //         },
    //         {
    //           name: 'activityDetailNotes',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Notes',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-plus',
    //       loadingButtonName: '...',
    //     },
    //   },
    // },
    // StaffAttendance: {
    //   title: 'Staff Attendance',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Staff Attendance',
    //   api: 'staffattendance',
    //   moduleName: 'StaffAttendance',
    //   classesNames: ['Lion'],
    //   isRowForm: true,
    //   rowForm: {
    //     crud: 4,
    //     formTitle: 'Activity Details',
    //     rowDataArray: 'userRoles',
    //     parentId: 'userId',
    //     update: {
    //       formTitle: 'Activity Details',
    //       formAPI: 'activityDetail',
    //       id: 'activityDetailId',
    //       message: { title: 'Activities', message: 'Activity updated!' },
    //       deleteMessage: { title: 'Activities', message: 'Activity deleted!' },
    //       validation: {
    //         activityDetailType: Y.string().required('Type is required!'),
    //         activityDetailLabel: Y.string().required('Label is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'activityDetailId',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: true,
    //           size: 6,
    //           placeholder: 'Type',
    //         },
    //         {
    //           name: 'activityDetailType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Type',
    //         },
    //         {
    //           name: 'activityDetailLabel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Label',
    //         },
    //         {
    //           name: 'activityDetailDescription',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Description',
    //         },
    //         {
    //           name: 'activityDetailNotes',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Notes',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-check',
    //       deleteButtonIcon: 'fa fa-trash',
    //       loadingButtonIcon: '...',
    //     },
    //     create: {
    //       formTitle: 'Add Activity',
    //       formAPI: 'activityDetail',
    //       message: { title: 'Activities', message: 'Activity added!' },
    //       addParentId: true,
    //       parentId: 'activityId',
    //       validation: {
    //         activityDetailType: Y.string().required('Type is required!'),
    //         activityDetailLabel: Y.string().required('Label is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'activityDetailType',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Type',
    //         },
    //         {
    //           name: 'activityDetailLabel',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Label',
    //         },
    //         {
    //           name: 'activityDetailDescription',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Description',
    //         },
    //         {
    //           name: 'activityDetailNotes',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 3,
    //           placeholder: 'Notes',
    //         },
    //       ],
    //       updateButtonIcon: 'fa fa-plus',
    //       loadingButtonName: '...',
    //     },
    //   },
    // },
    // MyAttendance: {
    //   title: 'Kids Attendance',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: My Attendance',
    //   api: 'attendance',
    //   moduleName: 'MyAttendance',
    //   classesNames: ['Lion'],
    //   hasDependencies: true,
    //   dependeciesFormAPIS: ['appl'],
    //   isPageEditor: true,
    //   modalAPI: 'activity',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'Pages',
    //       formAPI: 'activity',
    //       id: 'pageId',
    //       message: { title: 'Pages', message: 'Page updated!' },
    //       deleteMessage: { title: 'Pages', message: 'Page deleted!' },
    //       validation: {
    //         pageName: Y.string().required('Page name is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Page',
    //       formAPI: 'page',
    //       message: { title: 'Pages', message: 'Page created!' },
    //       validation: {
    //         pageName: Y.string().required('Page name is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    //   pdfSchemaSettings: { pdfName: 'ChildCare-ActivityReport.pdf' },
    //   pdfSchema: [
    //     {
    //       cols: [
    //         {
    //           textAlign: 'center',
    //           fontSize: '20px',
    //           fontWeight: 'bold',
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Bal Bhavan Child Care <br />Activity Report</span>',
    //           colSize: 'col-12',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '10px', paddingBottom: '10px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Child Name: </span>',
    //           hasData: true,
    //           dataConst: 'name',
    //           dataKey: 'userChildName',
    //           colSize: 'col-7',
    //         },
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '10px', paddingBottom: '10px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Date: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkInDateTime',
    //           colSize: 'col-5',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check In: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkInDateTime',
    //           colSize: 'col-6',
    //         },
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check In Confirmed: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkInConfirmedDateTime',
    //           colSize: 'col-6',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check Out: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkOutDateTime',
    //           colSize: 'col-6',
    //         },
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check Out Confirmed: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkOutConfirmedDateTime',
    //           colSize: 'col-6',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Type </span>',
    //           colSize: 'col-2',
    //         },
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Label </span>',
    //           colSize: 'col-2',
    //         },
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Description </span>',
    //           colSize: 'col-4',
    //         },
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Notes </span>',
    //           colSize: 'col-4',
    //         },
    //       ],
    //     },
    //     {
    //       isRowData: true,
    //       dataConst: 'pdfHeader',
    //       rowFormDataKey: 'rowFormData',
    //       cols: [
    //         {
    //           colSize: 'col-2',
    //           hasData: true,
    //           dataKey: 'activityDetailType',
    //         },
    //         {
    //           colSize: 'col-2',
    //           hasData: true,
    //           dataKey: 'activityDetailLabel',
    //         },
    //         {
    //           colSize: 'col-4',
    //           hasData: true,
    //           dataKey: 'activityDetailDescription',
    //         },
    //         {
    //           colSize: 'col-4',
    //           hasData: true,
    //           dataKey: 'activityDetailNotes',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // AttendanceStaff: {
    //   title: 'Attendance Staff',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Attendance Staff',
    //   api: 'staffattendance',
    //   moduleName: 'AttendanceStaff',
    //   classesNames: ['Lion'],
    //   hasDependencies: true,
    //   dependeciesFormAPIS: ['appl'],
    //   isPageEditor: true,
    //   modalAPI: 'activity',
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'Pages',
    //       formAPI: 'activity',
    //       id: 'pageId',
    //       message: { title: 'Pages', message: 'Page updated!' },
    //       deleteMessage: { title: 'Pages', message: 'Page deleted!' },
    //       validation: {
    //         pageName: Y.string().required('Page name is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Page',
    //       formAPI: 'page',
    //       message: { title: 'Pages', message: 'Page created!' },
    //       validation: {
    //         pageName: Y.string().required('Page name is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    //   pdfSchemaSettings: { pdfName: 'ChildCare-ActivityReport.pdf' },
    //   pdfSchema: [
    //     {
    //       cols: [
    //         {
    //           textAlign: 'center',
    //           fontSize: '20px',
    //           fontWeight: 'bold',
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Bal Bhavan Child Care <br />Activity Report</span>',
    //           colSize: 'col-12',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '10px', paddingBottom: '10px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Child Name: </span>',
    //           hasData: true,
    //           dataConst: 'name',
    //           dataKey: 'userChildName',
    //           colSize: 'col-7',
    //         },
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '10px', paddingBottom: '10px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Date: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkInDateTime',
    //           colSize: 'col-5',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check In: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkInDateTime',
    //           colSize: 'col-6',
    //         },
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check In Confirmed: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkInConfirmedDateTime',
    //           colSize: 'col-6',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check Out: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkOutDateTime',
    //           colSize: 'col-6',
    //         },
    //         {
    //           fontSize: '12px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Check Out Confirmed: </span>',
    //           isDate: true,
    //           dateFormat: 'MM-DD-YYYY h:mm A',
    //           hasData: true,
    //           dataConst: 'pdfHeader',
    //           dataKey: 'checkOutConfirmedDateTime',
    //           colSize: 'col-6',
    //         },
    //       ],
    //     },
    //     {
    //       cols: [
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Type </span>',
    //           colSize: 'col-2',
    //         },
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Label </span>',
    //           colSize: 'col-2',
    //         },
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Description </span>',
    //           colSize: 'col-4',
    //         },
    //         {
    //           fontSize: '14px',
    //           fontWeight: 'bold',
    //           styles: { paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' },
    //           text: '<span style="padding-top:5px;padding-bottom:5px; text-align:center">Notes </span>',
    //           colSize: 'col-4',
    //         },
    //       ],
    //     },
    //     {
    //       isRowData: true,
    //       dataConst: 'pdfHeader',
    //       rowFormDataKey: 'rowFormData',
    //       cols: [
    //         {
    //           colSize: 'col-2',
    //           hasData: true,
    //           dataKey: 'activityDetailType',
    //         },
    //         {
    //           colSize: 'col-2',
    //           hasData: true,
    //           dataKey: 'activityDetailLabel',
    //         },
    //         {
    //           colSize: 'col-4',
    //           hasData: true,
    //           dataKey: 'activityDetailDescription',
    //         },
    //         {
    //           colSize: 'col-4',
    //           hasData: true,
    //           dataKey: 'activityDetailNotes',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // OrgMessages: {
    //   title: 'Org Messages',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Org Messages',
    //   api: 'userMessage',
    //   moduleName: 'MyMessages',
    //   message: { title: 'Messages', message: 'Message sent!' },
    // },
    // MyMessages: {
    //   title: 'My Messages',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: My Messages',
    //   api: 'userMessage',
    //   moduleName: 'MyMessages',
    //   message: { title: 'Messages', message: 'Message sent!' },
    // },
    // CheckInOut: {
    //   title: 'Check-In/Out',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Check-In/Check-Out',
    //   api: 'page',
    //   moduleName: 'CheckInOut',
    //   formTitle: 'Add Page',
    //   formAPI: 'attendance',
    //   message: { title: 'Pages', message: 'Page created!' },
    //   validation: {
    //     pickupAuthCodeFull: Y.string().required('Please enter you code!'),
    //   },
    //   authCodeField: 'pickupAuthCodeFull',
    //   schema: [
    //     {
    //       name: 'pickupAuthCodeFull',
    //       element: 'InputField',
    //       type: 'text',
    //       hidden: false,
    //       size: 6,
    //       placeholder: 'Page Name',
    //     },
    //   ],
    //   updateButtonName: 'Add',
    //   loadingButtonName: 'Adding...',
    // },
    // StaffCheckIn: {
    //   title: 'Staff Check-In',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Staff Check-In',
    //   api: 'staffattendance',
    //   moduleName: 'StaffCheckIn',
    //   formTitle: 'Add Page',
    //   formAPI: 'staffattendance',
    //   message: { title: 'Pages', message: 'Page created!' },
    //   validation: {
    //     authCodeFull: Y.string().required('Please enter you code!'),
    //   },
    //   authCodeField: 'authCodeFull',

    //   schema: [
    //     {
    //       name: 'authCodeFull',
    //       element: 'InputField',
    //       type: 'text',
    //       hidden: false,
    //       size: 6,
    //       placeholder: 'Page Name',
    //     },
    //   ],
    //   updateButtonName: 'Add',
    //   loadingButtonName: 'Adding...',
    // },
    // CheckOut: {
    //   title: 'Pages',
    //   pageTitle: process.env.REACT_APP_PAGE_TITLE + ' :: Check-In/Check-Out',
    //   api: 'page',
    //   moduleName: 'Pages',
    //   isPageEditor: true,
    //   form: {
    //     crud: 4,
    //     update: {
    //       formTitle: 'Pages',
    //       formAPI: 'page',
    //       id: 'pageId',
    //       message: { title: 'Pages', message: 'Page updated!' },
    //       deleteMessage: { title: 'Pages', message: 'Page deleted!' },
    //       validation: {
    //         pageName: Y.string().required('Page name is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //       ],
    //       updateButtonName: 'Update',
    //       deleteButtonName: 'Delete',
    //       loadingButtonName: 'Updating...',
    //     },
    //     create: {
    //       formTitle: 'Add Page',
    //       formAPI: 'page',
    //       message: { title: 'Pages', message: 'Page created!' },
    //       validation: {
    //         pageName: Y.string().required('Page name is required!'),
    //       },
    //       schema: [
    //         {
    //           name: 'pageName',
    //           element: 'InputField',
    //           type: 'text',
    //           hidden: false,
    //           size: 6,
    //           placeholder: 'Page Name',
    //         },
    //       ],
    //       updateButtonName: 'Add',
    //       loadingButtonName: 'Adding...',
    //     },
    //   },
    // },
  },
};
