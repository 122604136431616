import { nanoid } from 'nanoid';
import create from 'zustand';
export const useNotificationStore = create((set) => ({
    notifications: [],
    addNotification: (notification) => set((state) => ({
        notifications: [...state.notifications, { id: nanoid(), ...notification }],
    })),
    dismissNotification: (id) => set((state) => ({
        notifications: state.notifications.filter((notification) => notification.id !== id),
    })),
}));
