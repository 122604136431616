import { Link } from 'react-router-dom';

export const Banner2 = (props) => {
  const errState = { error: false };
  try {
    return (
      <div id="static-slider2-html">
        <div className="static-slider2 spacer">
          <div className="container">
            <div className="row">
              <div
                className="col-md-5 align-self-center aos-init aos-animate"
                data-aos="fade-right"
                data-aos-duration={1200}
              >
                <h1 className="title">
                  <span className="typewrite" data-period={2000}>
                    <span className="wrap" />
                  </span>
                  <br />
                  {props.pageData.header.header}
                  <span className="text-megna">.</span>
                </h1>
                <h6 className="subtitle">{props.pageData.text.text}</h6>
                <Link
                  className="btn btn-success-gradiant text-white btn-md btn-arrow m-t-20 m-b-10"
                  to={props.pageData.button1Link.button1Link}
                >
                  <span>
                    {props.pageData.button1Text.button1Text} <i className="ti-arrow-right" />
                  </span>
                </Link>
                <Link
                  className="btn btn-link btn-md btn-arrow m-t-20 m-b-10 font-medium"
                  to={props.pageData.button2Link.button2Link}
                >
                  <span className="underline">
                    {props.pageData.button2Text.button2Text} <i className="ti-arrow-right" />
                  </span>
                </Link>
              </div>
              <div
                className="col-md-7 img-anim aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={2200}
              >
                <img src={props.pageData.image.image} alt="trt" className="img-fluid m-t-20" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <div className="mb-3" style={{ marginTop: '7rem', textAlign: 'center' }}>
        Some parts of webpage loaded with errors, <a href={window.location.pathname}>try again</a>{' '}
        if not displayed properly.
      </div>
    );
  }
};
