import { Navigate } from 'react-router-dom';
import React from 'react';

export const PrivateRoute = (props) => {
  if (props.isAuthenticated) {
    const modRole = props.authData.session.pages.filter((f) => f.pageName === props.module);
    const modAccess = modRole[0].roles.filter((f) => f.roleName === props.roleActive);

    if (modAccess.length > 0 && modAccess[0].PageRole.accessLevel > 1) {
      return (
        <>
          <props.navComp
            key="navbar"
            auth={props.auth}
            sessionIsActive={props.sessionIsActive}
            authData={props.authData}
            setRoleAccess={props.setRoleAccess}
            setRoleModal={props.setRoleModal}
            setOpenMenu={props.setOpenMenu}
            openMenu={props.openMenu}
            moduleSchema={props.modSchema}
            routesData={props.routesData}
          />
          {props.children}
        </>
      );
    }
  }
  return <Navigate to={props.navigateRoute} />;
};
