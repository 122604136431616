export const SelectBox = (props) => {
  return (
    <div className={`form-group mb-2 ${props.errors[props.field.name] && 'has-danger'}`}>
      <label className="form-control-label">{props.field.placeholder}</label>
      <select
        className={`form-select ${props.errors[props.field.name] && 'form-control-danger'}`}
        {...props.register(props.field.name)}
        defaultValue={props.defaultData ? props.defaultData[props.field.name] : null}
      >
        <option>Select {props.field.placeholder}...</option>
        {props.field.optionArrayDependency &&
          props.field.compOptionArrayDependency.map((option, optionIndex) => (
            <option key={optionIndex} value={option.id}>
              {option.name}
            </option>
          ))}
        {props.field.optionAPIDependency &&
          JSON.parse(localStorage.getItem('authent')).state.dependeciesFormAPIS[
            props.field.compOptionAPIDependency
          ]?.formData.map((option, optionIndex) => (
            <option key={optionIndex} value={option[props.field.compId]}>
              {option[props.field.compName]}
            </option>
          ))}
      </select>
      {props.errors[props.field.name] && (
        <div className="form-control-feedback">{props.errors[props.field.name].message}</div>
      )}
    </div>
  );
};
