import React from 'react';
import { default as dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';

import { useCreateCall } from '../../lib/api/create';
import { Sidebar } from '../../components/Layout/Sidebar';
import './messagecss.css';
import { useGetCall } from '../../lib/api/get';
import httpParams from '../../lib/query/httpParams';
import { timeAgo } from '../../lib/utils/timeAgo';
import { ModalForm } from './ModalForm';
import { IsLoading } from '../../components/Layout/IsLoading';

export const Messages = ({
  authData,
  session,
  schema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
}) => {
  const errState = { error: false };
  React.useEffect(() => {
    document.getElementById('siteTitle').innerHTML = schema.pageTitle;
  });
  const params = httpParams.adminOrgParams();
  const [isAdd, setIsAdd] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const [activeSubjectIndex, setActiveSubjectIndex] = React.useState(0);
  const [autoRefresh, setAutoRefresh] = React.useState(false);

  const getData = useGetCall(schema.api, params, autoRefresh);

  const createCallMutation = useCreateCall();

  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
  });

  const sendMessage = async (data) => {
    const payload = { ...data };

    payload['parentMessageId'] = getData.data.formData[activeSubjectIndex].UserMessageId;
    payload['subject'] = getData.data.formData[activeSubjectIndex].subject;
    await createCallMutation.mutateAsync({
      apiEndpoint: schema.api,
      data: payload, //data,
      messageTitle: schema.message.title,
      message: schema.message.message,
      params: params,
    });
  };

  const sendNewMessage = async (data) => {
    const payload = { ...data };
    if (data.message === '' || data.subject === '') {
      return;
    }

    await createCallMutation.mutateAsync({
      apiEndpoint: schema.api,
      data: payload, //data,
      messageTitle: schema.message.title,
      message: schema.message.message,
      params: params,
    });
  };

  if (getData.isLoading) {
    return <IsLoading />;
  }

  try {
    return (
      <div className="container" style={{ marginTop: '6rem' }}>
        <div className="row">
          <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
            <h3 className="title d-block d-lg-none">
              <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
              {schema.title}
              <button
                className="btn btn-primary btn-sm"
                style={{ float: 'right' }}
                onClick={() => setIsAdd(true)}
              >
                <i className="fa fa-plus"></i>
              </button>
              <label className="custSwitch" style={{ float: 'right', marginRight: '1rem' }}>
                <input
                  type="checkbox"
                  defaultChecked={autoRefresh}
                  className="colorinput-input"
                  onChange={() => setAutoRefresh(!autoRefresh)}
                />
                <span
                  className="custSlider round"
                  style={{
                    width: '100% !important',
                    height: '100% !important',
                    backgroundColor: autoRefresh !== true && '#CCC',
                  }}
                ></span>
              </label>
              <small style={{ float: 'right', marginRight: '1rem' }}>Auto Refresh</small>
            </h3>
            <h3 className="title d-none d-lg-block">
              {schema.title}{' '}
              <button
                className="btn btn-primary btn-sm"
                style={{ float: 'right' }}
                onClick={() => setIsAdd(true)}
              >
                <i className="fa fa-plus"></i> Add
              </button>
              <label className="custSwitch" style={{ float: 'right', marginRight: '1rem' }}>
                <input
                  type="checkbox"
                  defaultChecked={autoRefresh}
                  className="colorinput-input"
                  onChange={() => setAutoRefresh(!autoRefresh)}
                />
                <span
                  className="custSlider round"
                  style={{
                    width: '100% !important',
                    height: '100% !important',
                    backgroundColor: autoRefresh !== true && '#CCC',
                  }}
                ></span>
              </label>
              <small style={{ float: 'right', marginRight: '1rem' }}>Auto Refresh</small>
            </h3>
          </div>
        </div>
        <div className="row chat-clearfix">
          <Sidebar authData={authData} routesData={routesData} />
          <div className="col">
            <div className="chat-card chat-chat-app">
              <div id="plist" className="chat-people-list cScrollBar" style={{ maxHeight: '100%' }}>
                <ul className="list-unstyled chat-chat-list mt-2 mb-0">
                  {getData.data.formData.length > 0
                    ? getData.data.formData
                        .sort(
                          (b, a) =>
                            new Date(a.lastUpdatedDateTime) - new Date(b.lastUpdatedDateTime)
                        )
                        .map((message, messageIndex) => (
                          <li
                            key={messageIndex}
                            className={`chat-clearfix ${
                              messageIndex === activeSubjectIndex && 'chat-active'
                            }`}
                            onClick={() => setActiveSubjectIndex(messageIndex)}
                          >
                            <div className="chat-about">
                              <div className="chat-name">{message.subject}</div>
                              <div className="chat-status">
                                {' '}
                                <i className="fa fa-circle chat-offline" />{' '}
                                {timeAgo(
                                  new Date(
                                    dayjs(message.lastUpdatedDateTime).format('MM-DD-YYYY h:mm A')
                                  )
                                )}{' '}
                              </div>
                            </div>
                          </li>
                        ))
                    : 'No Messages'}
                </ul>
              </div>
              <div className="chat-chat">
                <div className="chat-chat-header chat-clearfix">
                  <div className="row">
                    <div className="col-lg-12 col-md-7">
                      <div className="chat-chat-about">
                        <h6 className="m-b-0">
                          {getData.data.formData.length > 0 &&
                            getData.data.formData[activeSubjectIndex].subject}
                        </h6>
                        <small>
                          {getData.data.formData.length > 0 &&
                            timeAgo(
                              new Date(
                                dayjs(
                                  getData.data.formData[activeSubjectIndex].lastUpdatedDateTime
                                ).format('MM-DD-YYYY h:mm A')
                              )
                            )}
                        </small>
                      </div>
                      <div className="chat-chat-about"></div>
                    </div>
                    <div className="col-md-4 d-block d-md-none text-right">
                      <a
                        href="#!"
                        onClick={() => setOpenList(!openList)}
                        className="btn btn-outline-secondary"
                      >
                        <i className="fa fa-bars" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="chat-chat-history cScrollBar" style={{ maxHeight: '100%' }}>
                  <ul className="m-b-0">
                    <div>
                      {getData.data.formData.length > 0 &&
                      getData.data.formData[activeSubjectIndex].userId !== session.user.userId ? (
                        <li className="chat-clearfix mb-2">
                          <div
                            className="chat-message-data chat-text-right"
                            style={{ textAlign: 'right' }}
                          >
                            <span className="chat-message-data-time">
                              {getData.data.formData.length > 0 &&
                                timeAgo(
                                  new Date(
                                    dayjs(
                                      getData.data.formData[activeSubjectIndex].lastUpdatedDateTime
                                    ).format('MM-DD-YYYY h:mm A')
                                  )
                                )}
                            </span>
                            <img
                              src="https://bootdey.com/img/Content/avatar/avatar7.png"
                              alt="avatar"
                            />
                          </div>
                          <div
                            className="chat-message chat-other-message chat-float-right"
                            style={{ minWidth: '18rem' }}
                          >
                            {' '}
                            {getData.data.formData.length > 0 &&
                              getData.data.formData[activeSubjectIndex].message}{' '}
                          </div>
                        </li>
                      ) : (
                        getData.data.formData.length > 0 && (
                          <li className="chat-clearfix mb-2">
                            <div className="chat-message-data">
                              <span className="chat-message-data-time">
                                {getData.data.formData.length > 0 &&
                                  timeAgo(
                                    new Date(
                                      dayjs(
                                        getData.data.formData[activeSubjectIndex]
                                          .lastUpdatedDateTime
                                      ).format('MM-DD-YYYY h:mm A')
                                    )
                                  )}
                              </span>
                            </div>
                            <div className="chat-message chat-my-message">
                              {getData.data.formData[activeSubjectIndex].message}{' '}
                            </div>
                          </li>
                        )
                      )}
                    </div>
                    {getData.data.formData.length > 0
                      ? getData.data.formData[activeSubjectIndex].childMessages
                          .sort(
                            (b, a) =>
                              new Date(b.lastUpdatedDateTime) - new Date(a.lastUpdatedDateTime)
                          )
                          .map((message, messageIndex) => (
                            <div>
                              {message.userId !== session.user.userId ? (
                                <li className="chat-clearfix mb-2">
                                  <div
                                    className="chat-message-data chat-text-right"
                                    style={{ textAlign: 'right' }}
                                  >
                                    <span className="chat-message-data-time">
                                      {timeAgo(
                                        new Date(
                                          dayjs(message.lastUpdatedDateTime).format(
                                            'MM-DD-YYYY h:mm A'
                                          )
                                        )
                                      )}
                                    </span>
                                    <img
                                      src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                      alt="avatar"
                                    />
                                  </div>
                                  <div
                                    className="chat-message chat-other-message chat-float-right"
                                    style={{ minWidth: '18rem' }}
                                  >
                                    {' '}
                                    {message.message}{' '}
                                  </div>
                                </li>
                              ) : (
                                <li className="chat-clearfix mb-2">
                                  <div className="chat-message-data">
                                    <span className="chat-message-data-time">
                                      {timeAgo(
                                        new Date(
                                          dayjs(message.lastUpdatedDateTime).format(
                                            'MM-DD-YYYY h:mm A'
                                          )
                                        )
                                      )}
                                    </span>
                                  </div>
                                  <div className="chat-message chat-my-message">
                                    {message.message}{' '}
                                  </div>
                                </li>
                              )}
                            </div>
                          ))
                      : 'No Messages'}
                  </ul>
                </div>
                <div className="chat-chat-message chat-clearfix">
                  <form onSubmit={handleSubmit(sendMessage)}>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control height-unset"
                        placeholder="Your Message Here...."
                        aria-describedby="basic-addon1"
                        {...register('message')}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-info text-white" type="submit">
                          <i className="fa fa-send" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isAdd && (
          <ModalForm
            setIsAdd={setIsAdd}
            handleSubmit={handleSubmit}
            sendMessage={sendNewMessage}
            register={register}
          />
        )}
      </div>
    );
  } catch (err) {
    errState['error'] = true;
    errState['errorStack'] = err;
    return (
      <>
        <div className="container" style={{ marginTop: '6rem' }}>
          <div className="row">
            <div className="col-md-12 b-all border-2 rounded border_Width-1 mb-4">
              <h3 className="title d-block d-lg-none">
                <span className="ti-menu cursor_Pointer" onClick={() => setOpenMenu(!openMenu)} />{' '}
                {schema.title}
              </h3>
              <h3 className="title d-none d-lg-block">{schema.title} </h3>
            </div>
            <Sidebar authData={authData} routesData={routesData} />
            <div
              className="col"
              style={{
                width: '50% !important',
                maxWidth: '50% !important',
                paddingRight: '0px !important',
              }}
            ></div>
            <div className="col-lg-8 b-all border-2 rounded border_Width-1">
              <div className="container mb-3">
                <h2 className="font-light pt-2 text-danger">Error</h2>
                <hr />
                <div className="text-danger">Something went wrong, please try again!</div>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '6rem' }}></div>
      </>
    );
  }
};
