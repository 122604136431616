export const ModalForm = (props) => {
  return (
    <div
      id="mymodal6"
      className="modal fade custom-modal modal2 show"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: 'block',
        backgroundColor: 'rgba(55,55,55,0.3)',
      }}
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-md">
        <div className="modal-content po-relative">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="modal-bg">
                  <h2 className="font-light m-b-0 mb-2">New Message</h2>

                  <form className="row" onSubmit={props.handleSubmit(props.sendMessage)}>
                    <div className="col-12 input-group mb-3">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Subject"
                        aria-describedby="basic-addon1"
                        {...props.register('subject')}
                      />
                    </div>
                    <div className="col-12 input-group mb-3">
                      <textarea
                        className="form-control"
                        placeholder="Your Message Here...."
                        {...props.register('message')}
                      ></textarea>
                    </div>
                    <div className="col-12 input-group mb-3 text-right">
                      <button
                        type="submit"
                        className="btn btn-rounded btn-success-gradiant text-white mt-3 btn-arrow"
                      >
                        <span>
                          Send Message
                          <i className="ti-arrow-right" />
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <a
              href="#!"
              className="close-btn d-flex align-items-center justify-content-center"
              data-bs-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.setIsAdd(false)}
            >
              ×
            </a>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};
