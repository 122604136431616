import axios from 'axios';
import httpParams from '../query/httpParams';
import { API_URL } from '../../config';
const params = httpParams.adminParams();
export const loginWithEmailAndPassword = (data) => {
  const headers = {
    authorization: `Bearer ${data.token}`,
    tokenSource: data.tokenSource,
    Accept: 'application/json',
  };
  return axios.post(API_URL + 'userSession' + params, data, {
    headers: headers,
  });
};
