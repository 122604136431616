import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useDependencies = create(
  persist(
    (set, get) => ({
      setDependency: (dependencies, role, roleId) => {
        set((state) => ({
          ...dependencies,
          role: role,
          roleId: roleId,
        }));
      },
    }),
    {
      name: 'authent',
      getStorage: () => localStorage,
    }
  )
);
