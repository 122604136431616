import { Form } from '../form';

export const ModalForm = (props) => {
  return (
    <div
      id="mymodal6"
      className="modal fade custom-modal modal2 show"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: 'block',
        backgroundColor: 'rgba(55,55,55,0.3)',
      }}
    >
      <div className="modal-dialog modal-fullscreen-sm-down modal-md">
        <div className="modal-content po-relative">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="modal-bg">
                  <Form
                    schema={props.schema}
                    crud={2}
                    defaultData={props.defaultData}
                    session={props.session}
                  />
                </div>
              </div>
            </div>
            <a
              href="#!"
              className="close-btn d-flex align-items-center justify-content-center"
              data-bs-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.closeModal(false)}
            >
              ×
            </a>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};
