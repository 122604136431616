import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../lib/query/react-query';

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense fallback={<div id="global-loader">LOADING.........</div>}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </React.Suspense>
  );
};
